// NotFound.jsx
import React from "react";
import soldout from "../../Images/404.jpg";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center py-20 bg-white dark:bg-boxdark dark:text-white">
      <div className="text-center">
        <img src={soldout} alt="" className="w-96 mx-auto" />
        <p className="text-2xl font-semibold mt-7">Page Not Found</p>
        <p className="mt-4">The page you're looking for doesn't exist.</p>
        <a
          href="/event"
          className="mt-6 inline-block bg-themecolor1 text-white px-6 py-3 rounded"
        >
          Back To Event
        </a>
      </div>
    </div>
  );
};

export default NotFound;
