/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { getAllLanguage } from "../API/State-City-Language-API";
import NoEvent from "../../Images/noevent.png";
import { getAllCategory } from "../API/CategoryApi";
import { getAllArtist } from "../API/ArtistApi";
import ClipLoader from "react-spinners/ClipLoader";
import { getEventByFilterData } from "../API/EventApi";
import Config from "../API/Config";
import { getAllSlider } from "../API/SliderApi";
import { FcCalendar } from "react-icons/fc";
import { FaCirclePlay } from "react-icons/fa6";
import ReactPaginate from "react-paginate";
import ScrollToTop from "../ScrollToTop";
import { format } from "date-fns";
import { FaDollarSign, FaRupeeSign } from "react-icons/fa";

const Events = () => {
  usePageSEO(
    "Love My Show | All Event", // title
    "Love My Show | All Event", // description
    ["Love My Show | All Event "] // keywords
  );
  const getCountrySlug = Config.getCountrySlug();
  const bannerS = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false, // Add this line to disable arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //=========================================Languages Part============================================
  const [Languages, setLanguages] = useState(false);
  const toggleLanguages = () => {
    setLanguages(!Languages);
  };

  //=========================================Categories Part============================================
  const [Categories, setCategories] = useState(false);
  const toggleCategories = () => {
    setCategories(!Categories);
  };
  //=========================================TypeIds Part============================================
  const [Type, setType] = useState(false);
  const toggleType = () => {
    setType(!Type);
  };
  //=========================================Artist Part============================================
  const [Artist, setArtist] = useState(false);
  const toggleArtist = () => {
    setArtist(!Artist);
  };
  //=========================================Dates Part============================================
  const [Dates, setDates] = useState(false);
  const toggleDates = () => {
    setDates(!Dates);
  };

  // ---------------------Language Data-------------------
  const [LanguageData, setLanguageData] = useState([]);
  const fetchLanguageData = async () => {
    try {
      const result = await getAllLanguage();
      setLanguageData(result);
    } catch (error) {
      console.error(error);
    }
  };
  // ---------------------Category Data-------------------
  const [CategoryData, setCategoryData] = useState(null);
  const fetchCategoryData = async () => {
    try {
      const result = await getAllCategory();
      setCategoryData(result);
    } catch (error) {
      console.error(error);
    }
  };
  // ---------------------Type Data-------------------
  const [TypeData, setTypeData] = useState([
    { Id: 1, Title: "Indoor" },
    { Id: 2, Title: "Outdoor" },
  ]);
  // ---------------------ArtistId Data-------------------
  const [ArtistData, setArtistData] = useState(null);
  const fetchArtistData = async () => {
    try {
      const result = await getAllArtist();
      setArtistData(result);
    } catch (error) {
      console.error(error);
    }
  };
  // ---------------------Dates Data-------------------
  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const [DatesData, setDatesData] = useState(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const daysUntilWeekend = 6 - today.getDay();
    const weekend = new Date(today);
    weekend.setDate(today.getDate() + daysUntilWeekend);

    return [
      { Id: 1, Title: "Today", datevalue: getFormattedDate(today) },
      { Id: 2, Title: "Tomorrow", datevalue: getFormattedDate(tomorrow) },
      { Id: 3, Title: "Weekend", datevalue: getFormattedDate(weekend) },
    ];
  });
  // ==================Slider Data===============
  const [SliderData, setSliderData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllSlider();
        setSliderData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchLanguageData();
    fetchCategoryData();
    fetchArtistData();
  }, []);
  // --------------------Language Filter------------------
  const [selectedFilters, setSelectedFilters] = useState({});
  const handleFilterChange = (filter) => {
    setSelectedFilters({
      ...selectedFilters,
      [filter]: !selectedFilters[filter],
    });
  };
  // --------------------Category Filter------------------
  const [CategoryFilters, setCategoryFilters] = useState({});
  const handleCategoryFilterChange = (filter) => {
    setCategoryFilters({
      ...CategoryFilters,
      [filter]: !CategoryFilters[filter],
    });
  };
  // --------------------Type Filter------------------
  const [TypeFilters, setTypeFilters] = useState({});
  const handleTypeFilterChange = (filter) => {
    setTypeFilters({
      ...TypeFilters,
      [filter]: !TypeFilters[filter],
    });
  };
  // --------------------Artist Filter------------------
  const [ArtistFilters, setArtistFilters] = useState({});
  const handleArtistFilterChange = (filter) => {
    setArtistFilters({
      ...ArtistFilters,
      [filter]: !ArtistFilters[filter],
    });
  };
  // --------------------Dates Filter------------------
  const [DatesFilters, setDatesFilters] = useState({});
  const handleDatesFilterChange = (filter) => {
    setDatesFilters({
      ...DatesFilters,
      [filter]: !DatesFilters[filter],
    });
  };

  const [FilteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const CityId = Config.getCityIdData();
  const CityIdName = Config.getCitySlug();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // -------------------language-------------------
        const languageIds = Object.keys(selectedFilters)
          .filter((filter) => selectedFilters[filter])
          .map(
            (filter) =>
              LanguageData.find((lang) => lang.Title.toLowerCase() === filter)
                .Id
          )
          .join("|");
        // -------------------language-------------------
        const categoryIds = Object.keys(CategoryFilters)
          .filter((filter) => CategoryFilters[filter])
          .map(
            (filter) =>
              CategoryData.find((cat) => cat.Title.toLowerCase() === filter).Id
          )
          .join("|");
        // -------------------Type-------------------
        const TypeId = Object.keys(TypeFilters)
          .filter((filter) => TypeFilters[filter])
          .map(
            (filter) =>
              TypeData.find((type) => type.Title.toLowerCase() === filter).Title
          )
          .join("|");
        // -------------------Artist-------------------
        const ArtistId = Object.keys(ArtistFilters)
          .filter((filter) => ArtistFilters[filter])
          .map(
            (filter) =>
              ArtistData.find((Artist) => Artist.Title.toLowerCase() === filter)
                .Id
          )
          .join("|");
        // -------------------Dates-------------------
        const DatesId = Object.keys(DatesFilters)
          .filter((filter) => DatesFilters[filter])
          .map(
            (filter) =>
              DatesData.find((Dates) => Dates.Title.toLowerCase() === filter)
                .datevalue
          )
          .join("|");

        const response = await getEventByFilterData(
          CityId,
          languageIds,
          categoryIds,
          TypeId,
          ArtistId,
          DatesId
        );
        setFilteredData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData(); // Call the API when component mounts or when selectedFilters change
  }, [
    selectedFilters,
    CategoryFilters,
    TypeFilters,
    ArtistFilters,
    DatesFilters,
  ]);

  const clrFilter = (e) => {
    e.preventDefault();
    setSelectedFilters("");
    setCategoryFilters("");
    setArtistFilters("");
    setTypeFilters("");
    setDatesFilters("");
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 12;

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = FilteredData.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(FilteredData.length / itemsPerPage);

  return (
    <div className="dark:bg-boxdark-2 ">
      <ScrollToTop />
      {/*=================================== Banners data======================================== */}
      {/* <div className="Mainslider outline-none dark:bg-boxdark-2 dark:text-white ">
        <Slider {...bannerS} className="main-banner">
          {SliderData?.map((val, index) => {
            return (
              <div className="outline-none w-full" key={index}>
                <Link to={val.Url}>
                  <img src={val.Image} alt="" className="w-full " />
                </Link>
              </div>
            );
          })}
        </Slider>
      </div> */}

      <section className="bg-gray-100 md:px-3 dark:bg-boxdark-2 ">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-3 gap-0">
            <div className=" ">
              <div className="flex items-center">
                <h1 className="my-5 mx-3 lg:mx-0 text-2xl font-bold dark:text-white">
                  Filters
                </h1>
                <div
                  className="ml-auto bg-themecolor1 rounded mx-3 text-white p-2 text-sm cursor-pointer"
                  onClick={clrFilter}
                >
                  Clear Filter
                </div></div>

              {/*================================== Languages Part================================== */}
              <div className="card  mx-4 lg:mx-0 my-3 md:py-3 py-2 rounded-md border bg-white">
                <button
                  onClick={toggleLanguages}
                  className="w-full text-normal flex px-4"
                >
                  Event Languages
                  {Languages ? (
                    <MdKeyboardArrowUp className="mt-1 ml-auto" />
                  ) : (
                    <MdKeyboardArrowDown className="mt-1 ml-auto" />
                  )}
                </button>
                {Languages && (
                  <p className="text-sm px-2">
                    {LanguageData.map((val) => {
                      return (
                        <label
                          key={val.Id}
                          className={`checkbox-button mx-2 px-2 py-1 my-1 mt-3 border text-themecolor1 ${selectedFilters[val.Title.toLowerCase()]
                              ? "active"
                              : ""
                            }`}
                        >
                          <input
                            type="checkbox"
                            checked={selectedFilters[val.Title.toLowerCase()]}
                            onChange={() =>
                              handleFilterChange(val.Title.toLowerCase())
                            }
                          />
                          {val.Title}
                        </label>
                      );
                    })}
                  </p>
                )}
              </div>
              {/*================================== Categories Part================================== */}
              <div className="card  mx-4 lg:mx-0 my-3  md:py-3 py-2  rounded-md border bg-white">
                <button
                  onClick={toggleCategories}
                  className="w-full text-base flex px-3 "
                >
                  Categories
                  {Categories ? (
                    <MdKeyboardArrowUp className="mt-1 ml-auto" />
                  ) : (
                    <MdKeyboardArrowDown className="mt-1 ml-auto" />
                  )}
                </button>
                {Categories && (
                  <p className="text-sm mt-2">
                    {CategoryData?.map((val) => {
                      return (
                        <label
                          key={val.Id}
                          className={`checkbox-button mx-2 px-2 py-1 my-1 border text-themecolor1 ${CategoryFilters[val.Title.toLowerCase()]
                              ? "active"
                              : ""
                            }`}
                        >
                          <input
                            type="checkbox"
                            checked={CategoryFilters[val.Title.toLowerCase()]}
                            onChange={() =>
                              handleCategoryFilterChange(
                                val.Title.toLowerCase()
                              )
                            }
                          />
                          {val.Title}
                        </label>
                      );
                    })}
                  </p>
                )}
              </div>
              {/*================================== Type Part================================== */}
              <div className="card  mx-4 lg:mx-0 my-3  md:py-3 py-2   rounded-md border bg-white">
                <button
                  onClick={toggleType}
                  className="w-full text-base flex px-3 "
                >
                  Type
                  {Type ? (
                    <MdKeyboardArrowUp className="mt-1 ml-auto" />
                  ) : (
                    <MdKeyboardArrowDown className="mt-1 ml-auto" />
                  )}
                </button>
                {Type && (
                  <p className="text-sm mt-2">
                    {TypeData?.map((val) => {
                      return (
                        <label
                          key={val.Id}
                          className={`checkbox-button mx-2 px-2 py-1 my-1 border text-themecolor1 ${TypeFilters[val.Title.toLowerCase()] ? "active" : ""
                            }`}
                        >
                          <input
                            type="checkbox"
                            checked={TypeFilters[val.Title.toLowerCase()]}
                            onChange={() =>
                              handleTypeFilterChange(val.Title.toLowerCase())
                            }
                          />
                          {val.Title}
                        </label>
                      );
                    })}
                  </p>
                )}
              </div>
              {/*================================== Artist Part================================== */}
              <div className="card  mx-4 lg:mx-0 my-3  md:py-3 py-2   rounded-md border bg-white">
                <button
                  onClick={toggleArtist}
                  className="w-full text-base flex px-3 "
                >
                  Artist
                  {Artist ? (
                    <MdKeyboardArrowUp className="mt-1 ml-auto" />
                  ) : (
                    <MdKeyboardArrowDown className="mt-1 ml-auto" />
                  )}
                </button>
                {Artist && (
                  <p className="text-sm mt-2">
                    {ArtistData?.map((val) => {
                      return (
                        <label
                          key={val.Id}
                          className={`checkbox-button mx-2 px-2 py-1 my-1 border text-themecolor1 ${ArtistFilters[val.Title.toLowerCase()]
                              ? "active"
                              : ""
                            }`}
                        >
                          <input
                            type="checkbox"
                            checked={ArtistFilters[val.Title.toLowerCase()]}
                            onChange={() =>
                              handleArtistFilterChange(val.Title.toLowerCase())
                            }
                          />
                          {val.Title}
                        </label>
                      );
                    })}
                  </p>
                )}
              </div>
              {/*================================== Dates Part================================== */}
              <div className="card  mx-4 lg:mx-0 my-3  md:py-3 py-2   rounded-md border bg-white">
                <button
                  onClick={toggleDates}
                  className="w-full text-base flex px-3 "
                >
                  Dates
                  {Dates ? (
                    <MdKeyboardArrowUp className="mt-1 ml-auto" />
                  ) : (
                    <MdKeyboardArrowDown className="mt-1 ml-auto" />
                  )}
                </button>
                {Dates && (
                  <p className="text-sm mt-2">
                    {DatesData?.map((val) => {
                      return (
                        <label
                          key={val.Id}
                          className={`checkbox-button mx-2 px-2 py-1 my-1 border text-themecolor1 ${DatesFilters[val.Title.toLowerCase()]
                              ? "active"
                              : ""
                            }`}
                        >
                          <input
                            type="checkbox"
                            checked={DatesFilters[val.Title.toLowerCase()]}
                            onChange={() =>
                              handleDatesFilterChange(val.Title.toLowerCase())
                            }
                          />
                          {val.Title}
                        </label>
                      );
                    })}
                  </p>
                )}
              </div>
            </div>

            {/* ==============================Events In City=================================*/}
            <div className="col-span-3   mb-20 md:mb-5">
              <h1 className="my-6 mx-4 text-2xl font-bold dark:text-white">
                Events In {CityIdName}
              </h1>

              {loading ? (
                <div className="flex justify-center items-center my-48">
                  <ClipLoader color={"#DC3558"} loading={loading} size={30} />
                </div>
              ) : currentPageData?.length > 0 ? (
                <>
                  <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-3 grid-cols-2 foldsmall:grid-cols-1 xs:grid-cols-1">
                    {currentPageData.map((val, index) => (
                      <div className="px-2 pb-5" key={index}>
                        <div className="bg-white dark:bg-boxdark-2 dark:border dark:border-zinc-600 shadow-lg rounded-md">
                          <Link to={`/event-detail/${val.EventSlug}/${val.Id}`}>
                            <img
                              src={val.Thumb}
                              alt=""
                              className="xl:h-80 lg:h-80 surface:h-72 rounded-md h-72 md:h-60 w-full rounded-b-none"
                            />
                            <div className="px-3 py-3">
                              <div>
                                <h1 className="font-semibold pt-1 pb-1 mb-1 text-base text-gray-800 dark:text-white leading-8 line-clamp-1">
                                  {val.EventName}
                                </h1>
                              </div>
                              <p className="text-sm line-clamp-1 mb-3 text-gray-500 dark:text-gray-400">
                                {val.CategoryTitle}
                              </p>
                              <div className="mt-1">
                                <div className="flex mb-3 md:items-center">
                                  <div className="flex md:text-sm text-xs font-semibold">
                                    <p className="text-sm mr-1">
                                      <FcCalendar />
                                    </p>
                                    <h1 className="line-clamp-1 text-xs text-gray-400  font-normal">
                                      {format(
                                        new Date(val.Dates.EventDate),
                                        "dd/MM/yyyy"
                                      )}
                                    </h1>
                                  </div>
                                  <div className="flex md:items-center md:text-sm text-xs font-semibold ml-auto">
                                    <p className="md:text-xl text-sm mx-1 me-2 text-red-600">
                                      <FaCirclePlay />
                                    </p>
                                    <h1 className="line-clamp-1 text-xs font-medium dark:text-white">
                                      {val.ArtistTitle}
                                    </h1>
                                  </div>
                                </div>
                                <div className="flex text-sm font-semibold pt-1 dark:text-white">
                                  <h1 className="my-auto flex items-center">
                                    {getCountrySlug === "India" ? (
                                      <>
                                        <span className="text-base">
                                          <FaRupeeSign />
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span className="text-base">
                                          <FaDollarSign />
                                        </span>
                                      </>
                                    )}{" "}
                                    {val.Price.TicketPrice}
                                  </h1>
                                  <button className="ml-auto bg-[#F84464] md:hover:bg-black rounded-full  px-3 py-1">
                                    <Link
                                      to={`/event-detail/${val.EventSlug}/${val.Id}`}
                                      className="text-xs text-white font-medium"
                                    >
                                      Buy Now
                                    </Link>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-center mt-4">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLinkClassName="page-link"
                    />
                  </div>
                </>
              ) : (
                <div className="mx-auto py-16 bg-white font-bold text-2xl text-bodydark2 text-center">
                  <img src={NoEvent} alt="" className="mx-auto" />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Events;
