import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { BiCategory } from "react-icons/bi";
import { FaIndianRupeeSign, FaUser } from "react-icons/fa6";
import { getAllDashbaord } from "../API/DashboardAPI";
import DashboardMenu from "./DashboardMenu";
import ScrollToTop from "../ScrollToTop";
import usePageSEO from "../hoosk/usepageSEO/Index";
import ClipLoader from "react-spinners/ClipLoader";

const VendorDashboard = () => {
  const [TotalOrder, setTotalOrder] = useState([]);
  const [TotalOrderAmount, setTotalOrderAmount] = useState([]);
  const [TotalEventCount, setTotalEventCount] = useState([]);
  const [CountryWiseEventStatus, setCountryWiseEventStatus] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  usePageSEO(
    "Love My Show | Dashboard", // title
    "Love My Show | Dashboard", // description
    ["Love My Show | Dashboard "] // keywords
  );
  const fetchCountryData = async () => {
    try {
      const result = await getAllDashbaord();
      setTotalOrder(result.TotalOrder);
      setTotalOrderAmount(result.TotalOrderAmount);
      setTotalEventCount(result.TotalEventCount);
      setCountryWiseEventStatus(result.CountryWiseEventStatus);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchCountryData();
  }, []);

  // Extract country names and user counts

  // Extract CountryWiseEventStatus
  const CountryWiseEventStatuslabels = CountryWiseEventStatus.map(
    (country) => country.Country
  );
  const CountryWiseEventStatusPending = CountryWiseEventStatus.map(
    (country) => country.Pending
  );
  const CountryWiseEventStatusActive = CountryWiseEventStatus.map(
    (country) => country.Active
  );
  const CountryWiseEventStatusInactive = CountryWiseEventStatus.map(
    (country) => country.Inactive
  );
  const CountryWiseEventStatusExpired = CountryWiseEventStatus.map(
    (country) => country.Expired
  );
  const CountryWiseEventStatusReject = CountryWiseEventStatus.map(
    (country) => country.Reject
  );
  const CountryWiseEventStatusCanceled = CountryWiseEventStatus.map(
    (country) => country.Canceled
  );

  // -----------------------event-status-------------------

  const eventstatusdata = {
    labels: CountryWiseEventStatuslabels,
    datasets: [
      {
        label: "Pending Event",
        data: CountryWiseEventStatusPending,
        backgroundColor: "#fd6285",
      },
      {
        label: "Active Event",
        data: CountryWiseEventStatusActive,
        backgroundColor: "#fd9e49",
      },
      {
        label: "Inactive Event",
        data: CountryWiseEventStatusInactive,
        backgroundColor: "#50c0bf",
      },
      {
        label: "Expired Event",
        data: CountryWiseEventStatusExpired,
        backgroundColor: "#9968fc",
      },
      {
        label: "Reject Event",
        data: CountryWiseEventStatusReject,
        backgroundColor: "#3ca3e9",
      },
      {
        label: "Canceled Event",
        data: CountryWiseEventStatusCanceled,
        backgroundColor: "#c94239",
      },
    ],
  };

  const isDarkMode = localStorage.getItem("darkMode") === "true";

  const eventstatusdataOption = {
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: isDarkMode ? "#ffffff" : "#000000", // White in dark mode, black otherwise
        },
      },
      y: {
        ticks: {
          color: isDarkMode ? "#ffffff" : "#000000", // White in dark mode, black otherwise
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: isDarkMode ? "#ffffff" : "#000000", // White in dark mode, black otherwise
        },
      },
      tooltip: {
        bodyColor: isDarkMode ? "#ffffff" : "#000000", // White in dark mode, black otherwise
      },
    },
  };

  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          <DashboardMenu />

          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3 px-2">
            {loading ? (
              <div className="flex justify-center items-center my-48">
                <ClipLoader color={"#DC3558"} loading={loading} size={30} />
              </div>
            ) : (
              <>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
                  <div className="p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                    <div className="flex items-start justify-between">
                      <div className="flex flex-col space-y-2">
                        <span className="text-gray-400 dark:text-white">
                          Total Order
                        </span>
                        <span className="text-lg font-semibold">{TotalOrder}</span>
                      </div>
                      <div className="p-10 bg-gray-200 rounded-md">
                        <BiCategory className="fill-primary dark:fill-black text-2xl" />
                      </div>
                    </div>
                  </div>{" "}
                  <div className="p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                    <div className="flex items-start justify-between">
                      <div className="flex flex-col space-y-2">
                        <span className="text-gray-400 dark:text-white">
                          Total Order Amount
                        </span>
                        <span className="text-lg font-semibold">
                          {TotalOrderAmount}
                        </span>
                      </div>
                      <div className="p-10 bg-gray-200 rounded-md">
                        <FaIndianRupeeSign className="fill-primary dark:fill-black text-2xl" />
                      </div>
                    </div>
                  </div>{" "}
                  <div className="p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                    <div className="flex items-start justify-between">
                      <div className="flex flex-col space-y-2">
                        <span className="text-gray-400 dark:text-white">
                          Total Event
                        </span>
                        <span className="text-lg font-semibold">
                          {TotalEventCount}
                        </span>
                      </div>
                      <div className="p-10 bg-gray-200 rounded-md">
                        <FaUser className="fill-primary dark:fill-black text-2xl" />
                      </div>
                    </div>
                  </div>{" "}
                </div>

                <div className="transition-shadow border rounded-lg shadow-sm hover:shadow-lg h-96 p-5 border-stroke mt-5 bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:text-white dark:bg-boxdark">
                  <Bar data={eventstatusdata} options={eventstatusdataOption} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorDashboard;
