/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import pdf from "../../Images/dummy.pdf";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { AddKYC3, getKYCById } from "../API/KYCApi";
import Config from "../API/Config";
import usePageSEO from "../hoosk/usepageSEO/Index";
import ClipLoader from "react-spinners/ClipLoader";
import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
const Kyc3 = () => {
  usePageSEO(
    "Love My Show | Kyc Step 2", // title
    "Love My Show | Kyc Step 2", // description
    ["Love My Show | Kyc Step 2 "] // keywords
  );
  const usenavigate = useNavigate();
  const Id = Config.getId();
  const [loading, setLoading] = useState(true); // Loading state
  // ==================Kyc Data===============
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Id) {
          const UserData = await getKYCById(Id);
          formik.setValues(UserData[0]);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, [Id]);

  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      Id: Id,
      SignAgreement: "",
      Hid_SignAgreement: "",
    },
    // validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        await AddKYC3(formData);
        usenavigate("/profile");
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  return (
    <div className="dark:bg-boxdark-2 dark:text-white ">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="container w-full xl:w-2/3 px-4 mx-auto py-10">
        <div className=" bg-white dark:bg-boxdark-2 dark:text-white p-5">
          <h1 className="text-3xl text-center font-semibold">Sign Agreement</h1>
          <h1 className="bg-[#FFF3CD] border dark:border-zinc-600 border-[#FFECB5] px-5 py-4 rounded-lg text-[#664D03] font-medium my-4 mx-4 lg:mx-0">
            Your KYC status is Pending
          </h1>
          {loading ? (
            <div className="flex justify-center items-center my-48">
              <ClipLoader color={"#DC3558"} loading={loading} size={30} />
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <input
                type="hidden"
                name="Hid_SignAgreement"
                value={formik.values.Hid_SignAgreement}
              />
              <div className="py-3">
                <p className="text-gray-500 dark:text-white pb-2">
                  Upload signed agreement,click on{" "}
                  <a href={pdf} download className="text-themecolor1">
                    Download agreement
                  </a>{" "}
                  to download the agreement and upload agreemenr.
                </p>
                <div className="border-2 border-dotted border-gray-600 rounded-md text-center pt-7 py-4">
                  <h1 className="text-lg text-gray-600  dark:text-white">
                    Select File here (1MB File Size Limit)
                  </h1>
                  <input
                    type="file"
                    name="SignAgreement"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "SignAgreement",
                        event.currentTarget.files[0]
                      );
                    }}
                    className="block m-auto w-56 foldsmall:w-40 lg:w-80 text-sm text-slate-500 my-5 border  border-[#F84464] bg-[#F7F7F7] rounded-lg
                          file:mr-4 file:py-3 file:px-4 dark:bg-boxdark-2 dark:text-white
                          file:border-0 file:text-sm file:font-semibold
                          file:bg-themecolor1 file:text-white"
                  />
                  {formik.errors.SignAgreement &&
                    formik.touched.SignAgreement && (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.SignAgreement}
                      </div>
                    )}

                  {formik.values.Hid_SignAgreement ? (
                    <Link
                      to={formik.values.Path + formik.values.Hid_SignAgreement}
                      target="_blank"
                      className="bg-themecolor2 px-10 py-2 text-white font-bold"
                    >
                      View
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex mb-2">
                <Link
                  to={"/kyc-2"}
                  className="block  bg-themecolor1  rounded-md text-white font-semibold py-2 px-5"
                >
                  Back
                </Link>
                <button
                  type="submit"
                  disabled={isFormLoading}
                  className="block ms-auto bg-themecolor1 rounded-md text-white font-semibold py-2 px-5"
                >
                  {isFormLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Kyc3;
