/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Config from "../API/Config";
import { AddKYC2, getKYCById } from "../API/KYCApi";
import usePageSEO from "../hoosk/usepageSEO/Index";
import ClipLoader from "react-spinners/ClipLoader";
import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import * as Yup from "yup";

const validationSchema = Yup.object({
  PanCard: Yup.mixed().required("Pan Card is required"),

  CancelledCheque: Yup.mixed().required("Cancelled Cheque is required"),
});

const Kyc2 = () => {
  usePageSEO(
    "Love My Show | Kyc Step 2", // title
    "Love My Show | Kyc Step 2", // description
    ["Love My Show | Kyc Step 2 "] // keywords
  );

  const usenavigate = useNavigate();
  const Id = Config.getId();
  const [loading, setLoading] = useState(true); // Loading state
  // ==================Kyc Data===============
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Id) {
          const UserData = await getKYCById(Id);
          formik.setValues(UserData[0]);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchData();
  }, [Id]);

  const [isFormLoading, setIsFormLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      Id: Id,
      PanCard: "",
      Hid_PanCard: "",
      CancelledCheque: "",
      Hid_CancelledCheque: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        await AddKYC2(formData);
        usenavigate("/kyc-3");
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  return (
    <div className="dark:bg-boxdark-2 dark:text-white ">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="container w-full xl:w-2/3 px-4 mx-auto pb-10">
        <div className=" bg-white dark:bg-boxdark-2 dark:text-white p-5">
          <h1 className="text-2xl text-center font-semibold">
            Upload Documents
          </h1>
          <h1 className="bg-[#FFF3CD] border dark:border-zinc-600 border-[#FFECB5] px-5 py-4 rounded-lg text-[#664D03] font-medium my-4 mx-4 lg:mx-0">
            Your KYC status is Pending
          </h1>
          {loading ? (
            <div className="flex justify-center items-center my-48">
              <ClipLoader color={"#DC3558"} loading={loading} size={30} />
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <input
                type="hidden"
                name="Hid_PanCard"
                value={formik.values.Hid_PanCard}
              />
              <input
                type="hidden"
                name="Hid_CancelledCheque"
                value={formik.values.Hid_CancelledCheque}
              />
              <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div className="py-3">
                    <h1 className="mb-2 text-sm">
                      Pan / Any Government Proof
                      <span className="text-red-600">*</span>
                    </h1>
                    <p className="text-xs text-gray-500 dark:text-white pb-4">
                      (.jpg, .jpeg, .png, .pdf)
                    </p>
                    <div className="border-2 border-dotted border-gray-600 rounded-md text-center pt-7 py-4">
                      <h1 className="px-4 lg:px-0 text-gray-600 dark:text-white text-xs pt-3">
                        Select File here (1MB File Size Limit)
                      </h1>
                      <input
                        type="file"
                        name="PanCard"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "PanCard",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="block m-auto w-56 foldsmall:w-40 lg:w-80 text-sm text-slate-500 my-5 border  border-[#F84464] bg-[#F7F7F7] rounded-lg
                      file:mr-4 file:py-3 file:px-4 dark:bg-boxdark-2 dark:text-white
                      file:border-0 file:text-sm file:font-semibold
                      file:bg-themecolor1 file:text-white"
                      />
                      {formik.errors.PanCard && formik.touched.PanCard && (
                        <div className="text-red-500 text-xs mt-2">
                          {formik.errors.PanCard}
                        </div>
                      )}

                      {formik.values.Hid_PanCard ? (
                        <Link
                          to={formik.values.Path + formik.values.Hid_PanCard}
                          target="_blank"
                          className="bg-black px-10 py-2 rounded-full text-white font-bold"
                        >
                          View
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="py-3">
                    <h1 className="mb-2 text-sm">
                      Cancelled Cheque <span className="text-red-600">*</span>
                    </h1>
                    <p className="text-xs text-gray-500 dark:text-white pb-4">
                      (.jpg, .jpeg, .png, .pdf)
                    </p>
                    <div className="border-2 border-dotted border-gray-600 rounded-md text-center pt-7 py-4">
                      <h1 className="px-4 lg:px-0 text-gray-600 dark:text-white text-xs pt-3">
                        Select File here (1MB File Size Limit)
                      </h1>
                      <input
                        type="file"
                        name="CancelledCheque"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "CancelledCheque",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="block m-auto w-56 foldsmall:w-40 lg:w-80 text-sm text-slate-500 my-5 border  border-[#F84464] bg-[#F7F7F7] rounded-lg
                          file:mr-4 file:py-3 file:px-4 dark:bg-boxdark-2 dark:text-white
                          file:border-0 file:text-sm file:font-semibold
                          file:bg-themecolor1 file:text-white"
                      />
                      {formik.errors.CancelledCheque &&
                        formik.touched.CancelledCheque && (
                          <div className="text-red-500 text-xs mt-2">
                            {formik.errors.CancelledCheque}
                          </div>
                        )}

                      {formik.values.Hid_CancelledCheque ? (
                        <Link
                          to={
                            formik.values.Path +
                            formik.values.Hid_CancelledCheque
                          }
                          target="_blank"
                          className="bg-black px-10 py-2 rounded-full text-white font-bold"
                        >
                          View
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex mb-2">
                  <Link
                    to={"/kyc-1"}
                    className="block bg-green-500 rounded-md text-white font-semibold py-2 px-5"
                  >
                    Back
                  </Link>
                  <button
                    type="submit"
                    disabled={isFormLoading}
                    className="block ms-auto bg-black rounded-md text-white font-semibold py-2 px-5"
                  >
                    {isFormLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Kyc2;
