import axios from "axios";
import Config from "./Config";

const { API_BASE_URL } = Config; // Destructure the values from the config file

// ---------------------------category------------------------
export const getAllCategory = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/category`);
    return response.data.responsedata;
  } catch (error) {
    // toast.error(response.data.message);
  }
};
