import { useEffect, useState } from "react";
import "./App.css";
import Layout from "./Components/Layout/Layout";
import Loader from "./Components/common/Loader";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulate an asynchronous check for user authentication
    setTimeout(() => { 
      setLoading(false);
    }, 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Layout />      
    </>
  );
}

export default App;
