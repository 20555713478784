import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";

const { API_BASE_URL } = Config; // Destructure the values from the config file

const TOKEN = Config.getToken();
const Id = Config.getId();
const UserType = Config.getType();

const headers = {
  Authorization: `Bearer ${TOKEN}`, // Corrected typo from "Bareer" to "Bearer"
};

// ======================PROFILE===========================
// =========================getUserdata=========================D
export const getUserById = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/${Id}/${UserType}`, {
      headers,
    });

    if (response.data.status === true) {
      localStorage.setItem(
        "KYCStatus",
        JSON.stringify(response.data.responsedata[0].KYCStatus)
      );
      return response.data.responsedata;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ===================Edit User data================D
export const UpdateUserById = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user`, formData, {
      headers,
      "Content-Type": "multipart/form-data",
    });

    if (response.data.status === true) {
      toast.success(response.data.message);
      return response.data;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
