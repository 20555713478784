import axios from "axios";
import { toast } from "react-toastify";
import Config from "./Config";

const { API_BASE_URL } = Config; // Destructure the values from the config file

// ---------------------------page------------------------

// ----------------------page----------------
export const getPages = async (Slug) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/page/${Slug}`);

    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
