import axios from "axios";
import { toast } from "react-toastify";
import Config from "./Config";

const { API_BASE_URL } = Config; // Destructure the values from the config file

const TOKEN = Config.getToken();
const UserData = Config.getUserData();
const UserType = UserData ? UserData.Type : null;

const headers = {
  Authorization: `Bearer ${TOKEN}`, // Corrected typo from "Bareer" to "Bearer"
};

// ---------------------------KYC------------------------

// ----------------------getKYCbyId----------------
export const getKYCById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/kyc/${Id}/${UserType}`, {
      headers,
    });

    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ------------------------Add KYC---------------------
export const AddKYC1 = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/kyc/1`, data, {
      headers,
    });
    if (response.data.status === true) {
      toast.success(response.data.message); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ------------------------Add KYC 2---------------------
export const AddKYC2 = async (formdata) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/kyc/2`, formdata, {
      headers,
    });
    if (response.data.status === true) {
      toast.success(response.data.message); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ------------------------Add KYC 2---------------------
export const AddKYC3 = async (formdata) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/kyc/3`, formdata, {
      headers,
    });
    if (response.data.status === true) {
      toast.success(response.data.message); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
