/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { format } from "date-fns";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import usePageSEO from "../../hoosk/usepageSEO/Index";
import ScrollToTop from "../../ScrollToTop";
import FormLoader from "../../common/FormLoader";
import DashboardMenu from "../DashboardMenu";
import { deleteBouncer, getAllBouncer } from "../../API/BouncerApi";

const BouncerDashboard = () => {
  usePageSEO(
    "Love My Show | Bouncer Listing", // title
    "Love My Show | Bouncer Listing", // description
    ["Love My Show | Bouncer Listing"] // keywords
  );
  // ==================Event Data===============
  const [EventData, setEventData] = useState(null);
  const [filterdata, setfilterdata] = useState([]);
  const [search, setsearch] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const fetchEventData = async () => {
    try {
      const result = await getAllBouncer();
      setEventData(result);
      setfilterdata(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchEventData();
  }, []);

  const [isFormLoading, setIsFormLoading] = useState(false);
  // -------------------delete venue------------------
  const handleDelete = async (Id) => {
    setIsFormLoading(true);
    try {
      await deleteBouncer(Id);
      fetchEventData();
    } catch (error) {
      console.error("Error deleting student:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Link to={`/bouncer/edit/${rowData.Id}`}>
          <div className="border border-blue-600 text-blue-600 mr-3 rounded-full py-2.5 my-1">
            <FaEdit className="mx-2.5" />
          </div>
        </Link>
        <div
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: `Are you sure you want to delete ${rowData.Code}?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!",
            }).then((result) => {
              if (result.isConfirmed) {
                handleDelete(rowData.Id);
              }
            });
          }}
          className="border border-red-600 text-red-600 mr-3 rounded-full py-2.5 my-1 cursor-pointer"
        >
          <FaTrash className="mx-2.5" />
        </div>
      </div>
    );
  };
  useEffect(() => {
    const mySearch = EventData?.filter(
      (item) => item.Code && item.Code.toLowerCase().match(search.toLowerCase())
    );
    setfilterdata(mySearch);
  }, [search]);

  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          <DashboardMenu />

          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  <div className="border-b flex items-center bg-themecolor1 py-2">
                    <h1 className="text-lg text-center text-white mx-auto py-1 font-bold pt-0">
                      Bouncer Listing
                    </h1>
                  </div>

                  {loading ? (
                    <div className="flex justify-center items-center my-48">
                      <ClipLoader
                        color={"#DC3558"}
                        loading={loading}
                        size={40}
                      />
                    </div>
                  ) : (
                    <>
                      <DataTable
                        value={filterdata}
                        tableStyle={{
                          minWidth: "50rem",
                          border: "1px solid #e0e0e0",
                        }}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        emptyMessage="No Data found"
                        globalFilter={search}
                        header={
                          <div className="flex justify-between p-ai-center">
                            <span className="p-input-icon-left">
                              <i className="pi pi-search" />
                              <InputText
                                type="text"
                                className="text-start me-auto text-sm border-2 py-2 pl-2 md:pr-20 pr-5"
                                onInput={(e) => setsearch(e.target.value)}
                                placeholder="Search"
                              />
                            </span>
                            <Link
                              to="/bouncer/add"
                              className="bg-green-600 text-white py-2 px-10 text-sm"
                            >
                              Add
                            </Link>
                          </div>
                        }
                      >
                        <Column
                          field="Id"
                          header="#"
                          sortable
                          className="border border-stroke"
                        />
                        <Column
                          field="Code"
                          header="Code"
                          sortable
                          className="border border-stroke"
                        />
                        <Column
                          field="Amount"
                          header="Amount"
                          className="border border-stroke"
                        />

                        <Column
                          field="StartDate"
                          header="StartDate"
                          className="border border-stroke"
                          body={(rowData) =>
                            format(new Date(rowData.StartDate), "dd/MM/yyyy")
                          }
                        />
                        <Column
                          field="EndDate"
                          header="EndDate"
                          className="border border-stroke"
                          body={(rowData) =>
                            format(new Date(rowData.EndDate), "dd/MM/yyyy")
                          }
                        />
                        <Column
                          field="Status"
                          header="Status"
                          className="border border-stroke"
                          body={(rowData) => (
                            <span>
                              {rowData.Status === 0 ? (
                                <span className="badge bg-red-500 text-white rounded text-xs px-3 py-1">
                                  In Active
                                </span>
                              ) : (
                                <span className="badge bg-green-500 text-white rounded text-xs px-3 py-1">
                                  Active
                                </span>
                              )}
                            </span>
                          )}
                        />

                        <Column
                          header="Action"
                          className="border border-stroke"
                          body={actionTemplate}
                        />
                      </DataTable>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BouncerDashboard;
