/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaCalendar, FaEye, FaUser } from "react-icons/fa";
import { CgOrganisation } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";
import DashboardMenu from "./DashboardMenu";
import usePageSEO from "../hoosk/usepageSEO/Index";
import {
  GetTicketDownloadById,
  SendEmailAPI,
  SendSMSAPI,
  ShareTicket,
  getEventBookByUserId,
} from "../API/EventApi";
import Config from "../API/Config";
import ClipLoader from "react-spinners/ClipLoader";
import NoDataFound from "../common/NodataFound";
import { BsFillPrinterFill } from "react-icons/bs";
import { FaEnvelope, FaLocationDot, FaMessage } from "react-icons/fa6";
import ScrollToTop from "../ScrollToTop";
import FormLoader from "../common/FormLoader";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  Phone: Yup.string()
    .required("Phone Number is required")
    .matches(/^[0-9]+$/, "Phone Number must be only digits")
    .min(10, "Phone Number must be at least 10 digits")
    .max(15, "Phone Number can't be more than 15 digits"),
  Qty: Yup.number()
    .required("Quantity is required")
    .positive("Quantity must be greater than 0")
    .integer("Quantity must be a whole number"),
});

const Orderhistory = () => {
  usePageSEO(
    "Love My Show | Order History", // title
    "Love My Show | Order History", // description
    ["Love My Show | Order History "] // keywords
  );
  const UserId = Config.getId();

  const getStatusBadge = (status) => {
    switch (status) {
      case 0:
        return (
          <span className="bg-yellow-500 text-white text-xs py-1 px-3 rounded mr-2">
            Pending
          </span>
        );
      case 1:
        return (
          <span className="bg-green-500 text-white text-xs py-1 px-3 rounded mr-2">
            Confirmed
          </span>
        );
      case 2:
        return (
          <span className="bg-red-500 text-white text-xs py-1 px-3 rounded mr-2">
            Cancelled
          </span>
        );
      case 3:
        return (
          <span className="bg-blue-500 text-white text-xs py-1 px-3 rounded mr-2">
            Completed
          </span>
        );
      default:
        return null;
    }
  };
  // ==================EventBook Data===============
  const [EventBookData, setEventBookData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const fetchData = async () => {
    try {
      const result = await getEventBookByUserId(UserId);
      setEventBookData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  // ------------------date format------------------
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatEntDtDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // tickitpopup--------------
  const [ticketpopup, setticket] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const handleProfileClick = (Id) => {
    const event = EventBookData.find((event) => event.Id === Id);
    setSelectedEvent(event);
    setticket(true);
  };
  const [isFormLoading, setIsFormLoading] = useState(false);
  const HandlePrint = async (Id) => {
    setIsFormLoading(true);
    try {
      const result = await GetTicketDownloadById(Id, "00");
      if (result) {
        const pdfUrl = result.downloadUrl;
        window.open(pdfUrl, "_blank");
      } else {
        console.error("PDF generation failed:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  const HandlePrint2 = async (OrderId, Id) => {
    setIsFormLoading(true);
    try {
      const result = await GetTicketDownloadById(OrderId, Id);
      if (result) {
        const pdfUrl = result.downloadUrl;
        window.open(pdfUrl, "_blank");
      } else {
        console.error("PDF generation failed:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };

  const HandleEmail = async (OrderId, Id) => {
    setIsFormLoading(true);
    try {
      await SendEmailAPI(OrderId, "00");
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };

  const HandleTextSMS = async (OrderId, Id) => {
    setIsFormLoading(true);
    try {
      await SendSMSAPI(OrderId, "00");
    } catch (error) {
      console.error("Error sending SMS:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  const HandleTextSMS2 = async (OrderId, Id) => {
    setIsFormLoading(true);
    try {
      await SendSMSAPI(OrderId, Id);
    } catch (error) {
      console.error("Error sending SMS:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  // -----------------------------pagination------------------------------
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = EventBookData?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(EventBookData?.length / itemsPerPage);

  const handlePrevious = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);
  };

  const handleNext = () => {
    setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage);
  };

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 10);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll
    });
  }, [currentPage]);

  // ----------------Split popup---------------
  // tickitpopup--------------
  const [splitepopup, setsplitepopup] = useState(false);
  const [SplitePop, setSplitePop] = useState(null);
  const HandleSpitePopup = (Id) => {
    const event = EventBookData.find((event) => event.Id === Id);
    setSplitePop(event);
    setsplitepopup(true);
  };

  const formik = useFormik({
    initialValues: {
      OrderId: SplitePop?.Id || "",
      UserId: SplitePop?.UserId || "",
      VendorId: SplitePop?.VendorId || "",
      EventId: SplitePop?.EventId || "",
      AddressId: SplitePop?.AddressId || "",
      DateId: SplitePop?.DateId || "",
      TicketId: SplitePop?.TicketId || "",
      Phone: "",
      Qty: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, action) => {
      setIsFormLoading(true);
      try {
        const result = await ShareTicket(values);
        if (result.status === true) {
          formik.setFieldValue("Phone", ""); // Set OrderId in Formik
          formik.setFieldValue("Qty", ""); // Set OrderId in Formik
          setsplitepopup(false);
          await GetTicketDownloadById(result.OrderId, result.SplitOrderId);
          fetchData();
        }
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  useEffect(() => {
    if (SplitePop?.Id) {
      formik.setFieldValue("OrderId", SplitePop.Id); // Set OrderId in Formik
      formik.setFieldValue("UserId", SplitePop.UserId); // Set TicketId in Formik
      formik.setFieldValue("VendorId", SplitePop.VendorId); // Set TicketId in Formik
      formik.setFieldValue("EventId", SplitePop.EventId); // Set TicketId in Formik
      formik.setFieldValue("AddressId", SplitePop.AddressId); // Set TicketId in Formik
      formik.setFieldValue("DateId", SplitePop.DateId); // Set TicketId in Formik
      formik.setFieldValue("TicketId", SplitePop.TicketId); // Set TicketId in Formik
    }
  }, [SplitePop]);
  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          {/*================================ NavPills ======================*/}
          <DashboardMenu />

          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                <div className="">
                  <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                    <h1 className="text-lg  p-2 px-4  text-white font-bold bg-themecolor1">
                      Order History
                    </h1>

                    {loading ? (
                      <div className="flex justify-center items-center my-48">
                        <ClipLoader
                          color={"#DC3558"}
                          loading={loading}
                          size={30}
                        />
                      </div>
                    ) : (
                      <div className="gap-4">
                        {currentItems?.length > 0 ? (
                          <>
                            {currentItems.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  className="shadow-lg p-3 border border-themecolor1 border-dashed dark:border-zinc-700 mt-4 rounded-lg bg-white dark:bg-gray-800"
                                >
                                  <div className="flex flex-col md:flex-row items-start md:items-center border-b border-gray-300 dark:border-gray-600 pb-4">
                                    <div className="flex items-center">
                                      <span className="bg-teal-500 text-white px-1.5 rounded-md mr-3 lg:text-xl md:text-normal text-sm">
                                        {index + 1}
                                      </span>
                                      <h2 className="lg:text-xl md:text-normal text-sm font-semibold">
                                        {val.EventName}
                                      </h2>
                                    </div>
                                    <p className="mt-2 md:mt-0 md:text-normal text-sm text-gray-600 dark:text-gray-300 ml-auto">
                                      <b className="text-gray-800 dark:text-gray-200 ">
                                        Event Date:
                                      </b>{" "}
                                      {val.BookForDate}
                                    </p>
                                  </div>

                                  <div className="flex flex-col md:flex-row md:gap-6 mt-3">
                                    <div className="md:w-2/3 bg-gray-100 dark:bg-gray-700 p-4 px-2 rounded-lg shadow-md">
                                      {val.Status === 1 ? (
                                        <>
                                          <div className="flex items-center bg-gray-900 text-white p-2 rounded-t-lg justify-center">
                                            <BsFillPrinterFill
                                              className="md:text-3xl text-2xl cursor-pointer bg-themecolor3 text-white md:p-1.5 p-1 rounded"
                                              onClick={() => {
                                                HandlePrint(val.Id);
                                              }}
                                            />
                                            <FaEye
                                              className="md:text-3xl text-2xl cursor-pointer bg-themecolor3 ml-3 text-white md:p-1.5 p-1 rounded"
                                              onClick={() =>
                                                handleProfileClick(val.Id)
                                              }
                                            />
                                            <FaEnvelope
                                              className="md:text-3xl text-2xl cursor-pointer bg-themecolor3 ml-3 text-white md:p-1.5 p-1 rounded"
                                              onClick={() =>
                                                HandleEmail(val.Id)
                                              }
                                            />
                                            <FaMessage
                                              className="md:text-3xl text-2xl cursor-pointer bg-themecolor3 ml-3 text-white md:p-1.5 p-1 rounded"
                                              onClick={() =>
                                                HandleTextSMS(val.Id)
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <div className="bg-white dark:bg-gray-800 md:p-3 p-2 rounded-lg shadow-md">
                                        <div className="flex items-center mt-1.5">
                                          <img
                                            src={val.Banner}
                                            alt="Event Thumbnail"
                                            className="w-full md:h-60 h-auto mb-2 md:object-cover"
                                          />
                                        </div>
                                        <p className="font-semibold lg:text-xl md:text-normal text-sm text-gray-800 dark:text-white mb-2">
                                          {val.EventName}
                                        </p>

                                        {/* User Name */}
                                        {val.UserName && (
                                          <p className="flex items-center md:text-sm text-xs mb-1.5 md:mb-0 text-gray-600 dark:text-gray-300 my-1">
                                            <FaUser className="mr-2 text-themecolor1" />
                                            <span className="font-medium">
                                              {val.UserName}
                                            </span>
                                          </p>
                                        )}

                                        {/* User Email */}
                                        {val.UserEmail && (
                                          <p className="flex items-center md:text-sm text-xs mb-1.5 md:mb-0 text-gray-600 dark:text-gray-300 my-1">
                                            <FaEnvelope className="mr-2 text-themecolor1" />
                                            <span className="font-medium">
                                              {val.UserEmail}
                                            </span>
                                          </p>
                                        )}

                                        {/* Event Organizer */}
                                        {val.EventOrganizer && (
                                          <p className="flex items-center md:text-sm text-xs mb-1.5 md:mb-0 text-gray-600 dark:text-gray-300 my-1">
                                            <CgOrganisation className="mr-2 text-themecolor1" />
                                            <span className="font-medium">
                                              {val.EventOrganizer}
                                            </span>
                                          </p>
                                        )}

                                        {/* Event Date and Time */}
                                        <p className="flex items-center md:text-sm text-xs mb-1.5 md:mb-0 text-gray-600 dark:text-gray-300 my-1">
                                          <FaCalendar className="mr-2 text-themecolor1" />
                                          <span>
                                            {formatDate(val.EventDate)} -{" "}
                                            {val.EventStartTime} to{" "}
                                            {val.EventEndTime}
                                          </span>
                                        </p>

                                        {/* Location */}
                                        <p className="flex items-center md:text-sm text-xs mb-1.5 md:mb-0 text-gray-600 dark:text-gray-300 my-1">
                                          <FaLocationDot className="mr-2 text-themecolor1" />
                                          <span className="ml-1">
                                            {val.Location}, {val.City},{" "}
                                            {val.Country}
                                          </span>
                                        </p>
                                      </div>

                                      {val.Status === 1111 ? (
                                        <button
                                          onClick={() =>
                                            HandleSpitePopup(val.Id)
                                          }
                                          className="my-5 py-2 px-10 rounded-full md:text-normal text-xs flex mx-auto bg-gradient-to-r from-pink-500 to-red-500 text-white shadow-lg hover:scale-105 transition-all"
                                        >
                                          Split & Share
                                        </button>
                                      ) : (
                                        ""
                                      )}

                                      {val.Status === 1 &&
                                      val.PaymentStatus === 1 ? (
                                        <button
                                          onClick={() =>
                                            HandleSpitePopup(val.Id)
                                          }
                                          className="my-5 py-2 px-10 rounded-full flex mx-auto bg-gradient-to-r from-pink-500 to-red-500 text-white shadow-lg hover:scale-105 transition-all"
                                        >
                                          Split & Share
                                        </button>
                                      ) : val.Status === 0 &&
                                        val.PaymentStatus === 0 ? (
                                        <div className="flex items-center justify-center mt-5">
                                          <p className="md:text-normal text-center leading-5 text-xs text-gray-600 dark:text-gray-300">
                                            {getStatusBadge(val.Status)}
                                            Please contact this number:{" "}
                                            <a
                                              href="tel:+918866560333"
                                              className="font-bold text-themecolor1"
                                            >
                                              8866560333
                                            </a>
                                            . If the payment was deducted, the
                                            amount will be refunded to your
                                            account within 14 working days.
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="flex items-center justify-center mt-5">
                                          <p className="md:text-normal text-xs text-gray-600 dark:text-gray-300">
                                            {getStatusBadge(val.Status)} Enjoy
                                            the show
                                          </p>
                                        </div>
                                      )}

                                      {val.OrderSplits.length > 0 && (
                                        <div className="overflow-x-auto custom-scroll">
                                          <table className="min-w-full mt-3 bg-white border border-gray-100 rounded-lg shadow-lg">
                                            <thead className="bg-gray-200 ">
                                              <tr>
                                                <th className="py-3 px-4 text-gray-600 md:text-normal text-sm text-left">
                                                  Phone
                                                </th>
                                                <th className="py-3 px-4 text-gray-600 md:text-normal text-sm text-left">
                                                  Qty
                                                </th>
                                                <th className="py-3 px-4 text-gray-600 md:text-normal text-sm text-left">
                                                  Print
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {val.OrderSplits.map(
                                                (orderSplit, index) => (
                                                  <tr
                                                    key={index}
                                                    className="hover:bg-gray-100 text-center"
                                                  >
                                                    <td className="py-2 px-4 border-b border-gray-100 md:text-normal text-sm text-black text-left">
                                                      {orderSplit.Phone}
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-gray-100 md:text-normal text-sm text-black text-left">
                                                      {orderSplit.Qty}
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-gray-100 md:text-normal text-sm text-black text-left flex justify-center items-center">
                                                      <BsFillPrinterFill
                                                        className="text-3xl cursor-pointer bg-themecolor3 mx-1.5 text-white p-1.5 rounded"
                                                        onClick={() => {
                                                          HandlePrint2(
                                                            orderSplit.OrderId,
                                                            orderSplit.Id
                                                          );
                                                        }}
                                                      />
                                                      <FaMessage
                                                        className="text-3xl cursor-pointer bg-themecolor3 mx-1.5 text-white p-1.5 rounded"
                                                        onClick={() => {
                                                          HandleTextSMS2(
                                                            orderSplit.OrderId,
                                                            orderSplit.Id
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      )}
                                    </div>

                                    <div className="w-full md:w-1/3 mt-4 md:mt-0 bg-gray-100 dark:bg-gray-700 p-3 rounded-lg shadow-md">
                                      <h3 className="md:text-normal text-sm font-semibold text-gray-800 dark:text-gray-200 mb-4 border-b border-gray-300 pb-1.5 text-center">
                                        Payment Details
                                      </h3>

                                      <div className="overflow-x-auto custom-scroll">
                                        <table className="w-full text-sm text-gray-600 dark:text-gray-300">
                                          <tbody>
                                            {[
                                              {
                                                label: "Transaction Id",
                                                value: val.transactionId,
                                              },
                                              {
                                                label: "Ticket Name",
                                                value: val.TicketName,
                                              },
                                              {
                                                label: "Quantity",
                                                value: val.Qty,
                                              },
                                              {
                                                label: "Ticket Price",
                                                value: `₹${val.Price}`,
                                              },
                                              {
                                                label: "Platform Charge",
                                                value: `₹${val.Charge}`,
                                              },
                                              {
                                                label: "Coupon Code",
                                                value: val.CouponCode,
                                              },
                                              {
                                                label: "Coupon Amount",
                                                value: `₹${val.CouponAmount}`,
                                              },
                                              {
                                                label: "Sub Total",
                                                value: `₹${val.SubTotal}`,
                                              },
                                              {
                                                label: "Total",
                                                value: `₹${val.Total}`,
                                              },
                                              {
                                                label: "Booking Date",
                                                value: formatEntDtDate(
                                                  val.EntDt
                                                ),
                                              },
                                              {
                                                label: "Payment Method",
                                                value: val.PaymentMethod,
                                              },

                                              {
                                                label: "Payment Status",
                                                value:
                                                  val.PaymentStatus === 1 ? (
                                                    <span className="bg-green-500 text-white text-xs p-0.5 px-3 rounded">
                                                      Paid
                                                    </span>
                                                  ) : (
                                                    <span className="bg-red-500 text-white text-xs p-0.5 px-3 rounded">
                                                      Pending
                                                    </span>
                                                  ),
                                              },
                                            ].map((item, i) => (
                                              <tr key={i} className="">
                                                <th className="md:px-4 px-2 py-2 border md:text-normal text-xs whitespace-nowrap dark:border-gray-600 dark:text-white text-left">
                                                  {item.label}
                                                </th>
                                                <td className="md:px-4 px-2 py-2 border md:text-normal text-[12px] whitespace-nowrap text-gray-400 dark:text-white dark:border-gray-600">
                                                  {item.value}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    )}

                    {EventBookData?.length > 5 ? (
                      <div className="flex justify-between mt-4">
                        <button
                          onClick={handlePrevious}
                          disabled={currentPage === 1}
                          className={`py-2 px-4 rounded ${
                            currentPage === 1
                              ? "bg-gray-300"
                              : "bg-themecolor1 text-white"
                          }`}
                        >
                          Previous
                        </button>
                        <span className="py-2 px-4">{`Page ${currentPage} of ${totalPages}`}</span>
                        <button
                          onClick={handleNext}
                          disabled={currentPage === totalPages}
                          className={`py-2 px-4 rounded ${
                            currentPage === totalPages
                              ? "bg-gray-300"
                              : "bg-themecolor1 text-white"
                          }`}
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {ticketpopup && selectedEvent && (
        <div>
          <div className="px-5 justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-96">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-boxdark-2 dark:text-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-2 border-b dark:border-zinc-600 border-solid border-blueGray-200 rounded-t bg-themecolor1">
                  <h3 className="md:text-normal text-sm font-semibold text-white pl-2">
                    Ticket
                  </h3>
                  <button
                    className="background-transparent text-white font-bold uppercase md:text-normal text-sm outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setticket(false)}
                  >
                    <RxCross2 />
                  </button>
                </div>
                <div className="p-3 py-1 relative">
                  <div className="dark:bg-gray-800 rounded-lg ">
                    <div className="flex items-center mt-1.5">
                      <img
                        src={selectedEvent.Banner}
                        alt="Event Thumbnail"
                        className="w-full md:h-28 h-auto mb-2 md:object-cover"
                      />
                    </div>
                    <p className="font-semibold md:text-normal text-sm text-gray-800 dark:text-white mb-2">
                      {selectedEvent.EventName}
                    </p>

                    {/* User Name */}
                    {selectedEvent.UserName && (
                      <p className="flex items-center md:text-sm text-xs mb-1.5 md:mb-0 text-gray-600 dark:text-gray-300 my-1">
                        <FaUser className="mr-2 text-themecolor1" />
                        <span className="font-medium">
                          {selectedEvent.UserName}
                        </span>
                      </p>
                    )}

                    {/* User Email */}
                    {selectedEvent.UserEmail && (
                      <p className="flex items-center md:text-sm text-xs mb-1.5 md:mb-0 text-gray-600 dark:text-gray-300 my-1">
                        <FaEnvelope className="mr-2 text-themecolor1" />
                        <span className="font-medium">
                          {selectedEvent.UserEmail}
                        </span>
                      </p>
                    )}

                    {/* Event Organizer */}
                    {selectedEvent.EventOrganizer && (
                      <p className="flex items-center md:text-sm text-xs mb-1.5 md:mb-0 text-gray-600 dark:text-gray-300 my-1">
                        <CgOrganisation className="mr-2 text-themecolor1" />
                        <span className="font-medium">
                          {selectedEvent.EventOrganizer}
                        </span>
                      </p>
                    )}

                    {/* Event Date and Time */}
                    <p className="flex items-center md:text-sm text-xs mb-1.5 md:mb-0 text-gray-600 dark:text-gray-300 my-1">
                      <FaCalendar className="mr-2 text-themecolor1" />
                      <span>
                        {formatDate(selectedEvent.EventDate)} -{" "}
                        {selectedEvent.EventStartTime} to{" "}
                        {selectedEvent.EventEndTime}
                      </span>
                    </p>

                    {/* Location */}
                    <p className="flex items-center md:text-sm text-xs mb-1.5 md:mb-0 text-gray-600 dark:text-gray-300 my-1">
                      <FaLocationDot className="mr-2 text-themecolor1" />
                      <span className="ml-1">
                        {selectedEvent.Location}, {selectedEvent.City},{" "}
                        {selectedEvent.Country}
                      </span>
                    </p>
                  </div>
                </div>
                <p className="border-t-2 border-gray-200 text-center dark:border-white p-2 md:text-normal text-xs">
                  {getStatusBadge(selectedEvent.Status)} Enjoy the show
                </p>
                <div className="border-t-2 border-gray-200  flex items-center px-3">
                  <img
                    src={selectedEvent.QRCODE}
                    alt=""
                    className="w-20 dark:bg-white"
                  />
                  <div className="mx-auto">
                    <p className="text-center text-sm text-themecolor1">
                      <b>BOOKING ID: </b>
                      {selectedEvent.Id}
                    </p>
                  </div>
                </div>
                <div className="border-t-2 border-gray-200 md:p-3 p-1 flex items-center">
                  <p className="text-center mx-auto text-[11px]">
                    Cancellation not available for this venue
                  </p>
                </div>
                <div className="border-t-2 border-gray-200 md:p-3 p-2 flex items-center font-bold md:px-4">
                  <p className="md:text-normal text-sm">Total Amount</p>
                  <p className="ml-auto text-green-700 md:text-normal text-sm">
                    Rs. {selectedEvent.Total}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}

      {splitepopup && SplitePop && (
        <div className="fixed inset-0 flex items-center justify-center z-50 outline-none focus:outline-none">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative my-6 mx-5 max-w-xl bg-white rounded-lg shadow-lg transform transition-all duration-300">
            <div className="p-5 relative">
              <div className="flex items-start justify-between p-2 border-b dark:border-zinc-600 border-solid border-blueGray-200 rounded-t">
                <h3 className="text-xl font-semibold text-black pl-2">
                  Share Your Tickets With Friends
                </h3>
              </div>
              <button
                className="background-transparent text-black absolute -top-3 bg-white rounded-full -right-3 font-bold uppercase text-3xl outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setsplitepopup(false)}
              >
                <RxCross2 />
              </button>
              <form onSubmit={formik.handleSubmit}>
                <input type="hidden" name="TicketId" value={SplitePop.Id} />
                <div className="my-6">
                  <label className="block text-gray-700 mb-1" htmlFor="mobile">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    id="mobile"
                    name="Phone"
                    value={formik.values.Phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Phone number"
                    className={`w-full p-3 border ${
                      formik.touched.Phone && formik.errors.Phone
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded focus:outline-none transition duration-200`}
                  />
                  {formik.touched.Phone && formik.errors.Phone ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.Phone}
                    </div>
                  ) : null}
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 mb-1"
                    htmlFor="quantity"
                  >
                    Quantity
                  </label>
                  <select
                    id="quantity"
                    name="Qty"
                    value={formik.values.Qty}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`w-full p-3 border ${
                      formik.touched.Qty && formik.errors.Qty
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded focus:outline-none transition duration-200`}
                  >
                    <option value="">Select Ticket</option>
                    {SplitePop?.Qty ? (
                      Array.from({ length: SplitePop.Qty - 1 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))
                    ) : (
                      <option disabled>No Qty available</option>
                    )}
                  </select>
                  {formik.touched.Qty && formik.errors.Qty ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.Qty}
                    </div>
                  ) : null}
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded transition duration-300 shadow-md hover:shadow-lg"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orderhistory;
