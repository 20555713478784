import React, { useEffect, useState } from "react";
import { IoIosLaptop } from "react-icons/io";
import { GoClock } from "react-icons/go";
import { MdMonitor } from "react-icons/md";
import LogoImg from "../../Images/newlogo.png";
import { useFormik } from "formik";
import { sendOTP, verifyOTP } from "../API/LoginApi";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import usePageSEO from "../hoosk/usepageSEO/Index";
import FormLoader from "../common/FormLoader";
import Config from "../API/Config";
import ScrollToTop from "../ScrollToTop";

const phoneLengthByCountry = {
  India: 10, // India
  Australia: 10, // Australia
  UK: 10, // UK
  USA: 10, // USA
  Canada: 10, // Canada
  // Add other countries and their rules here
};
const getPhoneValidationSchema = (countryCode) => {
  const phoneLength = phoneLengthByCountry[countryCode] || 10;
  return yup
    .string()
    .required(`Phone number is required for ${countryCode}`)
    .test(
      "is-valid-length",
      `Enter a valid phone number of length ${phoneLength}`,
      (value) => value && value.replace(/\D/g, "").length === phoneLength
    );
};
const validationSchema2 = yup.object().shape({
  OTP: yup
    .string()
    .matches(/^[0-9]+$/, "OTP must contain only numbers")
    .length(4, "OTP must be exactly 4 digits")
    .required("OTP is required"),
});

const Signin = () => {
  const getCountrySlug = Config.getCountrySlug();
  const validationSchema = yup.object().shape({
    EOP: yup.string().when("Country", {
      is: (value) => !!value, // Ensure Country is selected
      then: (value) => getPhoneValidationSchema(getCountrySlug),
      otherwise: yup.string().required("Phone number is required"),
    }),
  });
  usePageSEO(
    "Love My Show | Sign In", // title
    "Love My Show | Sign In", // description
    ["Love My Show  | Sign In"] // keywords
  );
  const navigate = useNavigate();

  const [isMobileScreen, setIsMobileScreen] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds = 1 minute
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const formik = useFormik({
    initialValues: {
      EOP: "", // Add EOP field
      Country: getCountrySlug,
      Type: "V",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await sendOTP(values);
        if (response.status === true) {
          setIsMobileScreen(false);
        }
        formik2.setFieldValue("EOP", values.EOP);
        setResendOTP((prevState) => ({
          ...prevState,
          EOP: values.EOP, // Update EOP in ResendOTP state
        }));
        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {
      EOP: "",
      Country: getCountrySlug,
      Type: "V",
      OTP: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await verifyOTP(values);
        if (response.status === true) {
          navigate("/profile");
          window.location.reload();
        }

        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const [ResendOTP, setResendOTP] = useState({
    EOP: "",
    Country: getCountrySlug,
    Type: "U",
  });
  const handleResendOTP = async () => {
    setIsFormLoading(true);
    try {
      setTimer(60);
      setShowResend(false);
      await sendOTP(ResendOTP); // Replace with actual function call
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  return (
    <div>
      <ScrollToTop />
      <div className="grid grid-cols-1 lg:grid-cols-2 bg-gray-200 dark:bg-boxdark-2 dark:text-white ">
        {/*============================================== Content ================================================*/}
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <div className="bg-white py-12 dark:bg-boxdark-2 dark:text-white md:block hidden">
         <div className="text-center ">
          
         <h1 className="md:text-2xl xl:mx-32 lg:mx-14 md:mx-6 mx-3 font-bold">
         Benefits of using - Do It Yourself
         </h1>
         <span>
         “our new event management too"</span>
         </div>

          <div className="flex shadow-lg  foldsmall:flex-col rounded my-5 xl:mx-32 lg:mx-14 md:mx-6 mx-3 p-4 dark:border dark:border-zinc-600">
            <div className="text-5xl my-auto foldsmall:mx-auto">
              <IoIosLaptop className="" />
            </div>
            <div className="mx-4 my-auto md:mx-4">
              <h1 className="text-1xl font-bold">Quick & Easy registration</h1>
              <p className="text-sm mt-1">
                Complete your registration with just your PAN card and bank
                details
              </p>
            </div>
          </div>

          <div className="flex shadow-lg  foldsmall:flex-col rounded my-5  xl:mx-32 lg:mx-14 md:mx-6 mx-3 p-4 dark:border dark:border-zinc-600">
            <div className="text-5xl my-auto foldsmall:mx-auto">
              <GoClock className="" />
            </div>
            <div className="mx-4 my-auto md:mx-4">
              <h1 className="text-1xl font-bold">
                Take your events live superfast!
              </h1>
              <p className="text-sm mt-1">
                Publish your event within just 15 minutes! Add event details,
                dates and ticket and BAM! Your evnet is ready
              </p>
            </div>
          </div>

          <div className="flex shadow-lg  foldsmall:flex-col rounded my-5  xl:mx-32 lg:mx-14 md:mx-6 mx-3 p-4 dark:border dark:border-zinc-600">
            <div className="text-5xl my-auto foldsmall:mx-auto">
              <MdMonitor className="" />
            </div>
            <div className="mx-4 my-auto md:mx-4">
              <h1 className="text-1xl font-bold">
                Monitor analytics & insights
              </h1>
              <p className="text-sm mt-1">
                Track event sales, daily ticketing, get daily insights and more
                in real time.
              </p>
            </div>
          </div>
        </div>

        {/*============================================ Form Part=================================================== */}
        <div className="py-12 my-auto ">
          {isMobileScreen ? (
            <div>
              <form onSubmit={formik.handleSubmit}>
                <div className=" shadow[0px 3px 10px -3px] border-2 border-themecolor1 xl:mx-60 surface:mx-28  mx-5 rounded-xl dark:bg-boxdark-2 dark:text-white bg-white py-5   px-3">
                  <img
                    src={LogoImg}
                    alt="LogoImage"
                    className="p-2 w-[60%] mx-auto mb-5 bg-white"
                  />

                  <label className="text-sm" htmlFor="phone">
                    Enter Your Mobile No
                  </label>

                  <input
                    type="text"
                    name="EOP"
                    autoFocus
                    value={formik.values.EOP}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="border dark:border-zinc-600 outline-none w-full px-3 py-3 mt-2 text-xs rounded-md dark:bg-boxdark-2 dark:text-white"
                    placeholder="Enter Mobile Number"
                    maxLength={phoneLengthByCountry[getCountrySlug] || 10}
                  />

                  {formik.touched.EOP && formik.errors.EOP ? (
                    <div className="text-red-500 text-xs mt-2">
                      {formik.errors.EOP}
                    </div>
                  ) : null}
                  <div className="grid grid-cols-1 gap-2 ">
                    <button
                      type="submit"
                      className="py-2 bg-themecolor1  text-white text-center font-bold rounded-md mt-6 "
                    >
                      Send OTP
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div>
              <form onSubmit={formik2.handleSubmit}>
                <div className=" shadow[0px 3px 10px -3px] border-2 border-themecolor1 xl:mx-60 surface:mx-28  mx-5 rounded-xl dark:bg-boxdark-2 dark:text-white bg-white py-5   px-3">
                  <img
                    src={LogoImg}
                    alt="LogoImage"
                    className="p-2 w-[60%] mx-auto mb-5 bg-white"
                  />

                  <label className="text-sm" htmlFor="phone">
                    Enter Your OTP
                  </label>
                  <input
                    type="text"
                    name="OTP"
                    autoFocus
                    value={formik2.values.OTP}
                    onChange={formik2.handleChange}
                    inputMode="numeric"
                    maxLength={4}
                    onBlur={formik2.handleBlur}
                    className="border dark:border-zinc-600 outline-none w-full px-3 py-3 mt-2 text-xs rounded-md dark:bg-boxdark-2 dark:text-white"
                    placeholder="Enter OTP"
                  />
                  {formik2.touched.OTP && formik2.errors.OTP ? (
                    <div className="text-red-500 text-xs mt-2">
                      {formik2.errors.OTP}
                    </div>
                  ) : null}

                  <div className="text-center text-xs mt-3">
                    {showResend ? (
                      <>
                        OTP expired?{" "}
                        <button
                          onClick={handleResendOTP}
                          type="button"
                          className="underline text-themecolor1"
                        >
                          Resend OTP
                        </button>
                      </>
                    ) : (
                      `Resend OTP in ${formatTimer()}`
                    )}
                  </div>
                  <div className="grid grid-cols-1 gap-2 ">
                    <button
                      type="submit"
                      className="py-2 bg-themecolor1  text-white text-center font-bold rounded-md mt-6 "
                    >
                      Verify OTP
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signin;
