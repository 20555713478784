import React from 'react';

import loader from '../../Images/newlogo.png';
const Loader = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-white">
      <div className=" ">
        <img src={loader} alt="" className="lg:w-72 mb:w-64 w-40" />
      </div>
    </div>
  );
};

export default Loader;
