import React, { useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { getOfferBySlug } from "../API/OfferApi";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import ScrollToTop from "../ScrollToTop";

const OfferInnerPage = () => {
  usePageSEO(
    "Love My Show ", // title
    "Love My Show ", // description
    ["Love My Show  "] // keywords
  );
  const [faqStates, setFaqStates] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  // Function to toggle FAQ item
  const toggleFaq = (index) => {
    setFaqStates((prevState) => {
      const newState = [...prevState];
      // Toggle the state of the clicked FAQ item
      newState[index] = !newState[index];
      // Close all other FAQ items
      for (let i = 0; i < newState.length; i++) {
        if (i !== index) {
          newState[i] = false;
        }
      }
      return newState;
    });
  };

  // ------------------------Offer-----------------------
  const { Slug } = useParams();
  const [OfferData, setOfferData] = useState([]);
  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const result = await getOfferBySlug(Slug);
        setOfferData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchOfferData();
  }, [Slug]);

  usePageSEO(
    OfferData
      ? `Love My Show | ${OfferData?.Title}`
      : "Love My Show | Booking - Event",
    OfferData
      ? `Love My Show | ${OfferData?.Title}`
      : "Love My Show | Booking - Event",
    [
      OfferData
        ? `Love My Show | ${OfferData?.Title}`
        : "Love My Show | Booking - Event",
    ] // No keywords provided in this example
  );

  const FAQLength = OfferData?.FAQs?.length; 

  return (
    <div className="dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      <img
        src={OfferData?.Image}
        alt=""
        className="w-full relative md:h-96 h-44"
      />
      {loading ? (
        <div className="flex justify-center items-center my-48">
          <ClipLoader color={"#DC3558"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="container mx-auto">
          <div className="md:flex mt-7 mx-3">
            <img src={OfferData?.Icon} alt="" className="rounded w-28" />
            <h1 className="md:px-8 my-auto text-lg">{OfferData?.Title}</h1>
          </div>
          <p
            className="text-base py-5 text-justify px-4 overflow-hidden"
            dangerouslySetInnerHTML={{
              __html: OfferData ? OfferData?.Content : "",
            }}
          ></p>

          {FAQLength !== 0 && (
            <div className="pb-8 mx-3">
              {/*=============================================== FAQS ==============================================*/}
              <div className=" mt-10">
                <h1 className="underline">FAQS</h1>

                {OfferData?.FAQs?.map((faqState, index) => (
                  <div key={index} className="border-2 border-themecolor1 mt-5">
                    <button
                      onClick={() => toggleFaq(index)} // Pass index to toggleFaq function
                      className="flex font-semibold p-2 rounded"
                    >
                      {faqStates[index] ? (
                        <CiCircleMinus className="text-xl my-auto mr-3" />
                      ) : (
                        <CiCirclePlus className="text-xl my-auto mr-3" />
                      )}
                      {faqState.Title}
                    </button>
                    {faqStates[index] && (
                      <p className="my-2 mx-2">{faqState?.Content}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OfferInnerPage;
