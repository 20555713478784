import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { PrimeReactProvider } from "primereact/api";
import ScrollToTop from "./Components/ScrollToTop";
// import DisableShortcuts from "./Components/Function/DisableShortcuts";
// import DisableCopyPaste from "./Components/Function/DisableCopyPaste";
import ClearLocalStorageAfter24Hours from "./Components/Function/ClearLocalStorageAfter24Hours";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <App /> 
      {/*<DisableShortcuts />
      <DisableCopyPaste />*/}
      <ClearLocalStorageAfter24Hours />
      <ToastContainer />
    </PrimeReactProvider>
  </React.StrictMode>
);
reportWebVitals();
