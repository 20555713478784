/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Config from "../API/Config";
import { AddKYC1, getKYCById } from "../API/KYCApi";
import * as Yup from "yup";
import usePageSEO from "../hoosk/usepageSEO/Index";
import ClipLoader from "react-spinners/ClipLoader";
import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";

const validationSchema = Yup.object().shape({
  GSTRegistration: Yup.string().required(
    "Please select an option for GST registration"
  ),
  // GSTIN: Yup.string().when("GSTRegistration", {
  //   is: "yes",
  //   then: Yup.string()
  //     .matches(/^[0-9A-Z]{15}$/, "GSTIN must be 15 alphanumeric characters")
  //     .required("GSTIN is required"),
  //   otherwise: Yup.string(),
  // }),
  // PAN: Yup.string().when("GSTRegistration", {
  //   is: "yes",
  //   then: Yup.string()
  //     .matches(
  //       /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  //       "PAN must be 10 alphanumeric characters"
  //     )
  //     .required("PAN is required"),
  //   otherwise: Yup.string(),
  // }),
  RegisteredCompanyName: Yup.string().required(
    "Registered Company Name is required"
  ),
  RegisteredCompanyAddress: Yup.string().required(
    "Registered Company Address is required"
  ),
  BeneficiaryNames: Yup.string().required("Beneficiary Names is required"),
  AccountNumber: Yup.string().required("Account Number is required"),
  IFSC: Yup.string()
    .matches(
      /^[A-Z]{4}0[A-Z0-9]{6}$/,
      "IFSC must be 11 alphanumeric characters"
    )
    .required("IFSC is required"),
});
const Kyc1 = () => {
  usePageSEO(
    "Love My Show | Kyc Step 1", // title
    "Love My Show | Kyc Step 1", // description
    ["Love My Show | Kyc Step 1 "] // keywords
  );

  const usenavigate = useNavigate();

  const Id = Config.getId();
  const [loading, setLoading] = useState(true); // Loading state
  // ==================Kyc Data===============

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Id) {
          const UserData = await getKYCById(Id);
          formik.setValues(UserData[0]);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, [Id]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      Id: Id,
      GSTRegistration: "",
      RegisteredCompanyName: "",
      RegisteredCompanyAddress: "",
      AccountType: "",
      GSTIN: "",
      BeneficiaryNames: "",
      AccountNumber: "",
      IFSC: "",
      PAN: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        await AddKYC1(values);
        usenavigate("/kyc-2");
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  return (
    <div className="dark:bg-boxdark-2 dark:text-white ">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="container w-full xl:w-2/3 px-4 mx-auto pt-5 py-10">
        {/*================= General Information ====================*/}
        <h1 className="text-2xl text-center font-semibold">
          General Information
        </h1>
        <h1 className="bg-[#FFF3CD] border dark:border-zinc-600 border-[#FFECB5] px-5 py-4 rounded-lg text-[#664D03] font-medium my-4 mx-4 lg:mx-0">
          Your KYC status is Pending
        </h1>

        {loading ? (
          <div className="flex justify-center items-center my-48">
            <ClipLoader color={"#DC3558"} loading={loading} size={30} />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="border dark:border-zinc-600">
              <h1 className="text-lg  p-2 text-white font-bold bg-themecolor1 ">
                General Information
              </h1>
              <div className=" bg-white dark:bg-boxdark-2 dark:text-white p-4">
                <h1 className="font-semibold md:text-lg">
                  Do you have a GST registration?
                </h1>
                {/* ===================Yes and No ====================*/}
                <div className="col-md-12 mt-5">
                  <div className="text-black">
                    <input
                      type="radio"
                      name="GSTRegistration"
                      value="yes"
                      checked={formik.values.GSTRegistration === "yes"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className=" dark:text-white"
                    />
                    <label htmlFor="" className="px-2  dark:text-white">
                      Yes
                    </label>
                    <input
                      type="radio"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.GSTRegistration === "no"}
                      name="GSTRegistration"
                      value="no"
                      className=" dark:text-white"
                    />
                    <label htmlFor="" className="px-2  dark:text-white">
                      No
                    </label>
                  </div>
                  {formik.values.GSTRegistration === "yes" && (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 gap-4 my-5">
                      <div className="">
                        <h1 htmlFor="" className="mb-1 text-sm">
                          GSTIN <span className="text-red-600">*</span>
                        </h1>
                        <input
                          type="text"
                          value={formik.values.GSTIN}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="GSTIN"
                          className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  "
                          placeholder="GSTIN"
                        />
                        {formik.touched.GSTIN && formik.errors.GSTIN ? (
                          <div className="text-red-500 text-xs mt-2">
                            {formik.errors.GSTIN}
                          </div>
                        ) : null}
                      </div>
                      <div className="">
                        <h1 htmlFor="" className="mb-1 text-sm">
                          PAN <span className="text-red-600">*</span>
                        </h1>
                        <input
                          type="text"
                          value={formik.values.PAN}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="PAN"
                          className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  "
                          placeholder="PAN"
                        />
                        {formik.touched.PAN && formik.errors.PAN ? (
                          <div className="text-red-500 text-xs mt-2">
                            {formik.errors.PAN}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 gap-4 my-5">
                    <div>
                      <h1 className="mb-1 text-sm mt-3">
                        Registered Company Name/Organiser's name
                      </h1>
                      <input
                        type="text"
                        value={formik.values.RegisteredCompanyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="RegisteredCompanyName"
                        placeholder="Your Company Name Pvt.Ltd. or Your Company Name LLP"
                        className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  "
                      />
                      {formik.touched.RegisteredCompanyName &&
                      formik.errors.RegisteredCompanyName ? (
                        <div className="text-red-500 text-xs mt-2">
                          {formik.errors.RegisteredCompanyName}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <h1 className="mb-1 text-sm mt-3">
                        Registered Company Address
                      </h1>
                      <input
                        type="text"
                        value={formik.values.RegisteredCompanyAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="RegisteredCompanyAddress"
                        placeholder="Registered Address"
                        className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  "
                      />
                      {formik.touched.RegisteredCompanyAddress &&
                      formik.errors.RegisteredCompanyAddress ? (
                        <div className="text-red-500 text-xs mt-2">
                          {formik.errors.RegisteredCompanyAddress}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/*================= Bank Account Details ====================*/}
              <h1 className="text-lg p-2 text-white font-bold bg-themecolor1">
                Bank Account Details
              </h1>
              <div className=" bg-white dark:bg-boxdark-2 dark:text-white p-4">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-4 md:gap-4">
                  <div>
                    <h1 className="mb-1 text-sm">
                      Account Type <span className="text-red-600">*</span>
                    </h1>
                    <select
                      name="AccountType"
                      value={formik.values.AccountType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  "
                    >
                      <option disabled>Select Account Type</option>
                      <option value="Savings">Savings</option>
                      <option value="Current">Current</option>
                    </select>
                    {formik.touched.AccountType && formik.errors.AccountType ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.AccountType}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <h1 className="mb-1 mt-3 md:mt-0 text-sm">
                      Beneficiary Names <span className="text-red-600">*</span>
                    </h1>
                    <input
                      type="text"
                      value={formik.values.BeneficiaryNames}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="BeneficiaryNames"
                      placeholder="Beneficiary Names"
                      className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                    />
                    {formik.touched.BeneficiaryNames &&
                    formik.errors.BeneficiaryNames ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.BeneficiaryNames}
                      </div>
                    ) : null}
                  </div>
                  <div className="">
                    <h1 className="mb-1 mt-3 md:mt-0 text-sm">
                      Account Number <span className="text-red-600">*</span>
                    </h1>
                    <input
                      type="text"
                      value={formik.values.AccountNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="AccountNumber"
                      placeholder="Account Number"
                      className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  "
                    />
                    {formik.touched.AccountNumber &&
                    formik.errors.AccountNumber ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.AccountNumber}
                      </div>
                    ) : null}
                  </div>
                  <div className="">
                    <h1 className="mb-1 mt-3 md:mt-0 text-sm">
                      IFSC <span className="text-red-600">*</span>
                    </h1>
                    <input
                      type="text"
                      value={formik.values.IFSC}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="IFSC"
                      placeholder="IFSC"
                      className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                    />
                    {formik.touched.IFSC && formik.errors.IFSC ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.IFSC}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="flex mb-2 mx-3">
                <Link
                  to={"/kyc"}
                  className="block bg-green-500 rounded-md text-white font-semibold py-2 px-5"
                >
                  Back
                </Link>
                <button
                  type="submit"
                  disabled={isFormLoading}
                  className="block ms-auto bg-black rounded-md text-white font-semibold py-2 px-5"
                >
                  {isFormLoading
                    ? "Submitting..."
                    : "Proceed to Upload Documents"}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Kyc1;
