// TinySetUp.jsx
import React from "react";
import TinyMCEEditor from "./TinyMain";

const TinySetUp = ({ value, onChange, onBlur, ref }) => {
  return (
    <TinyMCEEditor
      values={value}
      onChange={onChange}
      onBlur={onBlur}
      ref={ref}
    />
  );
};

export default TinySetUp;
