import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import usePageSEO from "../../hoosk/usepageSEO/Index";
import DashboardMenu from "../DashboardMenu";
import ScrollToTop from "../../ScrollToTop";
import FormLoader from "../../common/FormLoader";
import { getCouponById, updateCouponById } from "../../API/CoupenApi";

const validationSchema = Yup.object().shape({
  Code: Yup.string().required("Code is required"),
  Amount: Yup.string()
    .matches(/^\d+$/, "Amount must be a number")
    .required("Amount is required"),
  StartDate: Yup.string().required("StartDate is required"),
  EndDate: Yup.string().required("EndDate is required"),
});

const CouponEdit = () => {
  usePageSEO(
    "Love My Show | Coupon Edit", // title
    "Love My Show | Coupon Edit", // description
    ["Love My Show | Coupon Edit"] // keywords
  );
  const { Id } = useParams();
  const [formData, setFormData] = useState({
    Id: Id,
    VendorId: "",
    Code: "",
    Amount: "",
    StartDate: "",
    EndDate: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCouponById(Id);
        setFormData({
          Id: result.Id,
          VendorId: result.VendorId,
          Code: result.Code,
          Amount: result.Amount,
          StartDate: result.StartDate.split("T")[0], // Ensure the format is YYYY-MM-DD
          EndDate: result.EndDate.split("T")[0], // Ensure the format is YYYY-MM-DD
        });
      } catch (error) {
        console.error("Error fetching coupon data:", error);
      }
    };

    fetchData();
  }, [Id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear the error message for the current input field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const getMinDateTime = () => {
    const now = new Date();
    const minDate = now.toISOString().split("T")[0]; // Get YYYY-MM-DD format
    return minDate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const data = await updateCouponById(formData);
      if (data.status === true) {
        navigate("/coupon");
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setFormErrors(validationErrors);
      } else {
        console.error("Submission Error:", error);
      }
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <div className="dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full lg:grid grid-cols-5 py-10 px-3">
          <DashboardMenu />

          <div className="relative flex h-full flex-col min-w-0 break-words w-full rounded col-span-4 py-3">
            <div className="flex-auto">
              <div className="px-2">
                <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  <div className="border-b flex items-center bg-themecolor1 py-1 mb-2">
                    <Link to={"/coupon"}>
                      <button
                        type="button"
                        className="flex bg-green-500 text-white dark:bg-boxdark-2 dark:border-white dark:border px-6 py-1 my-1 ml-2 rounded-full"
                      >
                        Back
                      </button>
                    </Link>
                    <h1 className="text-sm ml-auto font-medium pt-0 text-white pr-3">
                      Coupon Edit
                    </h1>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="container mx-auto border my-2 p-4">
                      <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                        <div>
                          <h1 className="mb-2 text-sm">
                            Coupon Code
                            <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="text"
                            name="Code"
                            disabled
                            value={formData.Code}
                            onChange={(e) => handleInputChange(e)}
                            className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                            placeholder="Coupon Code"
                          />
                          <small className="text-gray-400">
                            You cannot change the code after creating the coupon
                          </small>
                          {formErrors.Code && (
                            <p className="text-red-500 text-sm">
                              {formErrors.Code}
                            </p>
                          )}
                        </div>
                        <div>
                          <h1 className="mb-2 text-sm">
                            Coupon Amount
                            <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="text"
                            name="Amount"
                            disabled
                            value={formData.Amount}
                            onChange={(e) => handleInputChange(e)}
                            className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                            placeholder="Coupon Amount"
                          />
                          <small className="text-gray-400">
                            You cannot change the amount after creating the
                            coupon
                          </small>
                          {formErrors.Amount && (
                            <p className="text-red-500 text-sm">
                              {formErrors.Amount}
                            </p>
                          )}
                        </div>
                        <div>
                          <h1 className="mb-2 text-sm">
                            Coupon Start Date
                            <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="date"
                            name="StartDate"
                            min={getMinDateTime()}
                            value={formData.StartDate}
                            onChange={(e) => handleInputChange(e)}
                            className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                            placeholder="Coupon Start Date"
                          />
                          {formErrors.StartDate && (
                            <p className="text-red-500 text-sm">
                              {formErrors.StartDate}
                            </p>
                          )}
                        </div>
                        <div>
                          <h1 className="mb-2 text-sm">
                            Coupon End Date
                            <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="date"
                            name="EndDate"
                            min={getMinDateTime()}
                            value={formData.EndDate}
                            onChange={(e) => handleInputChange(e)}
                            className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                            placeholder="Coupon End Date"
                          />
                          {formErrors.EndDate && (
                            <p className="text-red-500 text-sm">
                              {formErrors.EndDate}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center ">
                      <button
                        type="submit"
                        className="bg-[#EC5E71] block md:my-3 placeholder:my-2 text-white font-semibold px-12 p-2 rounded-md mx-auto"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponEdit;
