// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import { MdOutlinePayments } from "react-icons/md";
// import { FaCalendar, FaMinus, FaPlus } from "react-icons/fa6";
// import usePageSEO from "../hoosk/usepageSEO/Index";
// import { Link, useParams, useNavigate } from "react-router-dom";
// import {
//   PostEventBookTicketData,
//   getEventBookTicketData,
// } from "../API/EventApi";
// import { Tooltip } from "react-tooltip";
// import { useFormik } from "formik";
// import lmsicon from "../../Images/lms-icon-white.png";
// import { FaAddressCard, FaDollarSign, FaRupeeSign } from "react-icons/fa";
// import ClipLoader from "react-spinners/ClipLoader";
// import FormLoader from "../common/FormLoader";
// import ScrollToTop from "../ScrollToTop";
// import Config from "../API/Config";
// import soldout from "../../Images/Sold-Out.png";

// const BookingSteps3 = () => {
//   const { EventId, AddressId, DateId } = useParams();
//   const navigate = useNavigate();
//   const countrySlug = Config.getCountrySlug();
//   const [loading, setLoading] = useState(true);
//   const [isFormLoading, setIsFormLoading] = useState(false);
//   const [selectedTicketId, setSelectedTicketId] = useState(null);
//   const [count, setCount] = useState(1);
//   const [EventAddressData, setEventAddressData] = useState([]);
//   const [availableTickets, setAvailableTickets] = useState(100);
//   const [ticketQuantity, setTicketQuantity] = useState(100);
//   const [limitReached, setLimitReached] = useState(false);

//   // SEO metadata
//   usePageSEO("Love My Show | Book Event", "Book your event tickets", [
//     "Book Event",
//   ]);

//   // Fetch event ticket data
//   useEffect(() => {
//     const fetchEventAddressData = async () => {
//       try {
//         const result = await getEventBookTicketData(DateId);
//         setEventAddressData(result);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchEventAddressData();
//   }, [DateId]);

//   // Form handling with Formik
//   const formik = useFormik({
//     initialValues: {
//       Id: EventId,
//       AddressId: AddressId,
//       DateId: DateId,
//       TicketId: selectedTicketId,
//       Qty: count,
//     },
//     onSubmit: async (values) => {
//       setIsFormLoading(true);
//       try {
//         const response = await PostEventBookTicketData(values);
//         if (response.status === true) {
//           setAvailableTickets((prev) => prev - count);
//           navigate(
//             `/event/booking-steps/${EventId}/${AddressId}/${DateId}/${selectedTicketId}`,
//             {
//               state: response.responseData,
//             }
//           );
//         }
//       } catch (error) {
//         console.error("Error submitting the form:", error);
//       } finally {
//         setIsFormLoading(false);
//       }
//     },
//   });

//   // Ticket selection logic
//   const handleAddButtonClick = (ticketId, ticketQty) => {
//     setSelectedTicketId(ticketId);
//     setTicketQuantity(ticketQty);
//     setCount(1);
//     setLimitReached(false);
//   };

//   const increaseQuantity = () => {
//     if (count < Math.min(ticketQuantity, availableTickets)) {
//       setCount((prev) => prev + 1);
//       setLimitReached(false);
//     } else {
//       setLimitReached(true);
//     }
//   };

//   const decreaseQuantity = () => {
//     if (count > 1) {
//       setCount((prev) => prev - 1);
//       setLimitReached(false);
//     }
//   };

//   // Dynamically update form values
//   useEffect(() => {
//     formik.setFieldValue("TicketId", selectedTicketId);
//     formik.setFieldValue("Qty", count);
//   }, [selectedTicketId, count]);

//   return (
//     <div className="container mx-auto py-5">
//       <ScrollToTop />
//       {isFormLoading && <FormLoader loading={isFormLoading} />}
//       <div className="px-4 py-4">
//         {/* Booking Progress Steps */}
//         <div className="relative flex items-center justify-between md:w-1/2 mx-auto">
//           <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gradient-to-r from-themecolor1 to-themecolor2"></div>
//           <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300 dark:bg-white transition-all duration-500"></div>
//           <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
//             <img src={lmsicon} alt="" className="w-6 h-6" />
//           </div>
//           <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
//             <FaCalendar className="w-5 h-5" />
//           </div>
//           <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
//             <FaAddressCard className="w-5 h-5" />
//           </div>
//           <div className="relative grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center">
//             <MdOutlinePayments className="w-5 h-5" />
//           </div>
//         </div>

//         {/* Tickets Section */}
//         <div className="md:my-20 my-10">
//         <p className="text-center font-semibold dark:text-white md:text-xl mb-5 text-base">
//             Select Event Ticket
//           </p>
//           {loading ? (
//             <div className="flex justify-center items-center my-48">
//               <ClipLoader color={"#DC3558"} loading={loading} size={30} />
//             </div>
//           ) : EventAddressData.Tickets.length === 0 ? (
//             <img src={soldout} alt="Sold Out" className="w-96 mx-auto" />
//           ) : (
//             EventAddressData.Tickets.map((ticket) => (
//               <div
//                 key={ticket.Id}
//                 className="mx-auto w-full mb-4 flex flex-col justify-center px-0 py-5 bg-white border shadow-lg relative cursor-pointer lg:w-[30%] transition-all duration-300 ease-in-out dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600 rounded-lg"
//               >
//                 <div className="grid grid-cols-2">
//                   <div className="px-5">
//                     <h1 className="font-semibold md:text-base text-sm mb-1">{ticket.TicketName}</h1>
//                     <p className="text-themecolor1 dark:text-white flex items-center font-bold text-sm">
//                       {countrySlug === "India" ? (
//                         <FaRupeeSign />
//                       ) : (
//                         <FaDollarSign />
//                       )}{" "}
//                       {ticket.TicketAmount}
//                     </p>
//                   </div>
//                   <div className="flex justify-end items-center">
//                     {selectedTicketId === ticket.Id ? (
//                       <>
//                       <button
//                         onClick={decreaseQuantity}
//                         className="mx-2 md:h-8 md:w-8 h-7 w-7 md:text-xl text-base border border-themecolor1 rounded-full"
//                       >
//                         <FaMinus className="ml-1" />
//                       </button>

//                       <div className="mt-1">{count}</div>

//                       <button
//                         onClick={increaseQuantity}
//                         className={`mx-2 md:h-8 md:w-8 h-7 w-7 md:text-xl text-base border border-themecolor1 rounded-full ${
//                           count >= Math.min(ticketQuantity, availableTickets)
//                             ? "opacity-50 cursor-not-allowed"
//                             : ""
//                         }`}
//                         disabled={count >= Math.min(ticketQuantity, availableTickets)}
//                         id={`button-${ticket.Id}`}
//                       >
//                         <FaPlus className="ml-1" />
//                       </button>

//                       {limitReached && (
//                         <Tooltip
//                           id={`button-${ticket.Id}`}
//                           place="top"
//                           content={`You cannot add more than ${Math.min(ticketQuantity, availableTickets)} tickets.`}
//                         />
//                       )}
//                     </>

//                     ) : (
//                       <button
//                         onClick={() =>
//                           handleAddButtonClick(ticket.Id, ticket.TicketQuantity)
//                         }
//                         className="bg-gradient-to-r from-themecolor1 to-themecolor2 text-white text-xs px-4 py-1 mx-2 rounded-sm"
//                       >
//                         Add
//                       </button>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             ))
//           )}
//         </div>

//         {/* Navigation Buttons */}
//         <div className="flex justify-between md:w-1/2 mx-auto">
//           <div className=" mt-3 md:mt-0">
//             <Link
//               to={`/event/booking-steps/${EventId}/${AddressId}`}
//               className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor2 to-themecolor1 text-white shadow-lg hover:scale-105 transition-all"
//             >
//               Previous
//             </Link>
//           </div>
//           <div className=" mt-3 md:mt-0">
//             {selectedTicketId === null ? (
//               <>
//                 <button
//                   data-tooltip-id="nextButtonTooltip"
//                   className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all"
//                 >
//                   Next
//                 </button>
//                 <Tooltip id="nextButtonTooltip" place="left" effect="solid">
//                   Select Ticket
//                 </Tooltip>
//               </>
//             ) : (
//               <form onSubmit={formik.handleSubmit}>
//                 <button
//                   type="submit"
//                   className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all"
//                 >
//                   Next
//                 </button>
//               </form>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BookingSteps3;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MdOutlinePayments } from "react-icons/md";
import { FaCalendar, FaMinus, FaPlus } from "react-icons/fa6";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  PostEventBookTicketData,
  getEventBookTicketData,
} from "../API/EventApi";
import { Tooltip } from "react-tooltip";
import { useFormik } from "formik";
import lmsicon from "../../Images/lms-icon-white.png";
import { FaAddressCard, FaDollarSign, FaRupeeSign } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import Config from "../API/Config";
import soldout from "../../Images/Sold-Out.png";

const BookingSteps3 = () => {
  const { EventId, AddressId, DateId } = useParams();
  const navigate = useNavigate();
  const countrySlug = Config.getCountrySlug();
  const [loading, setLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [count, setCount] = useState(1);
  const [EventAddressData, setEventAddressData] = useState([]);
  const [availableTickets, setAvailableTickets] = useState(100);
  const [ticketQuantity, setTicketQuantity] = useState(100);
  const [limitReached, setLimitReached] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0); // State for total price

  // SEO metadata
  usePageSEO("Love My Show | Book Event", "Book your event tickets", [
    "Book Event",
  ]);

  // Fetch event ticket data
  useEffect(() => {
    const fetchEventAddressData = async () => {
      try {
        const result = await getEventBookTicketData(DateId);
        setEventAddressData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEventAddressData();
  }, [DateId]);

  // Form handling with Formik
  const formik = useFormik({
    initialValues: {
      Id: EventId,
      AddressId: AddressId,
      DateId: DateId,
      TicketId: selectedTicketId,
      Qty: count,
    },
    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        const response = await PostEventBookTicketData(values);
        if (response.status === true) {
          setAvailableTickets((prev) => prev - count);
          navigate(
            `/event/booking-steps/${EventId}/${AddressId}/${DateId}/${selectedTicketId}`,
            {
              state: response.responseData,
            }
          );
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  // Ticket selection logic
  const handleAddButtonClick = (ticketId, ticketQty, ticketAmount) => {
    setSelectedTicketId(ticketId);
    setTicketQuantity(ticketQty);
    setCount(1);
    setTotalPrice(ticketAmount); // Set total price to ticket amount initially
    setLimitReached(false);
  };

  const increaseQuantity = (ticketAmount) => {
    if (count < Math.min(ticketQuantity, availableTickets)) {
      setCount((prev) => prev - -1); // Increment count
      setTotalPrice((prevPrice) => Number(prevPrice) + Number(ticketAmount)); // Add ticket amount to total price
      setLimitReached(false);
    } else {
      setLimitReached(true);
    }
  };

  const decreaseQuantity = (ticketAmount) => {
    if (count > 1) {
      setCount((prev) => prev + -1); // Decrement count
      setTotalPrice((prevPrice) => Number(prevPrice) - Number(ticketAmount)); // Subtract ticket amount from total price
      setLimitReached(false);
    }
  };

  // Dynamically update form values
  useEffect(() => {
    formik.setFieldValue("TicketId", selectedTicketId);
    formik.setFieldValue("Qty", count);
  }, [selectedTicketId, count]);

  return (
    <div className="container mx-auto py-5">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="px-4 py-4">
        {/* Booking Progress Steps */}
        <div className="relative flex items-center justify-between md:w-1/2 mx-auto">
          <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gradient-to-r from-themecolor1 to-themecolor2"></div>
          <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300 dark:bg-white transition-all duration-500"></div>
          <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
            <img src={lmsicon} alt="" className="w-6 h-6" />
          </div>
          <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
            <FaCalendar className="w-5 h-5" />
          </div>
          <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
            <FaAddressCard className="w-5 h-5" />
          </div>
          <div className="relative grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center">
            <MdOutlinePayments className="w-5 h-5" />
          </div>
        </div>

        {/* Tickets Section */}
        <div className="md:my-14 my-10">
          <p className="text-center font-semibold dark:text-white md:text-xl mb-5 text-base">
            Select Event Ticket
          </p>
          {loading ? (
            <div className="flex justify-center items-center my-48">
              <ClipLoader color={"#DC3558"} loading={loading} size={30} />
            </div>
          ) : EventAddressData.Tickets.length === 0 ? (
            <img src={soldout} alt="Sold Out" className="w-96 mx-auto" />
          ) : (
            EventAddressData.Tickets.map((ticket) => (
              <div
                key={ticket.Id}
                className="mx-auto w-full mb-4 flex flex-col justify-center px-0 py-5 bg-white border shadow-lg relative cursor-pointer lg:w-[30%] transition-all duration-300 ease-in-out dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600 rounded-lg"
              >
                <div className="grid grid-cols-2">
                  <div className="px-5">
                    <h1 className="font-semibold md:text-base text-sm mb-1">
                      {ticket.TicketName}
                    </h1>
                    <p className="text-themecolor1 dark:text-white flex items-center font-bold text-sm">
                      {countrySlug === "India" ? (
                        <FaRupeeSign />
                      ) : (
                        <FaDollarSign />
                      )}{" "}
                      {ticket.TicketAmount}
                    </p>
                  </div>
                  <div className="flex justify-end items-center">
                    {selectedTicketId === ticket.Id ? (
                      <>
                        <button
                          onClick={() => decreaseQuantity(ticket.TicketAmount)} // Pass ticket amount to decrease function
                          className="mx-2 md:h-8 md:w-8 h-7 w-7 md:text-xl text-base border border-themecolor1 rounded-full"
                        >
                          <FaMinus className="ml-1" />
                        </button>

                        <div className="mt-1">{count}</div>

                        <button
                          onClick={() => increaseQuantity(ticket.TicketAmount)} // Pass ticket amount to increase function
                          className={`mx-2 md:h-8 md:w-8 h-7 w-7 md:text-xl text-base border border-themecolor1 rounded-full ${
                            count >= Math.min(ticketQuantity, availableTickets)
                              ? "opacity-50 cursor-not-allowed"
                              : ""
                          }`}
                          disabled={
                            count >= Math.min(ticketQuantity, availableTickets)
                          }
                          id={`button-${ticket.Id}`}
                        >
                          <FaPlus className="ml-1" />
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() =>
                          handleAddButtonClick(
                            ticket.Id,
                            ticket.TicketQuantity,
                            ticket.TicketAmount
                          )
                        }
                        className="bg-gradient-to-r from-themecolor1 to-themecolor2 text-white text-xs px-4 py-1 mx-2 rounded-sm"
                      >
                        Add Ticket
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="flex bg-themecolor1 p-2 mb-3 text-white justify-between md:w-1/2 mx-auto">
            <p className="font-semibold text-lg">Total Price:</p>
            <p className="font-bold text-lg flex items-center">
              {countrySlug === "India" ? <FaRupeeSign /> : <FaDollarSign />}
              {totalPrice}
            </p> 
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between md:w-1/2 mx-auto">
          <div className=" mt-3 md:mt-0">
            <Link
              to={`/event/booking-steps/${EventId}/${AddressId}`}
              className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor2 to-themecolor1 text-white shadow-lg hover:scale-105 transition-all"
            >
              Previous
            </Link>
          </div>
          <div className=" mt-3 md:mt-0">
            {selectedTicketId === null ? (
              <>
                <button
                  data-tooltip-id="nextButtonTooltip"
                  className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all"
                >
                  Next
                </button>
                <Tooltip id="nextButtonTooltip" place="left" effect="solid">
                  Select Ticket
                </Tooltip>
              </>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <button
                  type="submit"
                  className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all"
                >
                  Next
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSteps3;
