import axios from "axios";
import { toast } from "react-toastify";
import Config from "./Config";

const { API_BASE_URL } = Config; // Destructure the values from the config file

const TOKEN = Config.getToken();
const VendorId = Config.getId();

const headers = {
  Authorization: `Bearer ${TOKEN}`, // Corrected typo from "Bareer" to "Bearer"
};

// ------------------------Add Bouncer---------------------
// https://bme.seawindsolution.ae/api/f/coupon
export const AddBouncer = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/coupon`, formData, {
      headers,
    });
    if (response.data.status === true) {
      toast.success(response.data.message); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// =========================Get All Bouncer=========================
// https://bme.seawindsolution.ae/api/f/coupon/1
export const getAllBouncer = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/coupon/${VendorId}`, {
      headers,
    });
    return response.data.responsedata;
  } catch (error) {
    throw error;
  }
};
// =========================getAllBouncerforEvent=========================
// https://bme.seawindsolution.ae/api/f/coupon/1
export const getAllBouncerforEvent = async (VendorId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/coupon/${VendorId}`, {
      headers,
    });
    return response.data.responsedata;
  } catch (error) {
    throw error;
  }
};

// ----------------------getBouncerbyId----------------DB
// https://bme.seawindsolution.ae/api/f/coupon/details/2
export const getBouncerById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/coupon/details/${Id}`, {
      headers,
    });

    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ===================Edit Bouncer================DB
export const updateBouncerById = async (formData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/coupon`, formData, {
      headers,
    });

    if (response.data.status === true) {
      toast.success("Bouncer Update Successfully.."); // Toast success message
      return response.data;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ------------------------delete Bouncer---------------------
// https://bme.seawindsolution.ae/api/f/coupon/1
export const deleteBouncer = async (Id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/coupon/${Id}`, {
      headers,
    });
    if (response.data.status === true) {
      toast.success("Bouncer delete successfully..."); // Toast success message
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
