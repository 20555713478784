import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";

const { API_BASE_URL } = Config; // Destructure the values from the config file

// =========================AdminLogin==============D
export const ContactData = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/contact`, data);

    if (response.data.status === true) {
      toast.success("Contact request Send Successfully.."); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// =========================newslatter==============D
export const NewslatterData = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/newslatter`, data);

    if (response.data.status === true) {
      toast.success("Contact request Send Successfully.."); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
