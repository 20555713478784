import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2"; // Import SweetAlert2
import Config from "../API/Config";
import usePageSEO from "../hoosk/usepageSEO/Index";
import ScrollToTop from "../ScrollToTop";
import { FaList, FaUser } from "react-icons/fa";
import { FaTicket } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";
import { RiCoupon2Fill } from "react-icons/ri";
import { IoDocumentAttach, IoLogOut, IoTicket } from "react-icons/io5";

const DashboardMenu = () => {
  usePageSEO(
    "Love My Show", // title
    "Love My Show", // description
    ["Love My Show"] // keywords
  );
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserData");
        localStorage.removeItem("KYCStatus");
        navigate("/event");
        window.location.reload()
      }
    });
  };

  const [userType, setUserType] = useState("");

  useEffect(() => {
    const type = Config.getType();
    setUserType(type);
  }, []);

  const menuItems = [
    { path: "/profile", label: "Profile", Icon: <FaUser /> },
    { path: "/order-history", label: "Order History", Icon: <FaTicket /> },

    { path: "/dashboard", label: "Dashboard", Icon: <MdDashboard /> },
    { path: "/profile", label: "Profile", Icon: <FaUser /> },
    { path: "/kyc", label: "KYC", Icon: <IoDocumentAttach /> },
    { path: "/list-show", label: "List Show", Icon: <FaUser /> },
    { path: "/your-listing", label: "Your Listing", Icon: <FaList /> },
    { path: "/coupon", label: "Coupon", Icon: <RiCoupon2Fill /> },
    { path: "/sponsor", label: "Sponsor", Icon: <FaUser /> },
    // { path: "/bouncer", label: "Bouncer", Icon: <FaUser /> },
    { path: "/event/all-bookings", label: "All Booking", Icon: <IoTicket /> },
  ];

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <ScrollToTop />
      <ul
        className="flex mb-0 list-none  flex-col lg:col-span-1 col-span-2 px-2 py-3"
        role="tablist"
      >
        {menuItems.map(
          (item, index) =>
            ((userType === "U" && index < 2) ||
              (userType === "V" && index >= 2)) && (
              <li
                key={index}
                className={`last:mr-0 text-center border dark:border-zinc-600 ${isActive(item.path) ? "bg-themecolor1 text-white" : ""
                  }`}
                onClick={() => navigate(item.path)}
              >
                <Link
                  to={item.path}
                  className={`text-xs font-bold uppercase px-0 py-4 border-none block leading-normal hover:bg-themecolor1 hover:text-white transition-all duration-200 ${isActive(item.path) ? "bg-themecolor1 text-white" : ""
                    }`}
                >
                  <span className="flex justify-start px-3 items-center">
                    <span className="mr-2 text-lg">{item.Icon}</span>{" "}
                    {item.label}
                  </span>
                </Link>
              </li>
            )
        )}
        <li className="last:mr-0  text-start px-3 border dark:border-zinc-600 cursor-pointer  hover:bg-themecolor1 hover:text-white transition-all duration-200">
          <div
            onClick={logout}
            className={
              "text-xs font-bold uppercase px-0 py-4 border-none  block leading-normal "
            }
          >
            <span className="flex justify-start items-center">
              <span className="mr-2 text-lg ">
                <IoLogOut />
              </span>{" "}
              Logout
            </span>
          </div>
        </li>
      </ul>
    </>
  );
};

export default DashboardMenu;
