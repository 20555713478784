import React, { useState, useEffect } from "react";
import RouterMain from "../../Router/Router";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { BrowserRouter } from "react-router-dom";
import { FaMoon, FaSun } from "react-icons/fa";

const Layout = () => {
  // Retrieve the dark mode preference from localStorage or default to false
  const localStorageDarkMode = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    localStorageDarkMode ? JSON.parse(localStorageDarkMode) : false
  );

  // Function to toggle dark mode
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    // Store the preference in localStorage
    localStorage.setItem("darkMode", JSON.stringify(newDarkMode));
  };

  // Apply dark mode class to body when dark mode is enabled
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <div className={darkMode ? "dark" : "light"}>
      <BrowserRouter>
        <Navbar />
        <div className="pt-14 fold:pt-0 md:pt-0 dark:bg-boxdark-2">
          <RouterMain />
        </div>
        <Footer />

        <div
          className="fixed lg:bottom-4 bottom-20 left-4 cursor-pointer z-50"
          onClick={toggleDarkMode}
        >
          {darkMode ? (
            <FaSun className="bg-themecolor1 text-white text-4xl p-2" />
          ) : (
            <FaMoon className="bg-themecolor1 text-white text-4xl p-2" />
          )}
        </div>
      </BrowserRouter>
    </div>
  );
};

export default Layout;
