/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { FaEdit, FaImage } from "react-icons/fa";
import * as Yup from "yup";
import { FaTrash } from "react-icons/fa6";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllArtist } from "../API/ArtistApi";
import { getAllCategory } from "../API/CategoryApi";
import Config from "../API/Config";
import {
  deleteEventTicketDate,
  getEventById,
  updateEventById,
} from "../API/EventApi";
import FormLoader from "../common/FormLoader";
import Swal from "sweetalert2";
import ScrollToTop from "../ScrollToTop";
import usePageSEO from "../hoosk/usepageSEO/Index";
import TinySetUp from "./TinySetUp";
const validationSchema = Yup.object().shape({
  EventName: Yup.string().required("Event name is required"),
  EventEmail: Yup.string().required("Email is required"),
  EventSlug: Yup.string().required("Event Slug is required"),
  EventOrganizer: Yup.string().required("EventOrganizer name is required"),
  EventDescription: Yup.string().required("Description is required"),
  EventStartDateTime: Yup.string().required(
    "Event Booking Start date/time is required"
  ),
  EventCategoryId: Yup.string().required("Main Category is required"),
  EventArtistId: Yup.string().required("Artist is required"),
  AgeRestriction: Yup.string().required("Age Restriction is required"),
  HealthySafetyPrecautions: Yup.string().required(
    "Healthy Safety Precautions is required"
  ),
  PointOfContactName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Name must only contain letters")
    .required("Contact person name is required"),
  PointOfContactPhone: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must only contain digits")
    .required("Contact person number is required"),
  PointOfContactEmail: Yup.string().required(
    "Contact person email is required"
  ),
});

const ListShowEdit = () => {
  usePageSEO(
    "Love My Show | Edit Listing ", // title
    "Love My Show | Edit Listing ", // description
    ["Love My Show | Edit Listing  "] // keywords
  );
  const navigate = useNavigate();
  const VendorId = Config.getId();
  const { Id } = useParams(); // Assuming you're using useParams to get the event Id
  const EventId = Id;
  const InitialFormData = {
    UserId: VendorId,
    Id: Id,
    EventName: "",
    EventSlug: "",
    EventEmail: "",
    EventOrganizer: "",
    EventDescription: "",
    AgeRestriction: "",
    Banner: "",
    Hid_Thumb: "",
    Hid_Banner: "",
    Thumb: "",
    EventStartDateTime: "",
    EventCategoryId: "",
    EventArtistId: "",
    HealthySafetyPrecautions: "",
    PointOfContactName: "",
    PointOfContactPhone: "",
    PointOfContactEmail: "",
    Country: "",
    CountryId: "",
    Addresses: [
      {
        CityId: "",
        CountryId: "",
        LanguageId: "",
        Duration: "",
        Type: "",
        Location: "",
        Address: "",
        EventTicket: [
          {
            EventDate: "",
            EventStartTime: "",
            EventEndTime: "",
            Ticketdata: [{ tname: "", tamt: "", tqty: "" }],
          },
        ],
      },
    ],
  };

  // State variables
  const [ArtistData, setArtistData] = useState(null);
  const [CategoryData, setCategoryData] = useState(null);
  const [ThumbImagePreview, setThumbImagePreview] = useState();
  const [BannerPreview, setBannerPreview] = useState();
  const [EventData, setEventData] = useState([]);
  const [formData, setFormData] = useState(InitialFormData);
  const [errors, setErrors] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);
  // Fetch data functions
  const fetchArtistData = async () => {
    try {
      const result = await getAllArtist();
      setArtistData(result);
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }
  };

  const fetchCategoryData = async () => {
    try {
      const result = await getAllCategory();
      setCategoryData(result);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  const fetchEventData = async () => {
    try {
      const result = await getEventById(Id); // Assuming getEventById fetches event data by Id
      setEventData(result);
      setFormData({ ...formData, Id: result.Id });
      // Transform addresses
      const addressData = result.Addresses.map((address) => {
        return {
          Id: address.Id,
          CountryTitle: address.CountryTitle,
          CityTitle: address.CityTitle,
          LanguageTitle: address.LanguageTitle,
          CityId: address.CityId,
          CountryId: address.CountryId,
          LanguageId: address.LanguageId,
          Duration: address.Duration,
          Type: address.Type,
          Location: address.Location,
          Address: address.Address,
          Dates: address.Dates.map((date) => ({
            Id: date.Id,
            EventDate: date.EventDate,
            EventStartTime: date.EventStartTime,
            EventEndTime: date.EventEndTime,
            Tickets: date.Tickets.map((ticket) => ({
              Id: ticket.Id,
              TicketName: ticket.TicketName,
              TicketAmount: ticket.TicketAmount,
              TicketQuantity: ticket.TicketQuantity,
            })),
          })),
        };
      });

      // Set preview images
      if (result.Thumb) {
        setThumbImagePreview(result.Thumb);
      }
      if (result.Banner) {
        setBannerPreview(result.Banner);
      }
      const convertDateToISO = (dateStr) => {
        const [day, month, yearAndTime] = dateStr.split("/");
        const [year, time] = yearAndTime.split(" ");
        const formattedYear = `20${year}`; // Convert '24' to '2024'
        const isoString = `${formattedYear}-${month}-${day}T${time}`;
        return isoString;
      };
      // Update formData state
      setFormData({
        UserId: result.UserId,
        Id: result.Id,
        EventName: result.EventName,
        EventSlug: result.EventSlug,
        EventEmail: result.EventEmail,
        EventOrganizer: result.EventOrganizer,
        EventDescription: result.EventDescription,
        AgeRestriction: result.AgeRestriction,
        EventStartDateTime: convertDateToISO(result.EventStartDateTime),
        EventCategoryId: result.EventCategoryId,
        Banner: result.Banner,
        Thumb: result.Thumb,
        Hid_Banner: result.Hid_Banner,
        Hid_Thumb: result.Hid_Thumb,
        EventArtistId: result.EventArtistId,
        HealthySafetyPrecautions: result.HealthySafetyPrecautions,
        PointOfContactName: result.PointOfContactName,
        PointOfContactPhone: result.PointOfContactPhone,
        PointOfContactEmail: result.PointOfContactEmail,
        Country: result.Country,
        CountryId: result.CountryId,
        Addresses: addressData,
        Status: result.Status,
        Reason: result.Reason,
      });
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };
  // Effect hook to fetch initial data
  useEffect(() => {
    fetchArtistData();
    fetchCategoryData();
    fetchEventData();
  }, [Id]);
  // Reference object for form fields
  const fieldRefs = {
    EventName: useRef(null),
    EventSlug: useRef(null),
    EventEmail: useRef(null),
    EventOrganizer: useRef(null),
    EventDescription: useRef(null),
    Banner: useRef(null),
    Thumb: useRef(null),
    EventStartDateTime: useRef(null),
    EventCategoryId: useRef(null),
    EventArtistId: useRef(null),
    PointOfContactName: useRef(null),
    PointOfContactPhone: useRef(null),
    PointOfContactEmail: useRef(null),
    AgeRestriction: useRef(null),
    HealthySafetyPrecautions: useRef(null),
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;

    if (name === "EventSlug") {
      let newSlug = value
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/\//g, "-")
        .replace(/%/g, "-")
        .replace(/&/g, "-")
        .replace(/\?/g, "-");

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        EventSlug: newSlug,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const getMinDateTime = () => {
    const now = new Date();
    const minDateTime = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000 + 24 * 60 * 60 * 1000
    );
    const minDateTimeString = minDateTime.toISOString().slice(0, 16);
    return minDateTimeString;
  };

  // Handle file input changes
  const handleFileInputChange = (e) => {
    const fieldName = e.target.name;
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: file,
    }));
  };

  // Handle deletion of ticket Date
  const handleDeleteTicketDate = async (Id) => {
    setIsFormLoading(true);
    try {
      await deleteEventTicketDate(Id);
      fetchEventData();
    } catch (error) {
      console.error("Error deleting event ticket:", error);
    } finally {
      setIsFormLoading(false);
    }
  };
  // handleEditEventAddress
  const handleEditEventAddress = async (Id) => {
    setIsFormLoading(true);
    try {
      navigate(`/list-show/add-address-edit/${EventId}/${Id}`);
    } catch (error) {
      console.error("Error deleting event ticket:", error);
    } finally {
      setIsFormLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    try {
      // Validate form data using validationSchema if available
      await validationSchema.validate(formData, { abortEarly: false });

      // Create a new FormData object to send data to the server
      const formDataToSend = new FormData();

      // Iterate over formData keys and append them to formDataToSend accordingly
      Object.keys(formData).forEach((key) => {
        formDataToSend.append(key, formData[key]);
      });

      // Call API to update event data by Id with formDataToSend
      await updateEventById(formDataToSend);
      fetchEventData();
      // Optionally, handle success or redirect after successful update
      console.log("Event updated successfully!");
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });

        setErrors(validationErrors);
        for (const fieldName in validationErrors) {
          const fieldRef = fieldRefs[fieldName];
          if (fieldRef && fieldRef.current) {
            fieldRef.current.scrollIntoView({ behavior: "smooth" });
            break;
          }
        }
      } else {
        console.error("Error:", error);
      }
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };

  const addressDataLength = formData.Addresses.length;
  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-5 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  {formData.Status === 0 ? (
                    <h1 className="bg-blue-100 text-blue-800  px-5 py-2 rounded-lg font-medium lg:mx-0 mb-4">
                      Your Event is Under Review
                    </h1>
                  ) : formData.Status === 1 ? (
                    <h1 className="bg-green-100 text-green-800 px-5 py-2 rounded-lg font-medium lg:mx-0 mb-4">
                      Your Event is Approved
                    </h1>
                  ) : formData.Status === 2 ? (
                    <h1 className="bg-gray-100 text-gray-800 px-5 py-2 rounded-lg  font-medium lg:mx-0 mb-4">
                      Your Event is InActive
                    </h1>
                  ) : formData.Status === 3 ? (
                    <h1 className="bg-purple-100 text-purple-800 px-5 py-2 rounded-lg font-medium lg:mx-0 mb-4">
                      Your Event is Expired/Complete
                    </h1>
                  ) : formData.Status === 4 ? (
                    <h1 className="bg-red-100 text-red-800 px-5 py-2 rounded-lg  font-medium lg:mx-0 mb-4">
                      Your Event is Reject Reason Is : {formData.Reason}
                    </h1>
                  ) : formData.Status === 5 ? (
                    <h1 className="bg-red-100 text-red-800 px-5 py-2 rounded-lg  font-medium lg:mx-0 mb-4">
                      Your Event is Cancel
                    </h1>
                  ) : (
                    <h1 className="bg-blue-100 text-blue-800  px-5 py-2 rounded-lg font-medium lg:mx-0 mb-4">
                      Your Event is Under Review
                    </h1>
                  )}

                  <div className="border-b flex items-center bg-themecolor1 py-1 mb-3">
                    <Link to={"/your-listing"}>
                      <button
                        type="submit"
                        className="flex bg-green-500 text-white dark:bg-boxdark-2 dark:border-white dark:border px-6 py-1 my-1 ml-2 rounded-full"
                      >
                        Back
                      </button>
                    </Link>
                    <h1 className="text-sm ml-auto font-medium pt-0 text-white pr-3">
                      {EventData.EventName}
                    </h1>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <input
                      type="hidden"
                      name="Hid_Thumb"
                      value={formData.Hid_Thumb}
                    />
                    <input
                      type="hidden"
                      name="Hid_Banner"
                      value={formData.Hid_Banner}
                    />
                    <div className="container mx-auto ">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="">
                          <h1 className="mb-2 text-sm">
                            Event Name <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="text"
                            name="EventName"
                            value={formData.EventName}
                            onChange={handleInputChange2}
                            className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white ${
                              errors.EventName ? "border-red-600" : ""
                            }`}
                            placeholder="Event Name"
                            ref={fieldRefs.EventName}
                          />
                          {errors.EventName && (
                            <div className="text-red-600 text-sm mt-1">
                              {errors.EventName}
                            </div>
                          )}
                        </div>
                        <div className="">
                          <h1 className="mb-2 text-sm">
                            Event Slug <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="text"
                            name="EventSlug"
                            disabled
                            value={formData.EventSlug}
                            onChange={handleInputChange2}
                            className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white ${
                              errors.EventSlug ? "border-red-600" : ""
                            }`}
                            placeholder="Event Slug"
                            ref={fieldRefs.EventSlug}
                          />
                          {errors.EventSlug && (
                            <div className="text-red-600 text-sm mt-1">
                              {errors.EventSlug}
                            </div>
                          )}
                        </div>
                        <div className="">
                          <h1 className="mb-2 text-sm">
                            Email <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="email"
                            name="EventEmail"
                            value={formData.EventEmail}
                            onChange={handleInputChange2}
                            className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white ${
                              errors.EventEmail ? "border-red-600" : ""
                            }`}
                            placeholder="Email"
                            ref={fieldRefs.EventEmail}
                          />
                          {errors.EventEmail && (
                            <div className="text-red-600 text-sm mt-1">
                              {errors.EventEmail}
                            </div>
                          )}
                        </div>
                        <div className="">
                          <h1 className="mb-2 text-sm">
                            Your event's EventOrganizer{" "}
                            <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="text"
                            name="EventOrganizer"
                            value={formData.EventOrganizer}
                            onChange={handleInputChange2}
                            className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white ${
                              errors.EventOrganizer ? "border-red-600" : ""
                            }`}
                            placeholder="Your event's EventOrganizer"
                            ref={fieldRefs.EventOrganizer}
                          />
                          {errors.EventOrganizer && (
                            <div className="text-red-600 text-sm mt-1">
                              {errors.EventOrganizer}
                            </div>
                          )}
                        </div>
                      </div>

                      <h1 className="mb-2 text-sm mt-3">Event Description</h1>

                      <TinySetUp
                        value={formData.EventDescription}
                        onChange={(value) =>
                          setFormData((prev) => ({
                            ...prev,
                            EventDescription: value,
                          }))
                        }
                        onBlur={() =>
                          handleInputChange2({
                            target: {
                              name: "EventDescription",
                              value: formData.EventDescription,
                            },
                          })
                        }
                        ref={fieldRefs.EventDescription}
                      />
                      {errors.EventDescription && (
                        <div className="text-red-600 text-sm mt-1">
                          {errors.EventDescription}
                        </div>
                      )}

                      {/* =========Add Images and Add Videos =================*/}
                      <div className="mt-4">
                        <h1 className="text-lg px-3 py-2  text-white font-normal bg-black mb-2">
                          Image Section
                        </h1>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="py-3">
                            <h1 className="mb-2 text-sm">Add Banner Image</h1>
                            <p className="text-xs text-gray-500 dark:text-white pb-4">
                              (.jpg, .jpeg, .png, .webp)
                            </p>
                            <div className="border-2 border-dotted border-gray-600 rounded-md text-center pt-7 py-4 cursor-pointer">
                              <h1 className="text-3xl">
                                <FaImage className="block m-auto" />
                              </h1>
                              <h1 className="px-4 lg:px-0 text-gray-600 dark:text-white text-xs pt-3">
                                Select File here (below 1 MB)(1200 × 450 px)
                              </h1>
                              <input
                                type="file"
                                name="Banner"
                                onChange={handleFileInputChange}
                                className={`block w-56 lg:w-80 foldsmall:w-40 text-sm text-slate-500  dark:bg-boxdark-2 dark:text-white m-auto my-5 border  border-[#F84464] bg-[#f7f7f7] rounded-lg
         file:mr-4 file:py-3 file:px-4
         file:border-0 file:text-sm file:font-semibold
         file:bg-[#EC5E71] file:text-white
         cursor-pointer ${errors.Banner ? "border-red-600" : ""}`}
                                ref={fieldRefs.Banner}
                              />
                              {errors.Banner && (
                                <div className="text-red-600 text-sm mt-1">
                                  {errors.Banner}
                                </div>
                              )}

                              <div>
                                <p className="pb-4 text-gray-600 dark:text-white text-xs">
                                  Your Existing Thumb Image
                                </p>
                                <div className=" mx-2 shadow-md border rounded-md solid p-2">
                                  <img
                                    src={BannerPreview}
                                    className="lg:h-80 surface:h-72 rounded-md h-56 md:h-60 w-full mx-auto"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="py-3">
                            <h1 className="mb-2 text-sm">Add Thumb Image</h1>
                            <p className="text-xs text-gray-500 dark:text-white pb-4">
                              (.jpg, .jpeg, .png, .webp)
                            </p>
                            <div className="border-2 border-dotted border-gray-600 rounded-md text-center pt-7 py-4 cursor-pointer">
                              <h1 className="text-3xl">
                                <FaImage className="block m-auto" />
                              </h1>
                              <h1 className="px-4 lg:px-0 text-gray-600 dark:text-white text-xs pt-3">
                                Select File here (below 1 MB)(270 × 325 px)
                              </h1>
                              <input
                                type="file"
                                name="Thumb"
                                onChange={handleFileInputChange}
                                className={`block w-56 lg:w-80 foldsmall:w-40 text-sm text-slate-500  dark:bg-boxdark-2 dark:text-white m-auto my-5 border  border-[#F84464] bg-[#f7f7f7] rounded-lg
         file:mr-4 file:py-3 file:px-4
         file:border-0 file:text-sm file:font-semibold
         file:bg-[#EC5E71] file:text-white
         cursor-pointer ${errors.Thumb ? "border-red-600" : ""}`}
                                ref={fieldRefs.Thumb}
                              />
                              {errors.Thumb && (
                                <div className="text-red-600 text-sm mt-1">
                                  {errors.Thumb}
                                </div>
                              )}
                              <div>
                                <p className="pb-4 text-gray-600 dark:text-white text-xs">
                                  Your Existing Thumb Image
                                </p>
                                <div className="md:w-64 mx-auto shadow-md border rounded-md solid p-2">
                                  <img
                                    src={ThumbImagePreview}
                                    className="lg:h-80 surface:h-72 rounded-md h-56 md:h-60 w-full mx-auto"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-4  gap-4 mt-3">
                        <div className="">
                          <h1 className="mb-2 text-sm">
                            Event Booking Start Time
                          </h1>
                          <input
                            type="datetime-local"
                            name="EventStartDateTime"
                            value={formData.EventStartDateTime}
                            onChange={handleInputChange2}
                            className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white   ${
                              errors.EventStartDateTime ? "border-red-600" : ""
                            }`}
                            ref={fieldRefs.EventStartDateTime}
                            min={getMinDateTime()}
                          />
                          {errors.EventStartDateTime && (
                            <div className="text-red-600 text-sm mt-1">
                              {errors.EventStartDateTime}
                            </div>
                          )}
                        </div>

                        <div className="">
                          <h1 className="mb-2 text-sm">Select Category</h1>
                          <select
                            name="EventCategoryId"
                            value={formData.EventCategoryId}
                            onChange={handleInputChange2}
                            className={`border w-full dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-fulldark:bg-boxdark-2 dark:text-white form-select ${
                              errors.EventCategoryId ? "border-red-600" : ""
                            }`}
                            ref={fieldRefs.EventCategoryId}
                          >
                            <option value="">Select Category</option>
                            {CategoryData?.map((val, index) => {
                              return (
                                <option value={val.Id} key={index}>
                                  {val.Title}
                                </option>
                              );
                            })}
                          </select>
                          {errors.EventCategoryId && (
                            <div className="text-red-600 text-sm mt-1">
                              {errors.EventCategoryId}
                            </div>
                          )}
                        </div>
                        <div className="">
                          <h1 className="mb-2 text-sm">Select Artist</h1>
                          <select
                            name="EventArtistId"
                            value={formData.EventArtistId}
                            onChange={handleInputChange2}
                            className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white form-select ${
                              errors.EventArtistId ? "border-red-600" : ""
                            }`}
                            ref={fieldRefs.EventArtistId}
                          >
                            <option>Select Artist</option>
                            {ArtistData?.map((val, index) => {
                              return (
                                <option value={val.Id} key={index}>
                                  {val.Title}
                                </option>
                              );
                            })}
                          </select>
                          {errors.EventArtistId && (
                            <div className="text-red-600 text-sm mt-1">
                              {errors.EventArtistId}
                            </div>
                          )}
                        </div>
                        <div className="">
                          <h1 className="mb-2 text-sm">Age Restriction</h1>
                          <input
                            type="text"
                            placeholder="Age Restriction"
                            value={formData.AgeRestriction}
                            name="AgeRestriction"
                            onChange={handleInputChange2}
                            className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white ${
                              errors.AgeRestriction ? "border-red-600" : ""
                            }`}
                            ref={fieldRefs.AgeRestriction}
                          />
                          {errors.AgeRestriction && (
                            <div className="text-red-600 text-sm mt-1">
                              {errors.AgeRestriction}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="my-5">
                        <h1 className="text-lg px-3 py-2  text-white font-normal bg-black mb-4">
                          Point Of Contact
                        </h1>
                        <p className="text-sm mb-4">
                          Details of the person managing the event from your
                          end, it could be your or someone on your team. In case
                          we need to get in touch, we know how to reach you.
                        </p>
                        {/*=================== Details =========*/}
                        <div className="grid grid-cols-1 md:grid-cols-3  gap-4 my-2">
                          <div className="">
                            <input
                              type="text"
                              name="PointOfContactName"
                              value={formData.PointOfContactName}
                              onChange={handleInputChange2}
                              placeholder="Name"
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white   ${
                                errors.PointOfContactName
                                  ? "border-red-600"
                                  : ""
                              }`}
                              ref={fieldRefs.PointOfContactName}
                            />
                            {errors.PointOfContactName && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.PointOfContactName}
                              </div>
                            )}
                          </div>

                          <div className="">
                            <input
                              type="email"
                              name="PointOfContactEmail"
                              value={formData.PointOfContactEmail}
                              onChange={handleInputChange2}
                              placeholder="Email"
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white   ${
                                errors.PointOfContactEmail
                                  ? "border-red-600"
                                  : ""
                              }`}
                              ref={fieldRefs.PointOfContactEmail}
                            />
                            {errors.PointOfContactEmail && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.PointOfContactEmail}
                              </div>
                            )}
                          </div>
                          <div className="">
                            <input
                              type="text"
                              name="PointOfContactPhone"
                              value={formData.PointOfContactPhone}
                              onChange={handleInputChange2}
                              placeholder="Phone"
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white   ${
                                errors.PointOfContactPhone
                                  ? "border-red-600"
                                  : ""
                              }`}
                              ref={fieldRefs.PointOfContactPhone}
                            />
                            {errors.PointOfContactPhone && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.PointOfContactPhone}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* =====Healthy and Safety Precautions ===========*/}

                      <div className="">
                        <h1 className="mb-2 text-sm">
                          HealthySafetyPrecautions
                        </h1>
                        <select
                          name="HealthySafetyPrecautions"
                          value={formData.HealthySafetyPrecautions}
                          onChange={handleInputChange2}
                          className={`form-select border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white   ${
                            errors.HealthySafetyPrecautions
                              ? "border-red-600"
                              : ""
                          }`}
                          ref={fieldRefs.HealthySafetyPrecautions}
                        >
                          <option value="">
                            Select Healthy Safety Precautions
                          </option>
                          <option value="HealthySafetyPrecautions">
                            Healthy Safety Precautions
                          </option>
                        </select>
                        {errors.HealthySafetyPrecautions && (
                          <div className="text-red-600 text-sm mt-1">
                            {errors.HealthySafetyPrecautions}
                          </div>
                        )}
                      </div>
                    </div>

                    {addressDataLength === 0 ? (
                      ""
                    ) : (
                      <div className="container mx-auto mt-3">
                        <h1 className="text-lg px-3 py-2  text-white font-normal bg-black">
                          Address
                        </h1>
                        {formData.Addresses?.map((address, addressIndex) => (
                          <div key={addressIndex} className="">
                            <div className="grid  gap-3">
                              <div className="border p-4">
                                <div className="flex justify-between items-center mb-4">
                                  <p className="m-0 border border-themecolor1 py-1.5 rounded-3xl">
                                    <span className="bg-themecolor1  text-white px-3.5 py-2 rounded-full mr-2">
                                      {addressIndex + 1}
                                    </span>
                                    <span className="pr-3 text-sm">
                                      Event Address {addressIndex + 1}
                                    </span>
                                  </p>
                                  <div className="flex items-center">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleEditEventAddress(address.Id)
                                      }
                                      className="bg-green-500 py-2 px-5 flex items-center text-white"
                                    >
                                      <FaEdit />
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        Swal.fire({
                                          title: "Are you sure?",
                                          text: `Are you sure you want to delete ${address.CityTitle}?`,
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "Yes, delete it!",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            handleDeleteTicketDate(address.Id);
                                          }
                                        });
                                      }}
                                      className="bg-red-500 py-2 px-5 flex items-center text-white"
                                    >
                                      <FaTrash />
                                    </button>
                                  </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-3 mb-4">
                                  <p className=" p-3 border text-sm">
                                    <b>Country:</b> {address.CountryTitle}
                                  </p>
                                  <p className=" p-3 border text-sm">
                                    <b>City:</b> {address.CityTitle}
                                  </p>
                                  <p className=" p-3 border text-sm">
                                    <b>Language:</b> {address.LanguageTitle}
                                  </p>
                                  <p className=" p-3 border text-sm">
                                    <b>Duration:</b> {address.Duration}
                                  </p>
                                  <p className=" p-3 border text-sm">
                                    <b>Type:</b> {address.Type}
                                  </p>
                                  <p className=" p-3 border text-sm">
                                    <b>Location:</b> {address.Location}
                                  </p>
                                </div>

                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: address.Address
                                      ? address.Address
                                      : "",
                                  }}
                                  className="text-base map-container1 border mb-4"
                                />

                                <div className="grid md:grid-cols-2 grid-cols-1  gap-4">
                                  {address.Dates?.map((ticket, subIndex) => (
                                    <div className="mx-auto grid w-full border dark:border-zinc-600 p-2 my-2 relative h-max">
                                      <div className="flex items-center border-b pb-3 border-themecolor1 mb-3">
                                        <p className="m-0 border border-themecolor2 py-1.5 rounded-3xl">
                                          <span className="bg-themecolor2 text-white px-3.5 py-2 rounded-full mr-2">
                                            {subIndex + 1}
                                          </span>
                                          <span className="pr-3 text-sm">
                                            Event Date
                                          </span>
                                        </p>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            Swal.fire({
                                              title: "Are you sure?",
                                              text: `Are you sure you want to delete ${ticket.EventDate}?`,
                                              icon: "warning",
                                              showCancelButton: true,
                                              confirmButtonColor: "#3085d6",
                                              cancelButtonColor: "#d33",
                                              confirmButtonText:
                                                "Yes, delete it!",
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                handleDeleteTicketDate(
                                                  ticket.Id
                                                );
                                              }
                                            });
                                          }}
                                          className="bg-red-500 text-white px-4 py-2 rounded-md mr-2 ml-auto"
                                        >
                                          <FaTrash />
                                        </button>
                                      </div>
                                      <h1 className="py-1 text-sm">
                                        <b>Event Date: </b>
                                        {ticket.EventDate}
                                      </h1>
                                      <h1 className="py-1 text-sm">
                                        <b>Event Start Time: </b>
                                        {ticket.EventStartTime}
                                      </h1>
                                      <h1 className="py-1 text-sm">
                                        <b>Event End Time: </b>
                                        {ticket.EventEndTime}
                                      </h1>

                                      {ticket.Tickets?.map(
                                        (ticket, subIndex) => (
                                          <div className="border dark:border-zinc-600 px-3 py-4 my-3 relative mt-5">
                                            <h1 className="py-1 text-sm">
                                              <b>Ticket Name: </b>{" "}
                                              {ticket.TicketName}
                                            </h1>

                                            <h1 className="py-1 text-sm">
                                              <b>Ticket Price: </b>{" "}
                                              {ticket.TicketAmount}
                                            </h1>

                                            <h1 className="py-1 text-sm">
                                              <b>Ticket Quantity:</b>
                                              {ticket.TicketQuantity}
                                            </h1>

                                            <h1 className="absolute bg-themecolor2 text-white px-2 -top-3">
                                              {subIndex + 1}
                                            </h1>

                                            <button
                                              type="button"
                                              onClick={() => {
                                                Swal.fire({
                                                  title: "Are you sure?",
                                                  text: `Are you sure you want to delete ${ticket.TicketName}?`,
                                                  icon: "warning",
                                                  showCancelButton: true,
                                                  confirmButtonColor: "#3085d6",
                                                  cancelButtonColor: "#d33",
                                                  confirmButtonText:
                                                    "Yes, delete it!",
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    handleDeleteTicketDate(
                                                      ticket.Id
                                                    );
                                                  }
                                                });
                                              }}
                                              className="absolute bg-red-500 text-white p-2 top-0 right-0"
                                            >
                                              <FaTrash />
                                            </button>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    <div className=" ">
                      <button
                        type="submit"
                        className="bg-[#EC5E71] block my-5 mb-0 foldsmall:my-2 text-white font-semibold px-12 p-2 rounded-md mx-auto foldsmall:mx-auto"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListShowEdit;
