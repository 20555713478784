import { useEffect } from "react";

const LOCAL_STORAGE_KEY = "firstVisitTimestamp";
const EXPIRATION_TIME_MS = 24 * 60 * 60 * 1000; // 1 hour in milliseconds

const ClearLocalStorageAfter24Hours = () => {
  useEffect(() => {
    const currentTimestamp = new Date().getTime();
    const storedTimestamp = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (storedTimestamp) {
      const storedTime = parseInt(storedTimestamp, 10); // Parse with base 10
      if (currentTimestamp - storedTime > EXPIRATION_TIME_MS) {
        // 1 hour has passed, clear specific localStorage items
        localStorage.removeItem("CountryId");
        localStorage.removeItem("CountryFlag");
        localStorage.removeItem("CountryTitle");
        localStorage.removeItem("CountrySlug");
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserData");
        localStorage.removeItem("KYCStatus");
        localStorage.removeItem("selectedCityId");
        localStorage.removeItem("selectedCitySlug");
        localStorage.removeItem("selectedCityTitle");

        // Update the timestamp to the current time
        localStorage.setItem(LOCAL_STORAGE_KEY, currentTimestamp.toString());

        // Reload the page to apply changes
        window.location.reload();
      }
    } else {
      // If no timestamp is found, set the current timestamp
      localStorage.setItem(LOCAL_STORAGE_KEY, currentTimestamp.toString());
    }

    // Cleanup function if necessary
    return () => {};
  }, []); // The empty dependency array ensures this effect runs only once

  return null;
};

export default ClearLocalStorageAfter24Hours;
