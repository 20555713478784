/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"; 
import { Link,  useParams } from "react-router-dom"; 
import usePageSEO from "../hoosk/usepageSEO/Index"; 
import ScrollToTop from "../ScrollToTop";
import Config from "../API/Config";
import { FaCheckCircle, FaDollarSign, FaRupeeSign } from "react-icons/fa";
import {
  GetAllBookedOrderById,
  GetTicketDownloadById, 
} from "../API/EventApi";
import { sendOTP, verifyOTP } from "../API/LoginApi";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import FormLoader from "../common/FormLoader";
import ClipLoader from "react-spinners/ClipLoader";
import { BsFillPrinterFill } from "react-icons/bs";

const phoneLengthByCountry = {
  India: 10, // India
  Australia: 10, // Australia
  UK: 10, // UK
  USA: 10, // USA
  Canada: 10, // Canada
  // Add other countries and their rules here
};
const getPhoneValidationSchema = (countryCode) => {
  const phoneLength = phoneLengthByCountry[countryCode] || 10;
  return yup
    .string()
    .required(`Phone number is required for ${countryCode}`)
    .test(
      "is-valid-length",
      `Enter a valid phone number of length ${phoneLength}`,
      (value) => value && value.replace(/\D/g, "").length === phoneLength
    );
};
const validationSchema2 = yup.object().shape({
  OTP: yup
    .string()
    .matches(/^[0-9]+$/, "OTP must contain only numbers")
    .length(4, "OTP must be exactly 4 digits")
    .required("OTP is required"),
});

const OrderSuccess = () => {
  usePageSEO(
    "Love My Show  | Order Success", // title
    "Love My Show  | Order Success", // description
    ["Love My Show  | Order Success "] // keywords
  );

  const UserId = Config.getId();
  const getCountrySlug = Config.getCountrySlug();
  const validationSchema = yup.object().shape({
    EOP: yup.string().when("Country", {
      is: (value) => !!value, // Ensure Country is selected
      then: (value) => getPhoneValidationSchema(getCountrySlug),
      otherwise: yup.string().required("Phone number is required"),
    }),
  });
  // --------------------Login-----------------
  const [loginpopup, setloginpopup] = useState(false);

  const [isMobileScreen, setIsMobileScreen] = useState(true);
  // -----------------------otpsend-------------------------

  const [showResend, setShowResend] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds = 1 minute
  // -----------------------otpsend-------------------------
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleLoginClick = () => {
    toast.info("Please login first", {
      autoClose: 2000,
      onClose: () => {
        setloginpopup(true);
      },
    });
  };

  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      EOP: "", // Add EOP field
      Country: getCountrySlug,
      Type: "U",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await sendOTP(values);
        if (response.status === true) {
          setIsMobileScreen(false);
        }
        setIsMobileScreen(false);
        formik2.setFieldValue("EOP", values.EOP);
        setResendOTP((prevState) => ({
          ...prevState,
          EOP: values.EOP, // Update EOP in ResendOTP state
        }));
        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  const formik2 = useFormik({
    initialValues: {
      EOP: "",
      Country: getCountrySlug,
      Type: "U",
      OTP: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await verifyOTP(values);
        if (response.status === true) {
          window.location.reload();
          setloginpopup(false);
        }

        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const [ResendOTP, setResendOTP] = useState({
    EOP: "",
    Country: getCountrySlug,
    Type: "U",
  });
  const handleResendOTP = async () => {
    setIsFormLoading(true);
    try {
      setTimer(60);
      setShowResend(false);
      await sendOTP(ResendOTP); // Replace with actual function call
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };


  const { Id } = useParams();
  const [loading, setLoading] = useState(true); // Loading state
  const [EventData, setEventData] = useState([]);
  const fetchData = async () => {
    try {
      if (Id) {
        const UserData = await GetAllBookedOrderById(Id);
        await GetTicketDownloadById(Id, "00");

        setEventData(UserData);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchData();
  }, [Id]);
  const startDate = new Date(EventData?.EventDate);
  const formattedStartDate = `${startDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${(startDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${startDate.getFullYear()}`;

  const calculateTotalAmount = () => {
    const subtotal = Number(EventData?.SubTotal) || 0;
    const charge = Number(EventData?.Charge) || 0;
    const discount = Number(EventData?.CouponAmount) || 0;
    const tot = subtotal + charge - discount;
    return tot;
  };

  const HandlePrint = async (Id) => {
    setIsFormLoading(true);
    try {
      const result = await GetTicketDownloadById(Id, "00");
      if (result) {
        const pdfUrl = result.downloadUrl;
        window.open(pdfUrl, "_blank");
      } else {
        console.error("PDF generation failed:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  return (
    <div className="py-5 container mx-auto ">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      {loading ? (
        <div className="flex justify-center items-center py-48">
          <ClipLoader color={"#DC3558"} loading={loading} size={50} />
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-center bg-white dark:bg-transparent">
            <div className="bg-white dark:bg-boxdark rounded-lg border mx-2 shadow-lg md:p-7 p-3 max-w-xl w-full relative">
              <div className="flex items-center justify-center mb-6  absolute right-0 top-0">
                <BsFillPrinterFill
                  className="md:text-5xl text-4xl cursor-pointer bg-themecolor3 text-white md:p-2.5 p-1.5 rounded"
                  onClick={() => {
                    HandlePrint(EventData?.Id);
                  }}
                />
              </div>
              <div className="flex items-center justify-center mb-6">
                <FaCheckCircle className="text-green-600 text-5xl mr-2" />
                <h2 className="md:text-3xl text-2xl font-bold text-gray-800 dark:text-white">
                  Booking Successful !
                </h2>
              </div>
              <p className="text-gray-600  dark:text-white text-center mb-4 md:text-base text-sm">
                Your ticket(s) have been successfully reserved. Thank you for
                choosing us!
              </p>
              <div className="border-t border-gray-300 mt-4 pt-4">
                <h3 className="md:text-lg text-base font-semibold  dark:text-white mb-2">
                  Booking Details{" "}
                  <span className="ml-auto">
                    {EventData.Status === 0 ? (
                      <span className="bg-red-500 text-white text-xs p-0.5 px-3 rounded">
                        Pending
                      </span>
                    ) : (
                      <span className="bg-green-500 text-white text-xs p-0.5 px-3 rounded">
                        Success
                      </span>
                    )}
                  </span>
                </h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y dark:text-white divide-gray-300">
                    <tbody className="bg-white dark:bg-boxdark divide-y divide-gray-200">
                      {EventData?.transactionId && (
                        <tr className="flex justify-between py-2">
                          <td className="md:text-base text-sm">
                            Transaction Id:
                          </td>
                          <td className="font-bold md:text-base text-sm underline">
                            {EventData.transactionId}
                          </td>
                        </tr>
                      )}
                      {EventData?.EventName && (
                        <tr className="flex justify-between py-2">
                          <td className="md:text-base text-sm">Event:</td>
                          <td className="font-medium text-end md:text-base text-sm">
                            {EventData.EventName}
                          </td>
                        </tr>
                      )}
                      <tr className="flex justify-between py-2">
                        <td className="md:text-base text-sm">Date:</td>
                        <td className="font-medium text-end md:text-base text-sm">
                          {formattedStartDate}
                        </td>
                      </tr>
                      <tr className="flex justify-between py-2">
                        <td className="md:text-base text-sm">Time:</td>
                        <td className="font-medium text-end md:text-base text-sm">
                          {EventData.EventStartTime} - {EventData.EventEndTime}
                        </td>
                      </tr>
                      <tr className="flex justify-between py-2">
                        <td className="md:text-base text-sm">Location:</td>
                        <td className="font-medium text-end md:text-base text-sm">
                          {EventData.Location}, {EventData.City},{" "}
                          {EventData.Country}
                        </td>
                      </tr>
                      {EventData?.Qty && (
                        <tr className="flex justify-between py-2">
                          <td className="md:text-base text-sm">Tickets:</td>
                          <td className="font-medium text-end md:text-base text-sm">
                            {EventData.Qty}
                          </td>
                        </tr>
                      )}
                      {EventData?.CouponAmount && (
                        <tr className="flex justify-between py-2">
                          <td className="md:text-base text-sm">
                            Coupon Amount:
                          </td>
                          <td className="font-medium flex items-center text-end md:text-base text-sm">
                            {getCountrySlug === "India" ? (
                              <FaRupeeSign className="text-sm" />
                            ) : (
                              <FaDollarSign className="text-sm" />
                            )}{" "}
                            {EventData.CouponAmount}
                          </td>
                        </tr>
                      )}
                      {EventData?.Charge && (
                        <tr className="flex justify-between py-2">
                          <td className="md:text-base text-sm">
                            Booking Fees:
                          </td>
                          <td className="font-medium flex items-center text-end md:text-base text-sm">
                            {getCountrySlug === "India" ? (
                              <FaRupeeSign className="text-sm" />
                            ) : (
                              <FaDollarSign className="text-sm" />
                            )}{" "}
                            {EventData.Charge}
                          </td>
                        </tr>
                      )}
                      {EventData?.SubTotal && (
                        <tr className="flex justify-between py-2">
                          <td className="md:text-base text-sm">Sub Total:</td>
                          <td className="font-medium flex items-center text-end md:text-base text-sm">
                            {getCountrySlug === "India" ? (
                              <FaRupeeSign className="text-sm" />
                            ) : (
                              <FaDollarSign className="text-sm" />
                            )}{" "}
                            {EventData.SubTotal}
                          </td>
                        </tr>
                      )}
                      <tr className="flex justify-between py-2">
                        <td className="md:text-base text-sm">Total:</td>
                        <td className="font-medium flex items-center text-end md:text-base text-sm">
                          {getCountrySlug === "India" ? (
                            <FaRupeeSign className="text-sm" />
                          ) : (
                            <FaDollarSign className="text-sm" />
                          )}{" "}
                          {calculateTotalAmount()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="mt-3">
                {UserId === null ? (
                  <button
                    type="button"
                    onClick={() => handleLoginClick()}
                    className="w-full bg-green-600 hover:bg-green-700 md:text-base text-sm text-white font-bold py-2 rounded transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    Login To View Order
                  </button>
                ) : (
                  <Link to={"/order-history"}>
                    <button
                      type="button"
                      className="w-full bg-green-600 hover:bg-green-700 md:text-base text-sm text-white font-bold py-2 rounded transition duration-300 ease-in-out transform hover:scale-105"
                    >
                      View Ticket
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {loginpopup ? (
        <div>
          <div className="px-3 justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto w-96">
              <div className="border-0 rounded-lg shadow-lg relative h-80 flex flex-col w-full bg-white  outline-none dark:bg-boxdark-2 dark:text-white   focus:outline-none">
                <div className="flex items-start  justify-between p-5 border-b border-solid border-blueGray-200 rounded-t dark:border-zinc-600">
                  <h3 className="text-xl  block font-semibold">Login</h3>
                  <button
                    className="text-themecolor1 background-transparent font-bold uppercase  text-3xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setloginpopup(false)}
                  >
                    <RxCross2 />
                  </button>
                </div>
                {isMobileScreen ? (
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <p className="text-center text-xl mt-2">
                        Enter Mobile No
                      </p>
                      <div className="p-6 pt-2 border-solid border-blueGray-200 pb-6 rounded-b">
                        <div className="flex border-0 w-full">
                          <input
                            type="text"
                            name="EOP"
                            autoFocus
                            value={formik.values.EOP}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            maxLength={phoneLengthByCountry[getCountrySlug] || 10}
                            className="border dark:border-zinc-600 outline-none w-full p-2 rounded-md dark:bg-boxdark-2 dark:text-white"
                            placeholder="Enter Mobile Number"
                          />
                        </div>
                        {formik.touched.EOP && formik.errors.EOP ? (
                          <div className="text-red-500 text-xs mt-2">
                            {formik.errors.EOP}
                          </div>
                        ) : null}
                      </div>
                      <div className="px-6">
                        <button
                          className="bg-themecolor1 p-2 w-full text-white rounded-md"
                          type="submit"
                        >
                          Continue
                        </button>
                      </div>
                      <p className="text-xs block px-6 m-auto text-gray-500 py-5 dark:text-white">
                        I agree to the{" "}
                        <Link
                          to={"/page/terms-and-conditions"}
                          className="underline decoration-1"
                        >
                          Terms & conditions
                        </Link>{" "}
                        &{" "}
                        <Link
                          to={"/page/privacy-policy"}
                          className="underline decoration-1"
                        >
                          Privacy Policy
                        </Link>
                      </p>
                    </form>
                  </div>
                ) : (
                  <div className="my-auto">
                    <form onSubmit={formik2.handleSubmit}>
                      <p className="text-center text-xl">Enter OTP</p>
                      <div className="p-3  w-full flex justify-center">
                        <input
                          type="text"
                          placeholder="****"
                          name="OTP"
                          autoFocus
                          maxLength={4}
                          value={formik2.values.OTP}
                          onChange={formik2.handleChange}
                          className="border text-center w-full dark:bg-boxdark-2 dark:text-white py-3  mx-2 tracking-wide"
                        />
                      </div>
                      {formik2.touched.OTP && formik2.errors.OTP ? (
                        <div className="text-red-500 text-xs mt-2 px-5">
                          {formik2.errors.OTP}
                        </div>
                      ) : null}
                      <div className="text-center text-xs mt-3">
                        {showResend ? (
                          <>
                            OTP expired?{" "}
                            <button
                              onClick={handleResendOTP}
                              type="button"
                              className="underline text-themecolor1"
                            >
                              Resend OTP
                            </button>
                          </>
                        ) : (
                          `Resend OTP in ${formatTimer()}`
                        )}
                      </div>
                      <div className="grid grid-cols-2 mt-7">
                        <button
                          className="bg-themecolor1 mx-5 p-2 text-white rounded-md"
                          onClick={() => setIsMobileScreen(true)}
                          type="button"
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="bg-themecolor1 mx-5 p-2 text-white text-center rounded-md"
                        >
                          Verify OTP
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </div>
  );
};

export default OrderSuccess;
