import React, { useEffect, useState } from "react";
import bgimage from "../../Images/faq.jpg";
import { IoChevronDownOutline } from "react-icons/io5";

import Breadcrumb from "../Breadcumb/Breadcumb";
import { getFaqs } from "../API/FaqApi";
import usePageSEO from "../hoosk/usepageSEO/Index";
import ScrollToTop from "../ScrollToTop";

const FAQ = () => {
  usePageSEO(
    "Love My Show | FAQ", // title
    "Love My Show | FAQ", // description
    ["Love My Show | FAQ "] // keywords
  );
  const breadcrumbItems = [{ text: "FAQ" }];
  const backgroundImage = bgimage;
  // ==================Kyc Data===============
  const [kycData, setKycData] = useState([]);
  const [openIndex, setOpenIndex] = useState(null); // Track the index of the currently open question

  useEffect(() => {
    const fetchData = async () => {
      try {
        const UserData = await getFaqs();
        setKycData(UserData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const AccordionItem = ({ index, question, answer }) => {
    const isOpen = index === openIndex;

    return (
      <div
        className={`transition-all duration-1000 ease-in-out bg-white border border-gray-200 cursor-pointer hover:bg-gray-50 ${
          isOpen ? "open" : ""
        }`}
      >
        <button
          type="button"
          className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
          onClick={() => setOpenIndex(isOpen ? null : index)} // Toggle the open index
        >
          <span className="flex text-lg font-semibold text-black">
            {question}
          </span>
          <IoChevronDownOutline
            className={`w-6 h-6 text-gray-400 dark:bg-boxdark-2 dark:text-white ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        </button>

        <div
          className={`px-4 pb-5 sm:px-6 sm:pb-6 pt-3 border-t ${
            isOpen ? "" : "hidden"
          }`}
        >
          <p className="dark:text-black ">{answer}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="dark:bg-boxdark-2 dark:text-white ">
      <ScrollToTop />
      <Breadcrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
      <section className="py-10 bg-gray-50 dark:bg-boxdark-2 dark:text-white sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight   sm:text-4xl lg:text-5xl">
              Frequently Asked Questions
            </h2>
          </div>

          <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
            {kycData?.map((faq, index) => (
              <AccordionItem
                key={faq.Id}
                index={index}
                question={faq.Title}
                answer={faq.Content}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQ;
