/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import usePageSEO from "../../hoosk/usepageSEO/Index";
import DashboardMenu from "../DashboardMenu";
import ScrollToTop from "../../ScrollToTop";
import FormLoader from "../../common/FormLoader";
import { getSponsorById, updateSponsorById } from "../../API/SponsorApi";
import { useFormik } from "formik";

const validationSchema = Yup.object().shape({
  Name: Yup.string().required("Sponsor Name is required"),
  Phone: Yup.string().required("Sponsor Phone is required"),
  Status: Yup.string().required("Status is required"),
});

const SponsorEdit = () => {
  usePageSEO(
    "Love My Show | Sponsor Edit", // title
    "Love My Show | Sponsor Edit", // description
    ["Love My Show | Sponsor Edit"] // keywords
  );
  const { Id } = useParams();
  const [isFormLoading, setIsFormLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getSponsorById(Id);
        formik.setValues(result);
      } catch (error) {
        console.error("Error fetching Sponsor data:", error);
      }
    };

    fetchData();
  }, [Id]);

  const formik = useFormik({
    initialValues: {
      VendorId: Id,
      Name: "",
      Phone: "",
      Status: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        await updateSponsorById(values);
        navigate("/sponsor");
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  return (
    <div className="dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full lg:grid grid-cols-5 py-10 px-3">
          <DashboardMenu />

          <div className="relative flex h-full flex-col min-w-0 break-words w-full rounded col-span-4 py-3">
            <div className="flex-auto">
              <div className="px-2">
                <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  <div className="border-b flex items-center bg-themecolor1 py-1 mb-2">
                    <Link to={"/sponsor"}>
                      <button
                        type="button"
                        className="flex bg-green-500 text-white dark:bg-boxdark-2 dark:border-white dark:border px-6 py-1 my-1 ml-2 rounded-full"
                      >
                        Back
                      </button>
                    </Link>
                    <h1 className="text-sm ml-auto font-medium pt-0 text-white pr-3">
                      Sponsor Edit
                    </h1>
                  </div>

                  <form onSubmit={formik.handleSubmit}>
                    <div className="container mx-auto border my-2 p-4">
                      <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                        <div>
                          <h1 className="mb-2 text-sm">
                            Sponsor Name
                            <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="text"
                            name="Name"
                            value={formik.values.Name}
                            onChange={formik.handleChange}
                            className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                            placeholder="Sponsor Name"
                          />

                          {formik.touched.Name && formik.errors.Name ? (
                            <div className="text-red-500 text-xs mt-2">
                              {formik.errors.Name}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <h1 className="mb-2 text-sm">
                            Sponsor Phone
                            <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="text"
                            name="Phone"
                            value={formik.values.Phone}
                            onChange={formik.handleChange}
                            className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                            placeholder="Sponsor Phone"
                          />

                          {formik.touched.Phone && formik.errors.Phone ? (
                            <div className="text-red-500 text-xs mt-2">
                              {formik.errors.Phone}
                            </div>
                          ) : null}
                        </div>
                        <div className="">
                          <h1 className="mb-2 text-sm">
                            Sponsor Status
                            <span className="text-red-600">*</span>
                          </h1>
                          <div className="relative">
                            <input
                              type="radio"
                              onChange={formik.handleChange}
                              name="Status"
                              className="mx-2 ml-0"
                              value="1"
                              checked={formik.values.Status === "1"}
                            />
                            Active
                            <input
                              type="radio"
                              onChange={formik.handleChange}
                              name="Status"
                              className="mx-2"
                              value="0"
                              checked={formik.values.Status === "0"}
                            />
                            Inactive
                          </div>
                          {formik.touched.Status && formik.errors.Status ? (
                            <div className="text-red-500 text-xs mt-2">
                              {formik.errors.Status}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center ">
                      <button
                        type="submit"
                        className="bg-[#EC5E71] block md:my-3 placeholder:my-2 text-white font-semibold px-12 p-2 rounded-md mx-auto"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorEdit;
