import axios from "axios";
import { toast } from "react-toastify";
import Config from "./Config";

const { API_BASE_URL } = Config; // Destructure the values from the config file

const TOKEN = Config.getToken();
const UserId = Config.getId();

const headers = {
  Authorization: `Bearer ${TOKEN}`, // Corrected typo from "Bareer" to "Bearer"
};

// ------------------------Add Event---------------------
export const AddEvent = async (formData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/list-your-event`,
      formData,
      {
        headers,
      }
    );
    if (response.data.status === true) {
      toast.success(response.data.message); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ------------------------Add Event Address---------------------
export const AddEventAddress = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/list-your-event-address`,
      data,
      {
        headers,
      }
    );
    if (response.data.status === true) {
      toast.success(response.data.message); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// =========================Get All Event=========================
export const getAllEvent = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/event/${UserId}`, {
      headers,
    });
    return response.data.responsedata;
  } catch (error) {
    throw error;
  }
};

// ----------------------getEventbyId----------------DB
export const getEventById = async (EventId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/event/${UserId}/${EventId}`,
      {
        headers,
      }
    );

    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ===================Edit Event================DB
export const updateEventById = async (formData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/event`, formData, {
      headers,
      "Content-Type": "multipart/form-data",
    });

    if (response.data.status === true) {
      toast.success("Event Update Successfully.."); // Toast success message
      return response.data;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ------------------------delete Event---------------------
export const deleteEvent = async (Id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/event/${Id}`, {
      headers,
    });
    if (response.data.status === true) {
      toast.success("Event delete successfully..."); // Toast success message
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ------------------------deleteEventTicketDate---------------------
// https://www.bme.seawindsolution.ae/api/f/event/address/date/ticket/1
export const deleteEventTicketDate = async (Id) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/event/address/date/${Id}`,
      {
        headers,
      }
    );
    if (response.data.status === true) {
      toast.success("Event Date delete successfully..."); // Toast success message
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ------------------------delete Event Ticket---------------------
// https://www.bme.seawindsolution.ae/api/f/event/address/date/ticket/1
export const deleteEventTicket = async (Id) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/event/address/date/ticket/${Id}`,
      {
        headers,
      }
    );
    if (response.data.status === true) {
      toast.success("Event ticket delete successfully..."); // Toast success message
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ------------------------delete Address---------------------
export const deleteEventAddress = async (Id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/event/address/${Id}`, {
      headers,
    });
    if (response.data.status === true) {
      toast.success("Event address delete successfully..."); // Toast success message
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ----------------------getEventAddressbyId----------------DB
// https://www.bme.seawindsolution.ae/api/f/event/address/1/1
export const getEventAddressbyId = async (EventId, Id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/event/address/${EventId}/${Id}`,
      {
        headers,
      }
    );

    if (response.data.status === true) {
      return response.data.data;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ===================updateEventAddressById================DB
// https://www.bme.seawindsolution.ae/api/f/event/address
export const updateEventAddressById = async (formData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/event/address`,
      formData,
      {
        headers,
      }
    );

    if (response.data.status === true) {
      toast.success("Event Address Update Successfully.."); // Toast success message
      return response.data;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ---------------------------------USER SIDE FRONT-------------------------------
// ----------------------getEventByCityIdCategoryId----------------
export const getEventByCityIdCategoryId = async (CityId, CatId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/events/${CityId}/${CatId}`
    );

    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ----------------------getEventBySlugEventId----------------
// https://www.bme.seawindsolution.ae/api/f/events/7/kirtidan-gadhvi-dandiya/3
export const getEventBySlugEventId = async (CityId, EventSlug, Id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/events/${CityId}/${EventSlug}/${Id}/${UserId}`
    );

    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ----------------------getEventByFilterData----------------
// https://www.bme.seawindsolution.ae/api/f/events/7?LanguageId=1|2|3&Duration=10 Days|10 Days2&
export const getEventByFilterData = async (
  CityId,
  languageIds,
  categoryIds,
  TypeId,
  ArtistId,
  DatesId
) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/events/${CityId}?LanguageIds=${languageIds}&CategoryIds=${categoryIds}&TypeIds=${TypeId}&ArtistIds=${ArtistId}&DatesId=${DatesId}`
    );

    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error;
  }
};

// ----------------------getEventBookAddress----------------BOOK1
// https://www.bme.seawindsolution.ae/api/f/e/address/5
export const getEventBookAddress = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/e/address/${Id}`, {
      headers,
    });

    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ----------------------getEventBookDates----------------BOOK2
// https://www.bme.seawindsolution.ae/api/f/e/address/dates/1
export const getEventBookDates = async (AddressId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/e/address/dates/${AddressId}`,
      {
        headers,
      }
    );

    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ----------------------getEventBookTicketData----------------BOOK3
// https://www.bme.seawindsolution.ae/api/f/e/address/dates/tickets/8
export const getEventBookTicketData = async (DateId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/e/address/dates/tickets/${DateId}`,
      {
        headers,
      }
    );

    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ----------------------PostEventBookTicketData----------------BOOK4
// https://www.bme.seawindsolution.ae/api/f/e/event/address/date/tickets
export const PostEventBookTicketData = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/e/event/address/date/ticketsnew`,
      data
    );

    if (response.data.status === true) {
      return response.data;
    } else if (response.data.code === 200) {
      toast.info(response.data.message)
    } else{
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ----------------------CreateTicketOrder----------------BOOK2
//https://www.bme.seawindsolution.ae/api/f/e/order/create
export const CreateTicketOrder = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/e/order/create`, data, {
      headers,
    });

    if (response.data.status === true) {
      return response.data;
    } else if (response.data.code === 200) {
      toast.info(response.data.message)
    } else{
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ----------------------CreateTicketOrder----------------
//https://www.bme.seawindsolution.ae/api/f/e/order/check
export const CheckTicketOrder = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/e/order/check`, data, {
      headers,
    });

    if (response.data.status === true) {
      return response.data;
    } else if (response.data.code === 200) {
      toast.info(response.data.message)
    } else{ 
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ----------------------getEventBookByUserId----------------BOOK2
// https://www.bme.seawindsolution.ae/api/f/e/order/user
export const getEventBookByUserId = async (UserId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/e/order/user/${UserId}`, {
      headers,
    });

    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ------------------------GetAllBookedOrderByEventId---------------------
// https://bme.seawindsolution.ae/api/order/event/10
export const GetAllBookedOrderByEventId = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/e/order/event/${Id}`, {
      headers,
    });
    if (response.data.status === true) {
      return response.data.responseData; // Return response data
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ------------------------GetAllBookedOrderByVendorId---------------------
// https://www.bme.seawindsolution.ae/api/f/e/order/vendor/10
export const GetAllBookedOrderByVendorId = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/e/order/vendor/${UserId}`,
      {
        headers,
      }
    );
    if (response.data.status === true) {
      return response.data.responsedata; // Return response data
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ------------------------Add AddEventLike---------------------
export const AddEventLike = async (UserId, count, EventId) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/e/like/add`,
      { UserId, count, EventId },
      {
        headers,
      }
    );
    if (response.data.status === true) {
      toast.success("Event Like Successfullyy.."); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error("Event Already Like");
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ------------------------remove RemoveEventLike---------------------
export const RemoveEventLike = async (UserId, count, EventId) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/e/like/remove`,
      { UserId, count, EventId },
      {
        headers,
      }
    );
    if (response.data.status === true) {
      toast.success("Event UnLike Successfullyy.."); // Toast success message
      return response.data; // Return response data
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ----------------------getEventByCityId----------------
//https://www.bme.seawindsolution.ae/api/f/events/7
export const getEventByCityId = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/events/${Id}`);

    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ---------------------------------USER SIDE HOME FRONT-------------------------------
// ----------------------getHomeEventByCityId----------------
// https://www.bme.seawindsolution.ae/api/f//home/category/events/7
export const getHomeEventByCityId = async (CityId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/home/category/events/${CityId}`
    );

    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ----------------------getHomeEventByType----------------
// https://www.bme.seawindsolution.ae/api/f/home/type/events/7
export const getHomeEventByType = async (CityId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/home/type/events/${CityId}`
    );

    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ----------------------getSearchEventByType----------------
// https://www.bme.seawindsolution.ae/api/f/search/7/dandiya
export const getSearchEventByType = async (CityId, SearchKeyword) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/search/${CityId}/${SearchKeyword}`
    );

    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ===================GetAllBookedOrderById=====================
// https://www.bme.seawindsolution.ae/api/f/e/order/8
export const GetAllBookedOrderById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/e/order/${Id}`, {
      headers,
    });
    if (response.data.status === true) {
      return response.data.responsedata; // Return response data
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
// ===================GetTicketDownloadById=====================
// https://www.bme.seawindsolution.ae/api/f/e/order/pdf/4
export const GetTicketDownloadById = async (OrderId,Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/e/order/pdf/${OrderId}/${Id}`, {
      headers,
    });
    if (response.data.status === true) {
      return response.data; // Return response data
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ===================ShareTicket================D/e/order/split
export const ShareTicket = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/e/order/split`, data, {
      headers, 
    });

    if (response.data.status === true) {
      toast.success(response.data.message);
      return response.data;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ===================SendEmailAPI=====================
// https://www.bme.seawindsolution.ae/api/f/e/order/email/:OrderId/:SplitOrderId
export const SendEmailAPI = async (OrderId,SplitOrderId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/e/order/email/${OrderId}/${SplitOrderId}`, {
      headers,
    });
    if (response.data.status === true) {
      toast.success(response.data.message)
      return response.data; // Return response data
    } else {
      toast.error(response.data.message)
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};


// ===================SendEmailAPI=====================
// https://www.bme.seawindsolution.ae/api/f/e/order/sms/:OrderId/:SplitOrderId
export const SendSMSAPI = async (OrderId,SplitOrderId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/e/order/sms/${OrderId}/${SplitOrderId}`, {
      headers,
    });
    if (response.data.status === true) {
      toast.success(response.data.message)
      return response.data; // Return response data
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

