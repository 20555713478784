// -------------------------Old Dynamic State City-------------------
// const Config = {
//   API_BASE_URL: "https://www.bme.seawindsolution.ae/api/f",
//   getToken: () => {
//     const sessiondata = localStorage.getItem("UserToken");

//     const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
//     // const token = parsedSessionData ? parsedSessionData.token : null;
//     return parsedSessionData;
//   },
//   getId: () => {
//     const sessiondata = localStorage.getItem("UserData");
//     const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
//     const Id = parsedSessionData ? parsedSessionData.Id : null;
//     return Id;
//   },
//   getType: () => {
//     const sessiondata = localStorage.getItem("UserData");
//     const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
//     const Type = parsedSessionData ? parsedSessionData.Type : null;
//     return Type;
//   },
//   getUserData: () => {
//     const sessiondata = localStorage.getItem("UserData");
//     const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
//     return parsedSessionData;
//   },
//   getCitySlug: () => {
//     const sessiondata = localStorage.getItem("selectedCitySlug");
//     return sessiondata;
//   },
//   getCityTitle: () => {
//     const sessiondata = localStorage.getItem("selectedCityTitle");
//     return sessiondata;
//   },
//   getCityIdData: () => {
//     const sessiondata = localStorage.getItem("selectedCityId");
//     return sessiondata;
//   },
//   getCountryFlag: () => {
//     const sessiondata = localStorage.getItem("CountryFlag");
//     return sessiondata;
//   },
//   getCountrySlug: () => {
//     const sessiondata = localStorage.getItem("CountrySlug");
//     return sessiondata;
//   },
//   getCountryId: () => {
//     const sessiondata = localStorage.getItem("CountryId");
//     return sessiondata;
//   },

//   getKYCStatus: () => {
//     const sessiondata = localStorage.getItem("KYCStatus");
//     const Type = sessiondata ? JSON.parse(sessiondata) : null;
//     return Type;
//   },
// };

// export default Config;


const Config = {
  API_BASE_URL: "https://www.bme.seawindsolution.ae/api/f",
  getToken: () => {
    const sessiondata = localStorage.getItem("UserToken");

    const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
    // const token = parsedSessionData ? parsedSessionData.token : null;
    return parsedSessionData;
  },
  getId: () => {
    const sessiondata = localStorage.getItem("UserData");
    const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
    const Id = parsedSessionData ? parsedSessionData.Id : null;
    return Id;
  },
  getType: () => {
    const sessiondata = localStorage.getItem("UserData");
    const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
    const Type = parsedSessionData ? parsedSessionData.Type : null;
    return Type;
  },
  getUserData: () => {
    const sessiondata = localStorage.getItem("UserData");
    const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
    return parsedSessionData;
  },
  getCitySlug: () => {
    const sessiondata = localStorage.getItem("selectedCitySlug");
    return "Ahmedabad";
  },
  getCityTitle: () => {
    const sessiondata = localStorage.getItem("selectedCityTitle");
    return "Ahmedabad";
  },
  getCityIdData: () => {
    const sessiondata = localStorage.getItem("selectedCityId");
    return 7;
  },
  getCountryFlag: () => {
    const sessiondata = localStorage.getItem("CountryFlag");
    return "https://www.bme.seawindsolution.ae/uploads//country/image/1718882486856-Flag_of_India.svg.webp";
  },
  getCountrySlug: () => {
    const sessiondata = localStorage.getItem("CountrySlug");
    return "India";
  },
  getCountryId: () => {
    const sessiondata = localStorage.getItem("CountryId");
    return 3;
  },

  getKYCStatus: () => {
    const sessiondata = localStorage.getItem("KYCStatus");
    const Type = sessiondata ? JSON.parse(sessiondata) : null;
    return Type;
  },
};

export default Config;
