import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";

const { API_BASE_URL } = Config; // Destructure the values from the config file

// ===================sendOTP================
export const sendOTP = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/send-otp`, data);
    if (response.data.status === true) {
      toast.success("Otp Send Successfully...");
      return response.data;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
export const verifyOTP = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/verify-otp`, data);
    if (response.data.status === true) {
      toast.success("Otp Verification Successfully...");
      localStorage.setItem("UserToken", JSON.stringify(response.data.token));
      localStorage.setItem(
        "UserData",
        JSON.stringify(response.data.responsedata[0])
      );
      return response.data;
    } else {
      toast.error("Invalid OTP");
    }
  } catch (error) {
    throw error;
  }
};
