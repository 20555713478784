/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import profile from "../../Images/user.png";
import { UpdateUserById, getUserById } from "../API/UserAPI";
import { useFormik } from "formik";
import Config from "../API/Config";
import DashboardMenu from "./DashboardMenu";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { FaImage } from "react-icons/fa";
import * as Yup from "yup";
import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";

const noSpecialChars = /^[a-zA-Z0-9\s]*$/;
const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers")
    .required("Name is required"),
  Email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  Country: Yup.string()
    .matches(noSpecialChars, "Country cannot contain special characters")
    .required("Country is required"),
  City: Yup.string()
    .min(2, "City name must be at least 2 characters long")
    .max(50, "City name must be at most 50 characters long")
    .matches(/^[A-Za-z\s]+$/, "City name cannot contain numbers")
    .required("City is required"),
  Area: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Area cannot contain numbers")
    .required("Area is required"),
  Pincode: Yup.string()
    .matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits long")
    .required("Pincode is required"),
  Address: Yup.string().required("Address is required"),
});
const Profile = () => {
  usePageSEO(
    "Love My Show | Profile", // title
    "Love My Show | Profile", // description
    ["Love My Show | Profile "] // keywords
  );

  const [imagePreview, setImagePreview] = useState(profile);
  // ================ Get data by Id============
  const Id = Config.getId();
  const UserType = Config.getType();
  const fetchData = async () => {
    try {
      if (Id) {
        const UserData = await getUserById(Id, UserType);
        formik.setValues(UserData[0]);
        if (UserData[0].Image) {
          setImagePreview(UserData[0].Image); // Update image preview if image exists
        }
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [Id, UserType]);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      Id: Id,
      Name: "",
      Email: "",
      Phone: "",
      Type: "",
      Country: "",
      City: "",
      Area: "",
      Pincode: "",
      Address: "",
      Image: "",
      Hid_Image: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        await UpdateUserById(formData);
        fetchData();
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  // -----------------get Data ById------------
  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          <DashboardMenu />

          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                {/* ===============Account Details =============*/}
                <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  <div className="grid grid-cols-1">
                    <div className="bg-gradient-to-r from-black to-red-800 p-8 relative">
                      <h1 className="text-white text-xl font-bold">
                        Hi, {formik.values.Name}
                      </h1>
                      <div className="absolute top-1 bg-white rounded-full right-3">
                        <div className="p-2">
                          <img
                            src={imagePreview}
                            alt="User's image"
                            className="h-16 rounded-full"
                          />
                          <div className=" rounded-circle profile-photo-edit">
                            <input
                              name="Image"
                              type="file"
                              id="logoImg"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "Image",
                                  event.currentTarget.files[0]
                                );
                              }}
                              onBlur={formik.handleBlur}
                              className="border hidden dark:border-zinc-600 py-2 px-2 w-full  dark:bg-boxdark-2 dark:text-white "
                              placeholder="1324567980"
                            />
                            <label
                              for="logoImg"
                              className="absolute bottom-6 right-7 cursor-pointer"
                              name="logoImg"
                            >
                              <span className=" absolute bg-white rounded-full p-1.5 border border-black ">
                                <FaImage className="dark:text-black" />
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                      <input
                        type="hidden"
                        name="Hid_Image"
                        value={formik.values.Hid_Image}
                      />
                      <div className="block ">
                        <h1 className="text-lg mt-5 p-2 px-4 text-white font-bold bg-themecolor1">
                          Account Details
                        </h1>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-3 ">
                          <div className="py-4 ">
                            <h1 className="mb-2 text-sm">Name</h1>
                            <input
                              name="Name"
                              type="text"
                              value={formik.values.Name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                              placeholder="Enter Your Name"
                            />
                            {formik.touched.Name && formik.errors.Name ? (
                              <div className="text-red-500 text-xs mt-2">
                                {formik.errors.Name}
                              </div>
                            ) : null}
                          </div>
                          <div className="py-4 ">
                            <h1 className="mb-2 text-sm">Email Address</h1>
                            <input
                              name="Email"
                              type="text"
                              value={formik.values.Email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                              placeholder="Enter Your Email"
                            />
                            {formik.touched.Email && formik.errors.Email ? (
                              <div className="text-red-500 text-xs mt-2">
                                {formik.errors.Email}
                              </div>
                            ) : null}
                          </div>
                          <div className="py-4 pt-0 ">
                            <h1 className="mb-2 text-sm">Phone number</h1>
                            <input
                              name="Phone"
                              readOnly
                              disabled
                              type="text"
                              value={formik.values.Phone}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                            />
                          </div>
                        </div>
                      </div>
                      <div className="block">
                        <h1 className="text-lg mt-5 p-2 px-4 text-white font-bold bg-themecolor1">
                          Personal Details
                        </h1>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                          <div className="py-4 ">
                            <h1 className="mb-2 text-sm">Country</h1>
                            <input
                              name="Country"
                              type="text"
                              value={formik.values.Country}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                              placeholder="Country"
                            />
                            {formik.touched.Country && formik.errors.Country ? (
                              <div className="text-red-500 text-xs mt-2">
                                {formik.errors.Country}
                              </div>
                            ) : null}
                          </div>
                          <div className="py-4 ">
                            <h1 className="mb-2 text-sm">City</h1>
                            <input
                              name="City"
                              type="text"
                              value={formik.values.City}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                              placeholder="City"
                            />
                            {formik.touched.City && formik.errors.City ? (
                              <div className="text-red-500 text-xs mt-2">
                                {formik.errors.City}
                              </div>
                            ) : null}
                          </div>

                          <div className="py-4 ">
                            <h1 className="mb-2 text-sm">Pincode</h1>
                            <input
                              name="Pincode"
                              type="text"
                              value={formik.values.Pincode}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                              placeholder="Pin code"
                            />
                            {formik.touched.Pincode && formik.errors.Pincode ? (
                              <div className="text-red-500 text-xs mt-2">
                                {formik.errors.Pincode}
                              </div>
                            ) : null}
                          </div>
                          <div className="py-4 ">
                            <h1 className="mb-2 text-sm">Area</h1>
                            <input
                              name="Area"
                              type="text"
                              value={formik.values.Area}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                              placeholder="Area"
                            />
                            {formik.touched.Area && formik.errors.Area ? (
                              <div className="text-red-500 text-xs mt-2">
                                {formik.errors.Area}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="py-4 ">
                          <h1 className="mb-2 text-sm">Address</h1>

                          <textarea
                            rows="3"
                            name="Address"
                            value={formik.values.Address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                            placeholder="Address"
                          ></textarea>
                          {formik.touched.Address && formik.errors.Address ? (
                            <div className="text-red-500 text-xs mt-2">
                              {formik.errors.Address}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="block m-auto bg-themecolor1 text-white p-2 px-10 my-5 rounded-full"
                      >
                        Save Changes
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
