import React, { useEffect, useState } from "react";
import {
  getAllLanguage,
  getAllCountry,
  getCityByCountryId,
} from "../API/State-City-Language-API";
import FormLoader from "../common/FormLoader";
import { getEventAddressbyId, updateEventAddressById } from "../API/EventApi";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import usePageSEO from "../hoosk/usepageSEO/Index";

const AddAddressEdit = () => {
  usePageSEO(
    "Love My Show | Edit Address", // title
    "Love My Show | Edit Address", // description
    ["Love My Show  | Edit Address"] // keywords
  );
  const { Id, EventId } = useParams();
  const navigate = useNavigate();

  const initialFormData = {
    EventId: EventId,
    CityId: "",
    CountryId: "",
    LanguageId: "",
    Duration: "",
    Type: "",
    Location: "",
    Address: "",
    EventTicket: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [languageData, setLanguageData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getEventAddressbyId(EventId, Id);
        setFormData(result);
      } catch (error) {
        console.error("Error fetching event address data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [Id, EventId]);
  useEffect(() => {
    const fetchLanguageData = async () => {
      try {
        const result = await getAllLanguage();
        setLanguageData(result);
      } catch (error) {
        console.error("Error fetching language data:", error);
      }
    };

    const fetchCountryData = async () => {
      try {
        const result = await getAllCountry();
        setCountryData(result);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    if (formData && formData.CountryId) {
      // Add formData check
      fetchCities(formData.CountryId);
    }

    fetchLanguageData();
    fetchCountryData();
  }, [formData]); // Update dependencies

  const fetchCities = async (countryId) => {
    try {
      const response = await getCityByCountryId(countryId);
      setCityData(response);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleInputChange = (e, index, subIndex) => {
    const { name, value } = e.target;

    // Handle special cases, like fetching cities based on CountryId
    if (name === "CountryId") {
      fetchCities(value); // Assuming fetchCities function works correctly
    }

    // Update form data state
    setFormData((prevFormData) => {
      // Check if index and subIndex are defined to handle nested updates
      if (index !== undefined && subIndex !== undefined) {
        const updatedEventTicket = prevFormData.EventTicket.map(
          (eventDate, i) => {
            if (i === index) {
              const updatedTicketdata = eventDate.Ticketdata.map((ticket, j) =>
                j === subIndex ? { ...ticket, [name]: value } : ticket
              );
              return { ...eventDate, Ticketdata: updatedTicketdata };
            }
            return eventDate;
          }
        );

        return { ...prevFormData, EventTicket: updatedEventTicket };
      }

      // If only index is defined, update EventTicket at the specified index
      if (index !== undefined) {
        const updatedEventTicket = prevFormData.EventTicket.map(
          (eventDate, i) =>
            i === index ? { ...eventDate, [name]: value } : eventDate
        );
        return { ...prevFormData, EventTicket: updatedEventTicket };
      }

      // Default case: update top-level fields
      return { ...prevFormData, [name]: value };
    });

    // Clear error message if it exists for the current input field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for this field
    }));
  };

  const getMinDateTime = () => {
    const now = new Date();
    now.setDate(now.getDate() + 1); // Add 1 day
    const minDate = now.toISOString().split("T")[0]; // Get YYYY-MM-DD format
    return minDate;
  };

  const handleAddTicketData = (index) => {
    setFormData((prevFormData) => {
      const updatedEventTicket = prevFormData.EventTicket.map(
        (eventDate, i) => {
          if (i === index) {
            return {
              ...eventDate,
              Ticketdata: [
                ...eventDate.Ticketdata,
                { Id: 0, TicketName: "", TicketAmount: "", TicketQuantity: "" },
              ],
            };
          }
          return eventDate;
        }
      );

      return { ...prevFormData, EventTicket: updatedEventTicket };
    });
  };
  const handleAddEventTicket = () => {
    setFormData((prevState) => ({
      ...prevState,
      EventTicket: [
        ...prevState.EventTicket,
        {
          Id: 0,
          EventDate: "",
          EventStartTime: "",
          EventEndTime: "",
          Ticketdata: [
            { Id: 0, TicketName: "", TicketAmount: "", TicketQuantity: "" },
          ],
        },
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    try {
      // Perform validation if needed using Yup or other validation schema

      const data = await updateEventAddressById(formData); // Update API call with your function
      if (data.status === true) {
        navigate("/your-listing");
      }
    } catch (error) {
      console.error("Submission Error:", error);
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-5 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  <div className="border-b flex items-center bg-themecolor1 py-2 mb-2">
                    <h1 className="text-lg text-center text-white mx-auto  py-1 font-bold pt-0">
                      Address Edit
                    </h1>
                  </div>

                  {loading ? (
                    <div className="flex justify-center items-center my-48">
                      <ClipLoader color="#c82f32" loading={loading} size={30} />
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <h1 className="text-lg p-2 py-1 mt-4 text-white font-bold bg-black container mx-auto mb-4">
                        Address Details <span className="text-red-600">*</span>
                      </h1>
                      <div className="container mx-auto border my-2 p-4">
                        <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
                          <div>
                            <h1 className="mb-2 text-sm">
                              Country <span className="text-red-600">*</span>
                            </h1>
                            <select
                              name="CountryId"
                              disabled
                              value={formData.CountryId}
                              onChange={(e) => handleInputChange(e)}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                            >
                              <option value="">Select Country</option>
                              {countryData?.map((val, index) => {
                                return (
                                  <option value={val.Id} key={index}>
                                    {val.Title}
                                  </option>
                                );
                              })}
                            </select>
                            {formErrors.CountryId && (
                              <p className="text-red-500 text-sm">
                                {formErrors.CountryId}
                              </p>
                            )}
                          </div>
                          <div>
                            <h1 className="mb-2 text-sm">
                              City <span className="text-red-600">*</span>
                            </h1>
                            <select
                              name="CityId"
                              value={formData.CityId}
                              onChange={(e) => handleInputChange(e)}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                            >
                              <option value="">Select City</option>
                              {cityData?.map((val, index) => (
                                <option value={val.Id} key={index}>
                                  {val.Title}
                                </option>
                              ))}
                            </select>
                            {formErrors.CityId && (
                              <p className="text-red-500 text-sm">
                                {formErrors.CityId}
                              </p>
                            )}
                          </div>

                          <div>
                            <h1 className="mb-2 text-sm">
                              Event Language{" "}
                              <span className="text-red-600">*</span>
                            </h1>
                            <select
                              name="LanguageId"
                              value={formData.LanguageId}
                              onChange={(e) => handleInputChange(e)}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                            >
                              <option value="">Select Language</option>
                              {languageData?.map((val, index) => {
                                return (
                                  <option value={val.Id} key={index}>
                                    {val.Title}
                                  </option>
                                );
                              })}
                            </select>
                            {formErrors.LanguageId && (
                              <p className="text-red-500 text-sm">
                                {formErrors.LanguageId}
                              </p>
                            )}
                          </div>
                          <div>
                            <h1 className="mb-2 text-sm">
                              Event Duration{" "}
                              <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="text"
                              name="Duration"
                              value={formData.Duration}
                              onChange={(e) => handleInputChange(e)}
                              className="border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
                              placeholder="Event Duration"
                            />
                            {formErrors.Duration && (
                              <p className="text-red-500 text-sm">
                                {formErrors.Duration}
                              </p>
                            )}
                          </div>
                          <div>
                            <h1 className="mb-2 text-sm">
                              Select Type of Event{" "}
                              <span className="text-red-600">*</span>
                            </h1>
                            <select
                              id="type"
                              name="Type"
                              value={formData.Type}
                              onChange={(e) => handleInputChange(e)}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                            >
                              <option value="">Select Type of Event</option>
                              <option value="Indoor">Indoor</option>
                              <option value="Outdoor">Outdoor</option>
                            </select>
                            {formErrors.Type && (
                              <p className="text-red-500 text-sm">
                                {formErrors.Type}
                              </p>
                            )}
                          </div>

                          <div>
                            <h1 className="mb-2 text-sm">
                              Location <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="text"
                              value={formData.Location}
                              onChange={(e) => handleInputChange(e)}
                              name="Location"
                              className="border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
                              placeholder="Location"
                            />
                            {formErrors.Location && (
                              <p className="text-red-500 text-sm">
                                {formErrors.Location}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <h1 className="mb-2 text-sm mt-3">
                            Location Iframe{" "}
                            <span className="text-red-600">*</span>
                          </h1>
                          <textarea
                            name="Address"
                            value={formData.Address}
                            rows={4}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Location Iframe"
                            className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                          ></textarea>
                          {formErrors.Address && (
                            <p className="text-red-500 text-sm">
                              {formErrors.Address}
                            </p>
                          )}
                        </div>
                      </div>
                      {formData.EventTicket?.map((eventDate, index) => (
                        <div
                          key={index}
                          className="container mx-auto border p-4 my-6"
                        >
                          <div className="flex flex-col md:flex-row md:justify-between justify-start md:items-center items-start mb-4">
                            <p className="m-0 border border-themecolor1 py-1.5 rounded-3xl">
                              <span className="bg-themecolor1 text-white px-3.5 py-2 rounded-full mr-2">
                                {index + 1}
                              </span>
                              <span className="pr-3 text-sm">
                                Event Date {index + 1}
                              </span>
                            </p>

                            <button
                              type="button"
                              onClick={() => handleAddEventTicket(index)}
                              className="bg-green-500 py-2 px-5 flex items-center text-white mt-2 md:mt-0 rounded-md text-sm"
                            >
                              Add Event Ticket
                            </button>
                          </div>

                          <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
                            <div>
                              <h1 className="mb-2 text-sm">
                                Event Date{" "}
                                <span className="text-red-600">*</span>
                              </h1>
                              <input
                                type="date"
                                name="EventDate"
                                value={eventDate.EventDate}
                                onChange={(e) => handleInputChange(e, index)}
                                className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                                placeholder="Event Date"
                                min={getMinDateTime()}
                              />
                            </div>
                            <div>
                              <h1 className="mb-2 text-sm">
                                Event Start Time{" "}
                                <span className="text-red-600">*</span>
                              </h1>
                              <input
                                type="time"
                                name="EventStartTime"
                                value={eventDate.EventStartTime}
                                onChange={(e) => handleInputChange(e, index)}
                                className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                                placeholder="Event Date"
                              />
                            </div>
                            <div>
                              <h1 className="mb-2 text-sm">
                                Event End Time{" "}
                                <span className="text-red-600">*</span>
                              </h1>
                              <input
                                type="time"
                                name="EventEndTime"
                                value={eventDate.EventEndTime}
                                onChange={(e) => handleInputChange(e, index)}
                                className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                                placeholder="Event Date"
                              />
                            </div>
                          </div>

                          {eventDate.Ticketdata?.map((ticket, subIndex) => (
                            <div
                              key={subIndex}
                              className="grid lg:grid-cols-9 md:grid-cols-6 grid-cols-1 gap-3 border dark:border-zinc-600 px-4 py-5 my-6 relative "
                            >
                              <div className="lg:col-span-3 md:col-span-2">
                                <h1 className="mb-2 text-sm">
                                  Ticket Name{" "}
                                  <span className="text-red-600">*</span>
                                </h1>
                                <input
                                  type="text"
                                  id={`TicketName${subIndex}`}
                                  name="TicketName"
                                  value={ticket.TicketName}
                                  onChange={(e) =>
                                    handleInputChange(e, index, subIndex)
                                  }
                                  className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                                  placeholder="Ticket Name"
                                />
                              </div>
                              <div className="lg:col-span-3 md:col-span-2">
                                <h1 className="mb-2 text-sm">
                                  Ticket Price{" "}
                                  <span className="text-red-600">*</span>
                                </h1>
                                <input
                                  type="text"
                                  id={`TicketAmount${subIndex}`}
                                  name="TicketAmount"
                                  value={ticket.TicketAmount}
                                  onChange={(e) =>
                                    handleInputChange(e, index, subIndex)
                                  }
                                  className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                                  placeholder="Ticket Price"
                                />
                              </div>
                              <div className="lg:col-span-3 md:col-span-2">
                                <h1 className="mb-2 text-sm">
                                  Ticket Quantity{" "}
                                  <span className="text-red-600">*</span>
                                </h1>
                                <input
                                  type="text"
                                  id={`TicketQuantity${subIndex}`}
                                  name="TicketQuantity"
                                  value={ticket.TicketQuantity}
                                  onChange={(e) =>
                                    handleInputChange(e, index, subIndex)
                                  }
                                  className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white"
                                  placeholder="Ticket Quantity"
                                />
                              </div>

                              <h1 className="absolute bg-themecolor2 text-white px-2 -top-3">
                                {subIndex + 1}
                              </h1>
                            </div>
                          ))}

                          <div className="flex">
                            <button
                              type="button"
                              onClick={() => handleAddTicketData(index)}
                              className="bg-green-500 py-2 px-5 flex items-center text-white rounded-md text-sm"
                            >
                              Add Ticket
                            </button>
                          </div>
                        </div>
                      ))}

                      <div className="flex foldsmall:flex-col">
                        <button
                          type="submit"
                          className="bg-[#EC5E71] block my-5 foldsmall:my-2 text-white font-semibold px-12 p-2 rounded-md mx-auto foldsmall:mx-auto"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAddressEdit;
