import React, { useEffect, useState } from "react";
import "./Offers.css";
import { Link } from "react-router-dom";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { getAllOffer } from "../API/OfferApi";
import ClipLoader from "react-spinners/ClipLoader";
import NoDataFound from "../common/NodataFound";
import ScrollToTop from "../ScrollToTop";

const Offers = () => {
  usePageSEO(
    "Love My Show | Offer", // title
    "Love My Show | Offer", // description
    ["Love My Show | Offer "] // keywords
  );
  // ------------------------Offer-----------------------
  const [OfferData, setOfferData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const fetchOfferData = async () => {
    try {
      const result = await getAllOffer();
      setOfferData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchOfferData();
  }, []);

  return (
    <div className="dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {/*================================================Filter Navpills =======================================*/}
      <div className="container  mx-auto">
        <div className=" ">
          <div className="md:py-20 py-10">
            {loading ? (
              <div className="flex justify-center items-center py-40">
                <ClipLoader color={"#DC3558"} loading={loading} size={30} />
              </div>
            ) : OfferData?.length > 0 ? (
              <div className="w-full  rounded ">
                <div className="container mx-auto">
                  <div className="tab-content tab-space ">
                    {/*===================================== CreditCard ===============================================*/}
                    <div>
                      <div className="container mx-auto">
                        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 pb-6 mx-4">
                          {OfferData?.map((val, index) => {
                            return (
                              <div>
                                <Link to={`/offer/${val.Slug}`}>
                                  <div
                                    className="contain bg-white dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600 relative shadow-lg"
                                    key={index}
                                  >
                                    <img
                                      src={val.Image}
                                      alt="Avatar"
                                      className="image w-full block md:h-72 h-48 rounded-sm"
                                    />
                                    <div className="relative pb-3">
                                      <img
                                        src={val.Icon}
                                        alt=""
                                        className="rounded md:w-28 w-20 -top-6 left-4 absolute z-10"
                                      />
                                      <h1 className="text-sm text-center py-8">
                                        {val.Title}
                                      </h1>
                                      <p
                                        className="text-sm text-center mb-3 line-clamp-3 px-4 overflow-hidden"
                                        dangerouslySetInnerHTML={{
                                          __html: val ? val.Content : "",
                                        }}
                                      ></p>
                                    </div>

                                    <div className="overlay absolute md:h-72 h-48 w-full inset-x-0 inset-y-0 opacity-0 rounded-sm">
                                      <h1 className="text absolute top-[9%] border-y md:py-4 py-2 left-[50%] text-center text-sm font-bold text-white w-full">
                                        {val.Title}
                                      </h1>
                                      <p
                                        className="text absolute left-[50%] lg:line-clamp-5 md:line-clamp-4 line-clamp-3 top-[50%] text-center px-2 text-white w-full"
                                        dangerouslySetInnerHTML={{
                                          __html: val ? val.Content : "",
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;
