import axios from "axios";
import { toast } from "react-toastify";
import Config from "./Config";

const { API_BASE_URL } = Config; // Destructure the values from the config file

const TOKEN = Config.getToken();
const VendorId = Config.getId();

const headers = {
  Authorization: `Bearer ${TOKEN}`, // Corrected typo from "Bareer" to "Bearer"
};

// ------------------------Add Sponsor---------------------
// https://bme.seawindsolution.ae/api/f/sponsor
export const AddSponsor = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sponsor`, formData, {
      headers,
    });
    if (response.data.status === true) {
      toast.success(response.data.message); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// =========================Get All Sponsor=========================
// https://bme.seawindsolution.ae/api/f/sponsor/1
export const getAllSponsor = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/sponsor/vendor/${VendorId}`,
      {
        headers,
      }
    );
    return response.data.responsedata;
  } catch (error) {
    throw error;
  }
};

// ----------------------getSponsorbyId----------------DB
// https://bme.seawindsolution.ae/api/f/sponsor/details/2
export const getSponsorById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/sponsor/${Id}`, {
      headers,
    });

    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ===================Edit Sponsor================DB
export const updateSponsorById = async (formData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/sponsor`, formData, {
      headers,
    });

    if (response.data.status === true) {
      toast.success("Sponsor Update Successfully.."); // Toast success message
      return response.data;
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ------------------------delete Sponsor---------------------
// https://bme.seawindsolution.ae/api/f/sponsor/1
export const deleteSponsor = async (Id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/sponsor/${Id}`, {
      headers,
    });
    if (response.data.status === true) {
      toast.success("Sponsor delete successfully..."); // Toast success message
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// ------------------------Add Sponsor---------------------
// https://bme.seawindsolution.ae/api/f/sponsor
export const AssignSponsorTicket = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/sponsor/assign/event`,
      data,
      {
        headers,
      }
    );
    if (response.data.status === true) {
      toast.success(response.data.message); // Toast success message
      return response.data; // Return response data
    } else {
      toast.error(response.data.message);
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};

// =========================Get All Sponsor=========================
// https://bme.seawindsolution.ae/api/f/sponsor/1
export const getAllAssignSponsor = async (EventId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/sponsor/event/${EventId}`,
      {
        headers,
      }
    );
    return response.data.responsedata;
  } catch (error) {
    throw error;
  }
};
