/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import ContactusImg from "../../Images/contact.jpg";
import Breadcrumb from "../Breadcumb/Breadcumb";
import { FaEnvelope, FaMap, FaPhone } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/bootstrap.css";
import { ContactData } from "../API/ContactApi";
import usePageSEO from "../hoosk/usepageSEO/Index";
import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import PhoneInput from "react-phone-input-2";

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers")
    .required("Name is required"),
  Email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Custom email validation message"
    )
    .required("Email is required"),
  Phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .required("Number is required"),
  Message: Yup.string().required("Message is required"),
});

const Contact = () => {
  usePageSEO(
    "Love My Show | Contact Us", // title
    "Love My Show | Contact Us", // description
    ["Love My Show | Contact Us"] // keywords
  );

  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Phone: "",
      Message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await ContactData(values);
        if (response.status === true) {
          actions.resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const breadcrumbItems = [
    {
      text: "Contact Us",
    },
  ];

  const backgroundImage = ContactusImg;
  return (
    <div className="dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <Breadcrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
      <div className="container mx-auto py-5">
        <div className="bg-white border dark:border-gray-400 rounded-md p-6 lg:p-8 drop-shadow-md dark:bg-boxdark-2 dark:text-white mt-8 mx-4">
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-8">
            {/* Form Section */}
            <div className="col-span-2 mx-2 lg:mx-0">
              <h1 className="text-4xl font-bold mb-6">Get in Touch</h1>

              <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h1 className="mb-2 text-sm font-semibold">
                      First Name <span className="text-red-700">*</span>
                    </h1>
                    <input
                      type="text"
                      name="Name"
                      className="border dark:border-zinc-600 py-3 rounded-lg text-sm px-4 text-gray-500 w-full dark:bg-boxdark-2 dark:text-white"
                      placeholder="First Name"
                      value={formik.values.Name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.Name && formik.errors.Name ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.Name}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <h1 className="mb-2 text-sm font-semibold">
                      Email Address <span className="text-red-700">*</span>
                    </h1>
                    <input
                      type="email"
                      name="Email"
                      className="border dark:border-zinc-600 py-3 rounded-lg text-sm px-4 text-gray-500 w-full dark:bg-boxdark-2 dark:text-white"
                      placeholder="Enter Your Email Address"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.Email}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <h1 className="mb-2 text-sm font-semibold mt-3">
                      Phone No <span className="text-red-700">*</span>
                    </h1>
                    <PhoneInput
                      country={"in"}
                      enableSearch={true}
                      value={formik.values.Phone}
                      onChange={(phone) => formik.setFieldValue("Phone", phone)}
                      name="Phone"
                      className="border-0 w-full text-black"
                    />
                    {formik.touched.Phone && formik.errors.Phone ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.Phone}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <h1 className="mb-2 text-sm font-semibold mt-3">
                      Message <span className="text-red-700">*</span>
                    </h1>
                    <textarea
                      rows="3"
                      name="Message"
                      value={formik.values.Message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Message"
                      className="border dark:border-zinc-600 py-3 rounded-lg text-sm px-4 text-gray-500 w-full dark:bg-boxdark-2 dark:text-white"
                    ></textarea>
                    {formik.touched.Message && formik.errors.Message ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.Message}
                      </div>
                    ) : null}
                  </div>
                </div>

                <button
                  className="bg-themecolor1 text-white mt-6 py-2 px-6 rounded-lg hover:bg-orange-600 transition duration-300"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>

            {/* Info Section */}
            <div className="relative mt-6 lg:mt-0">
              <div className="grid grid-cols-5 gap-4 shadow-lg rounded-md my-4 md:h-32 border dark:border-zinc-600 foldsmall:gap-2 p-4">
                <div className="md:col-span-1 my-auto hidden md:block">
                  <h1 className="text-themecolor1 border border-themecolor1 rounded-full text-3xl h-14 w-14 p-3">
                    <FaMap className="block mx-auto" />
                  </h1>
                </div>
                <div className="md:col-span-4 col-span-5 my-auto">
                  <p className="md:text-base text-sm text-justify">
                    D 1, JEE HEET PARK, MADHAPAR CHOWKDY, JAMNAGAR ROAD, RAJKOT,
                    Gujarat, India - 360006
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-5 gap-4 shadow-lg rounded-md my-4 md:h-32 border dark:border-zinc-600 foldsmall:gap-2 p-4">
                <div className="md:col-span-1 my-auto hidden md:block">
                  <h1 className="text-themecolor1 border border-themecolor1 rounded-full text-3xl h-14 w-14 p-3">
                    <FaPhone className="block mx-auto" />
                  </h1>
                </div>
                <div className="md:col-span-4 col-span-5 my-auto">
                  <a href="tel:+916262313191">
                    <p className="text-base">+91 6262313191</p>
                  </a>
                </div>
              </div>

              <div className="grid grid-cols-5 gap-4 shadow-lg rounded-md my-4 md:h-32 border dark:border-zinc-600 foldsmall:gap-2 p-4">
                <div className="md:col-span-1 my-auto hidden md:block">
                  <h1 className="text-themecolor1 border border-themecolor1 rounded-full text-3xl h-14 w-14 p-3">
                    <FaEnvelope className="block mx-auto" />
                  </h1>
                </div>
                <div className="md:col-span-4 col-span-5 my-auto">
                  <p className="text-base">
                    <a href="mailto:lovemyshoww@gmail.com">
                      lovemyshoww@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white my-6 border-t pt-6">
            <div className="w-full">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.715811897729!2d70.76774727603687!3d22.326585141940594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c9a15f70a3fb%3A0xcda5001433af99b2!2sJee%20Heet%20Park%2C%20Krishna%20Society%2C%20Madhapar%2C%20Rajkot%2C%20Gujarat%20360006!5e0!3m2!1sen!2sin!4v1727786828536!5m2!1sen!2sin"
                width="100%"
                height="450"
                className="border-0"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
