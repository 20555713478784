import React, { useEffect, useState } from "react";
import bgimage from "../../Images/abtimage.jpg";
import Breadcrumb from "../Breadcumb/Breadcumb";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { useParams } from "react-router-dom";
import { getPages } from "../API/PagesApi";
import ScrollToTop from "../ScrollToTop";
import ClipLoader from "react-spinners/ClipLoader";

const Pages = () => {
  // ==================Pages Data===============
  const [pageData, setPageData] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const { Slug } = useParams(); // Extract Slug from URL params

  useEffect(() => {
    const fetchData = async () => {
      try {
        const page = await getPages(Slug); // Assuming getPages is a function to fetch a page by Slug
        setPageData(page);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, [Slug]);

  usePageSEO(
    pageData ? pageData.SeoTitle : "Love My Show | Pages", // Use page title for SEO if available
    pageData ? pageData.SeoDescription : "Love My Show | Pages", // Use page description for SEO if available
    [pageData ? pageData.SeoKeyword : "Love My Show | Pages"] // No keywords provided in this example
  );

  const breadcrumbItems = [{ text: pageData.Title }];
  const backgroundImage = pageData.Image ? pageData.Image : bgimage;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="dark:bg-boxdark-2 dark:text-white pages-main">
      <ScrollToTop />

      {pageData ? (
        <React.Fragment>
          <Breadcrumb
            items={breadcrumbItems}
            backgroundImage={backgroundImage}
          />
          <div className="container mx-auto lg:py-10">
            <p
              dangerouslySetInnerHTML={{
                __html: pageData ? pageData.Content : "",
              }}
              className="text-justify"
            ></p>
          </div>
        </React.Fragment>
      ) : (
        <p>
          {" "}
          <div className="flex justify-center items-center my-48">
            <ClipLoader color={"#DC3558"} loading={loading} size={30} />
          </div>
        </p>
      )}
    </div>
  );
};

export default Pages;
