/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import DashboardMenu from "./DashboardMenu";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { deleteEvent, getAllEvent } from "../API/EventApi";
import ClipLoader from "react-spinners/ClipLoader";
import FormLoader from "../common/FormLoader";
import NoDataFound from "../common/NodataFound";
import { format } from "date-fns";
import { FaTicket } from "react-icons/fa6";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ScrollToTop from "../ScrollToTop";
import Swal from "sweetalert2";

const YourListing = () => {
  usePageSEO(
    "Love My Show | Event Listing", // title
    "Love My Show | Event Listing", // description
    ["Love My Show | Event Listing "] // keywords
  );
  // ==================Event Data===============
  const [EventData, setEventData] = useState(null);
  const [filterdata, setfilterdata] = useState([]);
  const [search, setsearch] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const fetchEventData = async () => {
    try {
      const result = await getAllEvent();
      setEventData(result);
      setfilterdata(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchEventData();
  }, []);

  const [isFormLoading, setIsFormLoading] = useState(false);
  // -------------------delete venue------------------
  const handleDelete = async (Id) => {
    setIsFormLoading(true);
    try {
      await deleteEvent(Id);
      fetchEventData();
    } catch (error) {
      console.error("Error deleting student:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div>
        {rowData.Status === 0 || rowData.Status === 4 ? (
          <>
            <Link to={`/list-show/edit/${rowData.Id}`}>
              <div className="text-blue-600 cursor-pointer my-2 mx-6">
                <FaEdit className="text-lg" />
              </div>
            </Link>
            <div
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: `Are you sure you want to delete ${rowData.EventName}?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleDelete(rowData.Id);
                  }
                });
              }}
              className="text-red-600 cursor-pointer my-2 mx-6"
            >
              <FaTrash className="text-sm" />
            </div>
          </>
        ) : null}
        <Link to={`/event/bookings/${rowData.Id}`}>
          <div className=" text-green-600 cursor-pointer my-2 mx-6">
            <FaTicket className="text-lg" />
          </div>
        </Link>
      </div>
    );
  };
  const actionTemplate2 = (rowData) => {
    return (
      <div>
        <Link to={`/event/sponsor/${rowData.Id}`}>
          <div className=" bg-green-600 cursor-pointer my-2 text-white text-center py-1.5 rounded px-3">
            Add
          </div>
        </Link>
      </div>
    );
  };
  useEffect(() => {
    const mySearch = EventData?.filter(
      (item) =>
        item.EventName &&
        item.EventName.toLowerCase().match(search.toLowerCase())
    );
    setfilterdata(mySearch);
  }, [search]);

  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          <DashboardMenu />

          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                <div className="bg-white  border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  <div className="border-b flex items-center bg-themecolor1 py-2">
                    <h1 className="text-lg text-center text-white mx-auto py-1 font-bold pt-0">
                      Your Listing
                    </h1>
                  </div>

                  {loading ? (
                    <div className="flex justify-center items-center my-48">
                      <ClipLoader
                        color={"#DC3558"}
                        loading={loading}
                        size={40}
                      />
                    </div>
                  ) : (
                    <>
                      {EventData?.length === 0 ? (
                        <NoDataFound />
                      ) : (
                        <>
                          <DataTable
                            value={filterdata}
                            tableStyle={{
                              minWidth: "50rem",
                              border: "1px solid #e0e0e0",
                            }}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            emptyMessage="No Data found"
                            globalFilter={search}
                            header={
                              <div className="flex justify-between p-ai-center">
                                <span className="p-input-icon-left">
                                  <i className="pi pi-search" />
                                  <InputText
                                    type="text"
                                    className="text-start me-auto border-2 py-3 text-sm pl-2 md:pr-20 pr-5"
                                    onInput={(e) => setsearch(e.target.value)}
                                    placeholder="Search"
                                  />
                                </span>
                              </div>
                            }
                          >
                            <Column
                              header="#"
                              className="border border-stroke"
                              body={(rowData, { rowIndex }) => rowIndex + 1}
                            />
                            <Column
                              header="Sponsor"
                              className="border border-stroke"
                              body={actionTemplate2}
                            />
                            <Column
                              field="EventName"
                              header="Event Name"
                              sortable
                              className="border border-stroke"
                            />
                            <Column
                              field="EventEmail"
                              header="Event Email"
                              className="border border-stroke"
                            />
                            <Column
                              field="EventOrganizer"
                              header="Event Organizer"
                              className="border border-stroke"
                            />

                            <Column
                              field="EventStartDateTime"
                              header="Event Start Date-Time"
                              className="border border-stroke"
                              body={(rowData) =>
                                format(
                                  new Date(rowData.EventStartDateTime),
                                  "dd/MM/yyyy"
                                )
                              }
                            />
                            <Column
                              field="Status "
                              header="Status"
                              className="border border-stroke"
                              body={(rowData) => (
                                <span>
                                  {rowData.Status === 0 ? (
                                    <span className="badge bg-blue-500 text-white rounded text-xs px-3 py-1">
                                      Pending
                                    </span>
                                  ) : rowData.Status === 1 ? (
                                    <span className="badge bg-green-500 text-white rounded text-xs px-3 py-1">
                                      Active
                                    </span>
                                  ) : rowData.Status === 2 ? (
                                    <span className="badge bg-gray-500 text-white rounded text-xs px-3 py-1">
                                      Inactive
                                    </span>
                                  ) : rowData.Status === 3 ? (
                                    <span className="badge bg-yellow-500 text-white rounded text-xs px-3 py-1">
                                      Expired/Complete
                                    </span>
                                  ) : rowData.Status === 4 ? (
                                    <span className="badge bg-red-500 text-white rounded text-xs px-3 py-1">
                                      Reject
                                    </span>
                                  ) : (
                                    <span className="badge bg-red-500 text-white rounded text-xs px-3 py-1">
                                      Cancel
                                    </span>
                                  )}
                                </span>
                              )}
                            />

                            <Column
                              header="Action"
                              className="border border-stroke"
                              body={actionTemplate}
                            />
                          </DataTable>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourListing;
