import axios from "axios";
import Config from "./Config";

const { API_BASE_URL } = Config; // Destructure the values from the config file

const TOKEN = Config.getToken();

const headers = {
  Authorization: `Bearer ${TOKEN}`, // Corrected typo from "Bareer" to "Bearer"
};

// =========================Get All Offer=========================
export const getAllOffer = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/offers`, {
      headers,
    });
    return response.data.responsedata;
  } catch (error) {
    throw error;
  }
};

// ----------------------getOfferBySlug----------------
export const getOfferBySlug = async (Slug) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/offers/${Slug}`, {
      headers,
    });

    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
