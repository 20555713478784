import React, { useEffect, useState } from "react"; 
import usePageSEO from "../hoosk/usepageSEO/Index";
import { Link, useParams } from "react-router-dom";
import { getEventBookAddress } from "../API/EventApi";
import { Tooltip } from "react-tooltip";
import { FaAddressCard } from "react-icons/fa";
import { MdOutlinePayments } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import { FaCalendar, FaLocationDot } from "react-icons/fa6";
import ScrollToTop from "../ScrollToTop";
import lmsicon from "../../Images/lms-icon-white.png";

const BookingSteps = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const { Id } = useParams();

  const [EventAddressData, setEventAddressData] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  usePageSEO(
    EventAddressData
      ? EventAddressData?.EventName
      : "Love My Show | Booking -Event", // Use page title for SEO if available
    EventAddressData
      ? EventAddressData?.EventName
      : "Love My Show | Booking -Event", // Use page description for SEO if available
    [
      EventAddressData
        ? EventAddressData?.EventName
        : "Love My Show | Booking -Event",
    ] // No keywords provided in this example
  );

  useEffect(() => {
    const fetchEventAddressData = async () => {
      try {
        const result = await getEventBookAddress(Id);
        setEventAddressData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchEventAddressData();
  }, [Id]);

  const handleAddressSelect = (event) => {
    setSelectedAddressId(event.target.value);
  };

  return (
    <div className="py-10 container mx-auto">
      <ScrollToTop />
      <div className="px-5">
        {/* Progress bar */}
        <div className="relative flex items-center justify-between md:w-1/2 mx-auto">
          <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
          <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gradient-to-r bg-gray-300 transition-all duration-500"></div>

          <div className="relative grid w-10 h-10 font-bold text-white bg-gradient-to-r from-themecolor1 to-themecolor2 transition-all duration-300 rounded-full place-items-center shadow-lg">

            <img src={lmsicon} alt="" className="w-6 h-6" />
          </div>

          <div className="relative grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center shadow-md hover:bg-gray-400">
            <FaCalendar className="w-5 h-5" />
          </div>

          <div className="relative grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center shadow-md hover:bg-gray-400">
            <FaAddressCard className="w-5 h-5" />
          </div>

          <div className="relative grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center shadow-md hover:bg-gray-400">
            <MdOutlinePayments className="w-5 h-5" />
          </div>
        </div>

        {/* Address selection */}
        <div className="grid md:grid-cols-1 sm:grid-cols-1 gap-4 md:my-20 my-10">
          {loading ? (
            <div className="flex justify-center items-center my-48">
              <ClipLoader color={"#DC3558"} loading={loading} size={30} />
            </div>
          ) : (
            <>
              {EventAddressData?.Addresses?.length > 0 ? (
                <>
                <p className="text-center font-semibold dark:text-white md:text-xl text-base">
                    Select Event Address
                  </p>
                  {EventAddressData.Addresses?.map((val, index) => {
                    const inputId = `add${index + 1}`;
                    return (
                      <label
                        htmlFor={inputId}
                        key={inputId}
                        className="mx-auto w-full flex flex-col justify-center px-0 py-5 bg-white border shadow-lg relative cursor-pointer lg:w-[30%] transition-all duration-300 ease-in-out dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600 rounded-lg"
                      >
                        <h1 className="font-semibold px-3 pb-1 flex items-center">
                          <FaLocationDot className="mr-2 text-themecolor1" />
                          <span className="md:text-base text-sm "> {val.Location}</span>
                        </h1>
                        <p className="ps-10 pe-4  md:text-sm text-xs text-gray-500">
                          {val.CityTitle}, {val.CountryTitle}
                        </p>
                        <input
                          type="radio"
                          name="show"
                          id={inputId}
                          value={val.Id}
                          onChange={handleAddressSelect}
                          className="absolute top-9 right-5"
                        />
                      </label>
                    );
                  })}
                </>
              ) : (
                <p className="text-center font-bold text-xl mt-5 py-10 bg-red-200 rounded-lg shadow-lg">
                  No Address found for this event
                </p>
              )}
            </>
          )}
        </div>

        {/* Next button */}
        <div className="flex justify-between">
          {selectedAddressId === null ? (
            <>
              <button
                data-tooltip-id="nextButtonTooltip"
                disabled={selectedAddressId === null}
                className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all"
              >
                Next
              </button>
              <Tooltip id="nextButtonTooltip" place="top" effect="solid">
                Please select address
              </Tooltip>
            </>
          ) : (
            <Link
              to={`/event/booking-steps/${Id}/${selectedAddressId}`}
              className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all"
            >
              Next
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingSteps;
