import React, { useEffect, useState } from "react";
import { MdOutlinePayments } from "react-icons/md";
import { FaCalendar } from "react-icons/fa6";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getEventBookDates } from "../API/EventApi";
import { Tooltip } from "react-tooltip";
import { FaAddressCard, FaStopwatch } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import ScrollToTop from "../ScrollToTop";
import lmsicon from "../../Images/lms-icon-white.png";

const BookingSteps2 = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const { EventId, AddressId } = useParams();
  const [EventAddressData, setEventAddressData] = useState([]);
  const [selectedDateId, setSelectedDateId] = useState(null);
  const navigate = useNavigate();
  usePageSEO(
    EventAddressData
      ? `Love My Show | ${EventAddressData?.CityTitle}`
      : "Love My Show | Booking - Event",
    EventAddressData
      ? `Love My Show | ${EventAddressData?.CityTitle}`
      : "Love My Show | Booking - Event",
    [
      EventAddressData
        ? `Love My Show | ${EventAddressData?.CityTitle}`
        : "Love My Show | Booking - Event",
    ] // No keywords provided in this example
  );

  useEffect(() => {
    const fetchEventAddressData = async () => {
      try {
        const result = await getEventBookDates(AddressId);
        setEventAddressData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchEventAddressData();
  }, [AddressId]);

  const handleDateSelect = (event) => {
    setSelectedDateId(event.target.value);
  };

  const RedirectTicket = async (e) => {
    try {
      navigate(
        `/event/booking-steps/${EventId}/${AddressId}/${selectedDateId}`
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  return (
    <div className="py-5 container mx-auto ">
      <ScrollToTop />
      <div className="w-full py-4 px-4">
        <div className="relative flex items-center justify-between md:w-1/2 mx-auto">
          <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gradient-to-r from-themecolor1 to-themecolor2"></div>
          <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300 dark:bg-white transition-all duration-500"></div>
          <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
            <img src={lmsicon} alt="" className="w-6 h-6" />
          </div>
          <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
            <FaCalendar className="w-5 h-5" />
          </div>
          <div className="relative grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center">
            <FaAddressCard className="w-5 h-5" />
          </div>
          <div className="relative grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center">
            <MdOutlinePayments className="w-5 h-5" />
          </div>
        </div>
        <div className="grid md:grid-cols-1 sm:grid-cols-1 gap-4 md:my-20 my-10">
          {loading ? (
            <div className="flex justify-center items-center my-48">
              <ClipLoader color={"#DC3558"} loading={loading} size={30} />
            </div>
          ) : (
            <>
              {EventAddressData?.Dates?.length > 0 ? (
                <>
                  <p className="text-center font-semibold dark:text-white md:text-xl text-base">
                    Select Event Date
                  </p>
                  <div className="lg:w-1/2 w-full mx-auto grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-6">
                    {EventAddressData.Dates.map((val, index) => {
                      const inputId = `add${index + 1}`;
                      return (
                        <label
                          htmlFor={inputId}
                          key={inputId}
                          className="mx-auto w-full flex flex-col justify-center px-0 py-3 bg-white border shadow-lg relative cursor-pointer lg:w-[100%] transition-all duration-300 ease-in-out dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600 rounded-lg"
                        >
                          <h1 className="font-semibold px-3 flex items-center mb-3">
                            <FaCalendar className="mr-2 text-sm" />
                            <span className="text-xs">{val.EventDate}</span>
                          </h1>
                          <h1 className="px-3 flex items-center">
                            <FaStopwatch className="mr-2 text-sm" />
                            <span className="md:text-xs text-[11px] text-gray-400 dark:text-white">
                              {val.EventStartTime} - {val.EventEndTime}
                            </span>
                          </h1>
                          <input
                            type="radio"
                            name="show"
                            id={inputId}
                            value={val.Id}
                            onChange={handleDateSelect}
                            className="absolute top-1/2 right-1 transform -translate-x-1/2 -translate-y-1/2"
                          />
                        </label>
                      );
                    })}
                  </div>
                </>
              ) : (
                <p className="text-center font-bold text-xl mt-5 py-10 bg-red-200">
                  No dates found for this event
                </p>
              )}
            </>
          )}
        </div>
        <div className="flex justify-between md:w-1/2 mx-auto">
          <div>
            <Link
              to={`/event/booking-steps/${EventId}`}
              className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor2 to-themecolor1 text-white shadow-lg hover:scale-105 transition-all"
            >
              Previous
            </Link>
          </div>
          <div>
            {selectedDateId === null ? (
              <>
                <button
                  data-tooltip-id="nextButtonTooltip"
                  className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all"
                >
                  Next
                </button>
                <Tooltip id="nextButtonTooltip" place="left" effect="solid">
                  Select Date
                </Tooltip>
              </>
            ) : (
              <button
                onClick={RedirectTicket}
                type="submit"
                className="my-2 py-2 px-10 rounded-full md:text-base text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSteps2;
