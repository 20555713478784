import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import DashboardMenu from "./DashboardMenu";
import { getKYCById } from "../API/KYCApi";
import Config from "../API/Config";
import usePageSEO from "../hoosk/usepageSEO/Index";
import ClipLoader from "react-spinners/ClipLoader";
import ScrollToTop from "../ScrollToTop";
const Kyc = () => {
  usePageSEO(
    "Love My Show | Kyc", // title
    "Love My Show | Kyc", // description
    ["Love My Show | Kyc "] // keywords
  );

  // ==================Kyc Data===============
  const [kycData, setKycData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const Id = Config.getId();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Id) {
          const UserData = await getKYCById(Id);
          setKycData(UserData[0]);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    // Fetch data initially
    fetchData();
  }, [Id]); // Include Id in the dependency array to re-run effect when Id changes

  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          <DashboardMenu />

          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                <div className="bg-white border dark:border-zinc-600 p-5 dark:bg-boxdark-2 dark:text-white">
                  {loading ? (
                    <div className="flex justify-center items-center my-48">
                      <ClipLoader
                        color={"#DC3558"}
                        loading={loading}
                        size={30}
                      />
                    </div>
                  ) : (
                    <>
                      {kycData.KYCStatus === 0 ? (
                        <>
                          <div className="flex mb-2">
                            <div className="border-b flex items-center mb-2">
                              <h1 className="text-lg text-center dark:text-white mx-auto py-1 font-bold pt-0">
                                My KYC
                              </h1>
                            </div>
                            <Link
                              to={"/kyc-1"}
                              className="block ms-auto bg-themecolor2 dark:bg-themecolor1  rounded-md text-white font-semibold py-2 px-5"
                            >
                              Edit
                            </Link>
                          </div>
                          {kycData.Reason !== "" ? (
                            <>
                              <h1 className="bg-[#f8d7da] border mt-4 border-[#f5c6cb] px-5 py-4 rounded-lg text-[#721c24] font-medium lg:mx-0">
                                Your KYC is Reject Reason Is : {kycData.Reason}
                              </h1>
                            </>
                          ) : (
                            <h1 className="bg-[#FFF3CD] border border-[#FFECB5] px-5 py-4 rounded-lg text-[#664D03] font-medium lg:mx-0">
                              Your KYC is Pending
                            </h1>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="border-b flex items-center mb-2">
                            <h1 className="text-lg text-center dark:text-white mx-auto py-1 font-bold pt-0">
                              My KYC
                            </h1>
                          </div>
                          <h1 className="bg-[#d4edda] border border-[#c3e6cb] px-5 py-4 rounded-lg text-[#155724] font-medium lg:mx-0">
                            Your KYC status is Verified
                          </h1>
                        </>
                      )}
                      {/*------------------GENERAL INFORMATION------------------*/}

                      <h1 className="text-lg  p-2 mt-4 text-white font-bold bg-themecolor1">
                        General Information
                      </h1>

                      <div className="relative overflow-x-auto shadow-md sm:rounded-lg rounded-t-none">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <tbody>
                            {/*===================GST=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">
                                GST Registration{" "}
                              </th>

                              {kycData.GSTRegistration !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  {kycData.GSTRegistration}
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>

                            {/*===================GST document=============*/}
                            {kycData.GSTRegistration === "yes" ? (
                              <>
                                {/*===================GSTIN=================*/}

                                <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                                  <th className="px-6 py-4 w-1/2 ">GSTIN</th>

                                  {kycData.GSTIN !== "" ? (
                                    <td className="px-6 py-4 w-1/2 text-base">
                                      {kycData.GSTIN}
                                    </td>
                                  ) : (
                                    <td className="px-6 py-4 w-1/2 text-base">
                                      Na
                                    </td>
                                  )}
                                </tr>

                                <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                                  <th className="px-6 py-4 w-1/2 ">PAN</th>

                                  {kycData.PAN !== "" ? (
                                    <td className="px-6 py-4 w-1/2 text-base">
                                      {kycData.PAN}
                                    </td>
                                  ) : (
                                    <td className="px-6 py-4 w-1/2 text-base">
                                      Na
                                    </td>
                                  )}
                                </tr>
                              </>
                            ) : (
                              ""
                            )}

                            {/*===================RegisteredCompanyName=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">
                                Registered Company Name{" "}
                              </th>
                              {kycData.RegisteredCompanyName !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  {kycData.RegisteredCompanyName}
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>

                            {/*===================RegisteredCompanyAddress=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">
                                Registered Company Address{" "}
                              </th>
                              {kycData.RegisteredCompanyAddress !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  {kycData.RegisteredCompanyAddress}
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>

                            {/*===================Country=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">Country </th>
                              {kycData.Country !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  {kycData.Country}
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>

                            {/*===================Phone=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">Contact No </th>
                              {kycData.Phone !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  {kycData.Phone}
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>

                            {/*===================Email=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">Email </th>
                              {kycData.Email !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  {kycData.Email}
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {/*------------------GENERAL INFORMATION------------------*/}

                      <h1 className="text-lg  p-2 mt-4 text-white font-bold bg-themecolor1">
                        Bank Account Details
                      </h1>

                      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <tbody>
                            {/*===================AccountType=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">
                                Account Type{" "}
                              </th>
                              {kycData.AccountType !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  {kycData.AccountType}
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>

                            {/*===================BeneficiaryNames=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">
                                Beneficiary Name
                              </th>
                              {kycData.BeneficiaryNames !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  {kycData.BeneficiaryNames}
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>

                            {/*===================Account Number=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">
                                Account Number
                              </th>
                              {kycData.AccountNumber !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  {kycData.AccountNumber}
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>

                            {/*===================IFSC=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">IFSC</th>

                              {kycData.IFSC !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  {kycData.IFSC}
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/*------------------Documnet------------------1712808597429-4.jpg*/}

                      <h1 className="text-lg  p-2 mt-4 text-white font-bold bg-themecolor1">
                        Document Details
                      </h1>

                      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <tbody>
                            {/*===================PanCard=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">Pan Card </th>
                              {kycData.PanCard !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  <a
                                    href={kycData.PanCard}
                                    target="_blank"
                                    className="flex items-center"
                                    rel="noreferrer"
                                  >
                                    <FaEye className="mr-2" />
                                    View
                                  </a>
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>

                            {/*===================RegisteredCompanyName=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">
                                Cancelled Cheque{" "}
                              </th>

                              {kycData.CancelledCheque !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  <a
                                    href={kycData.CancelledCheque}
                                    target="_blank"
                                    className="flex items-center"
                                    rel="noreferrer"
                                  >
                                    <FaEye className="mr-2" />
                                    View
                                  </a>
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>

                            {/*===================RegisteredCompanyName=================*/}

                            <tr className="bg-white border-b dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 dark:border-l dark:border-r">
                              <th className="px-6 py-4 w-1/2 ">Agreement </th>
                              {kycData.SignAgreement !== "" ? (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  <a
                                    href={kycData.SignAgreement}
                                    target="_blank"
                                    className="flex items-center"
                                    rel="noreferrer"
                                  >
                                    <FaEye className="mr-2" />
                                    View
                                  </a>
                                </td>
                              ) : (
                                <td className="px-6 py-4 w-1/2 text-base">
                                  Na
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kyc;
