import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Components/Pages/Home";
import Event from "../Components/Pages/Events";
import EventBook from "../Components/Pages/EventBook";
import Signin from "../Components/DoItYourSelf/Signin";
import Offers from "../Components/Pages/Offers";
import OfferInnerPage from "../Components/Pages/OfferInnerPage";
import BookingSteps from "../Components/Pages/BookingSteps";
import Contact from "../Components/Pages/Contact";
import FAQ from "../Components/Pages/FAQ";
import Kyc1 from "../Components/Pages/Kyc1";
import Kyc2 from "../Components/Pages/Kyc2";
import Kyc3 from "../Components/Pages/Kyc3";
import Artistdetail from "../Components/Pages/Artistdetail";
import Artist from "../Components/Pages/Artist";
import Bookingview from "../Components/Pages/Bookingview";
import Profile from "../Components/Pages/Profile";
import YourListing from "../Components/Pages/YourListing";
import ListShow from "../Components/Pages/ListShow";
import Kyc from "../Components/Pages/Kyc";
import Orderhistory from "../Components/Pages/Orderhistory";
import Config from "../Components/API/Config";
import RequireAuth from "../Private/Requiredau";
import EventsCategory from "../Components/Pages/Events-category";
import Pages from "../Components/Pages/Pages";
import ListShowEdit from "../Components/Pages/ListShowEdit";
import BookingSteps2 from "../Components/Pages/BookingSteps2";
import BookingSteps3 from "../Components/Pages/BookingSteps3";
import OrderSuccess from "../Components/Pages/OrderSuccess";
import OrderFail from "../Components/Pages/OrderFail";
import ViewAllBooking from "../Components/Pages/ViewAllBooking";
import EventsCity from "../Components/Pages/Events-city";
import EventSearch from "../Components/Pages/Events-Search";
import AddAddress from "../Components/Pages/AddAddress";
import AddAddressEdit from "../Components/Pages/AddAddress-Edit";
import VendorDashboard from "../Components/Pages/Dashboard";
import BookingSteps4 from "../Components/Pages/BookingSteps4";
import BookingViewInner from "../Components/Pages/BookingViewInner";
import ViewAllBookingInner from "../Components/Pages/ViewAllBookingInner";
import CouponDashboard from "../Components/Pages/Coupen/CoupenDashboard";
import CouponAdd from "../Components/Pages/Coupen/CoupenAdd";
import CouponEdit from "../Components/Pages/Coupen/CoupenEdit";
import NotFound from "../Components/Pages/NotFound";
import BouncerDashboard from "../Components/Pages/Bouncer/BouncerDashboard";
import BouncerAdd from "../Components/Pages/Bouncer/BouncerAdd";
import BouncerEdit from "../Components/Pages/Bouncer/BouncerEdit";
import SponsorEdit from "../Components/Pages/Sponcer/SponsorEdit";
import SponsorAdd from "../Components/Pages/Sponcer/SponsorAdd";
import SponsorDashboard from "../Components/Pages/Sponcer/SponsorDashboard";
import SponsorAssignView from "../Components/Pages/Sponcer/SponsorAssignView";




const Router = () => {
  const [isLoggedIn, setIsLoggedIn] = useState("");

  // Check if user is logged in on component mount
  useEffect(() => {
    const usertype = Config.getType;
    setIsLoggedIn(usertype); // Update isLoggedIn based on token presence
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/event" element={<Event />} />

      <Route path="/event/city/:Id" element={<EventsCity />} />
      <Route path="/event/:Id/:Slug" element={<EventsCategory />} />
      <Route path="/event-detail/:Slug/:Id" element={<EventBook />} />

      <Route path="/list-show-signin" element={<Signin />} />
      <Route path="/offers" element={<Offers />} />
      <Route path="/offer/:Slug" element={<OfferInnerPage />} />

      <Route path="/event/booking-steps/:Id" element={<BookingSteps />} />

      <Route
        path="/event/booking-steps/:EventId/:AddressId"
        element={<BookingSteps2 />}
      />
      <Route
        path="/event/booking-steps/:EventId/:AddressId/:DateId"
        element={<BookingSteps3 />}
      />
      <Route
        path="/event/booking-steps/:EventId/:AddressId/:DateId/:TicketId"
        element={<BookingSteps4 />}
      />
      <Route
        path="/event/booking-steps/success/:Id"
        element={<OrderSuccess />}
      />
      <Route path="/event/booking-steps/fail/:Id" element={<OrderFail />} />

      <Route path="/contact" element={<Contact />} />
      <Route path="/faq" element={<FAQ />} />

      <Route path="/artist/:Slug" element={<Artistdetail />} />
      <Route path="/artist" element={<Artist />} />

      <Route path="/page/:Slug" element={<Pages />} />
      <Route path="/search/:SearchKeyword" element={<EventSearch />} />

      {/* Vendor Routes */}
      {isLoggedIn === "V" ? (
        <>
          {/* -----------------Vendor---------------*/}
          <Route
            path="/list-show"
            element={
              <RequireAuth>
                <ListShow />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <VendorDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/coupon"
            element={
              <RequireAuth>
                <CouponDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/coupon/add"
            element={
              <RequireAuth>
                <CouponAdd />
              </RequireAuth>
            }
          />
          <Route
            path="/coupon/edit/:Id"
            element={
              <RequireAuth>
                <CouponEdit />
              </RequireAuth>
            }
          />

          <Route
            path="/bouncer"
            element={
              <RequireAuth>
                <BouncerDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/bouncer/add"
            element={
              <RequireAuth>
                <BouncerAdd />
              </RequireAuth>
            }
          />
          <Route
            path="/bouncer/edit/:Id"
            element={
              <RequireAuth>
                <BouncerEdit />
              </RequireAuth>
            }
          />

          <Route
            path="/sponsor"
            element={
              <RequireAuth>
                <SponsorDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/sponsor/add"
            element={
              <RequireAuth>
                <SponsorAdd />
              </RequireAuth>
            }
          />
          <Route
            path="/sponsor/edit/:Id"
            element={
              <RequireAuth>
                <SponsorEdit />
              </RequireAuth>
            }
          />
          <Route
            path="/list-show/add-address/:Id"
            element={
              <RequireAuth>
                <AddAddress />
              </RequireAuth>
            }
          />
          <Route
            path="/list-show/add-address-edit/:EventId/:Id"
            element={
              <RequireAuth>
                <AddAddressEdit />
              </RequireAuth>
            }
          />

          <Route
            path="/list-show/edit/:Id"
            element={
              <RequireAuth>
                <ListShowEdit />
              </RequireAuth>
            }
          />
          <Route
            path="/kyc"
            element={
              <RequireAuth>
                <Kyc />
              </RequireAuth>
            }
          />
          <Route
            path="/kyc-1"
            element={
              <RequireAuth>
                <Kyc1 />
              </RequireAuth>
            }
          />
          <Route
            path="/kyc-2"
            element={
              <RequireAuth>
                <Kyc2 />
              </RequireAuth>
            }
          />
          <Route
            path="/kyc-3"
            element={
              <RequireAuth>
                <Kyc3 />
              </RequireAuth>
            }
          />

          <Route
            path="/event/sponsor/:Id"
            element={
              <RequireAuth>
                <SponsorAssignView />
              </RequireAuth>
            }
          />

          <Route
            path="/event/bookings/:Id"
            element={
              <RequireAuth>
                <Bookingview />
              </RequireAuth>
            }
          />
          <Route
            path="/event/bookings/:Id/:OrderId"
            element={
              <RequireAuth>
                <BookingViewInner />
              </RequireAuth>
            }
          />
          <Route
            path="/event/all-bookings"
            element={
              <RequireAuth>
                <ViewAllBooking />
              </RequireAuth>
            }
          />
          <Route
            path="/event/all-bookings/:Id"
            element={
              <RequireAuth>
                <ViewAllBookingInner />
              </RequireAuth>
            }
          />
          <Route
            path="/your-listing"
            element={
              <RequireAuth>
                <YourListing />
              </RequireAuth>
            }
          />
        </>
      ) : (
        <></>
      )}
      {/* User Routes */}
      {isLoggedIn === "U" ? (
        <>
          <Route
            path="/order-history"
            element={
              <RequireAuth>
                <Orderhistory />
              </RequireAuth>
            }
          />
        </>
      ) : (
        <></>
      )}

      <Route
        path="/profile"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
