// -------------------------Old Dynamic State City-------------------
// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import { GiBarracksTent } from "react-icons/gi";
// import { RiCustomerService2Line } from "react-icons/ri";
// import { IoTicketOutline } from "react-icons/io5";
// import { IoMailOutline } from "react-icons/io5";
// import { FaFacebookF } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
// import { FaInstagram } from "react-icons/fa6";
// import { FaYoutube } from "react-icons/fa";
// import { FaLinkedinIn } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { getCityByCountryId } from "../API/State-City-Language-API";
// import Config from "../API/Config";

// const Footer = () => {
//   // ==================City Data===============
//   const CountryId = Config.getCountryId();
//   const [CityData, setCityData] = useState(null);
//   const fetchCityData = async () => {
//     try {
//       const result = await getCityByCountryId(CountryId);
//       setCityData(result);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const helpdata = [
//     { des: "About Us", link: "/page/about" },
//     { des: "Contact Us", link: "/contact" },
//     { des: "FAQS", link: "/faq" },
//     { des: "Help & Support", link: "/page/help-support" },
//     { des: "Terms and Conditions", link: "/page/terms-and-conditions" },
//     { des: "Privacy Policy", link: "/page/privacy-policy" },
//     {
//       des: "Refund and cancellation policy",
//       link: "/page/refund-and-cancellation-policy",
//     },
//   ];

//   const currentYear = new Date().getFullYear();

//   useEffect(() => {
//     fetchCityData();
//   }, [CountryId]);
//   return (
//     <div className="mb-16 lg:mb-0">
//       {/* ===============================Footer ==================================*/}
//       <footer className="lg:list-item bg-[#333338] ">
//         <div className="container mx-auto lg:flex ">
//           {/*=========================== List Your Shows================================ */}

//           <div className=" py-6 lg:flex text-center">
//             <GiBarracksTent className="text-white text-2xl mx-auto" />
//             <h1 className="mx-1 md:mx-2 lg:mx-3 mt-1 font-bold text-[#A5A5A5]">
//               List your Show
//             </h1>
//             <p className="mt-1.5 text-sm px-3 text-[#A5A5A5]">
//               Got a show, event, activity or a great experience? Parter with us
//               & get listed on Love My Show{" "}
//             </p>
//           </div>
//           <div className="lg:ml-auto lg:mx-0 flex justify-center lg:block">
//             <Link to={"/contact"}>
//               <button className="bg-themecolor1 text-white my-5 py-2 px-5 text-sm rounded-full">
//                 Contact Today
//               </button>
//             </Link>
//           </div>
//         </div>
//         {/*===================================== Customer=================================== */}
//         <section className="  bg-[#404043] py-5">
//           <div className="container mx-auto">
//             <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mx-16">
//               <div className="part1 hover:text-white text-[#A5A5A5]">
//                 <Link to={"/"}>
//                   <RiCustomerService2Line className="text-4xl block m-auto " />
//                   <h1 className="text-center text-sm mt-2">
//                     24/7 Customer Care
//                   </h1>
//                 </Link>
//               </div>

//               <div className="part1 hover:text-white text-[#A5A5A5]">
//                 <Link to={"/"}>
//                   <IoTicketOutline className="text-4xl m-auto" />
//                   <h1 className="text-center text-sm mt-2">
//                     Resend Booking Confirmation
//                   </h1>
//                 </Link>
//               </div>

//               <div className="part1 hover:text-white text-[#A5A5A5]">
//                 <Link to={"/"}>
//                   <IoMailOutline className="text-4xl block m-auto" />
//                   <h1 className="text-center text-sm mt-2">
//                     Subscribe To The Newsletter
//                   </h1>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </section>

//         <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-3 container mx-auto">
//           {/*================================EVENTS In Top cities=========================== */}
//           <div className="container mx-auto mt-6 lg:mt-0">
//             <h1 className="text-sm lg:my-6 lg:px-0 md:px-4 px-2 font-semibold text-[#A5A5A5]">
//               EVENTS IN TOP CITIES
//             </h1>
//             <div className="grid lg:flex my-2">
//               {CityData?.slice(0, 10)?.map((val, index) => {
//                 return (
//                   <div key={index}>
//                     <Link
//                       to={`/event/city/${val.Id}`}
//                       className="mx-2 md:mx-4 lg:mx-0 lg:border-r border-r-0 lg:border-l-0 border-l border-[#7F7F7F] text-[#7F7F7F] px-2 lg:px-2 text-xs hover:text-white"
//                     >
//                       {val.Title}
//                     </Link>
//                   </div>
//                 );
//               })}
//             </div>
//           </div>

//           {/*================================Help=========================== */}
//           <div className="container mx-auto mt-5 lg:mt-0">
//             <h1 className="my-2 text-sm lg:px-0 md:px-4 px-2 font-semibold text-[#A5A5A5]">
//               Help
//             </h1>
//             <div className="grid lg:flex my-2">
//               {helpdata.map((val, index) => {
//                 return (
//                   <div key={index}>
//                     <Link
//                       to={val.link}
//                       className="mx-2 md:mx-4 lg:mx-0 lg:border-r border-r-0 lg:border-l-0 border-l border-[#7F7F7F] text-[#7F7F7F] px-2 lg:px-2 text-xs hover:text-white"
//                     >
//                       {val.des}
//                     </Link>
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         </div>
//         <div className="border-b border-[#666666] pt-4"></div>

//         <div className="social flex mt-5 justify-center pb-5 ">
//           <Link
//             to={"https://www.facebook.com/lovemyshow.official/"}
//             target="_blank"
//           >
//             <FaFacebookF className="mx-1 bg-[#666666] hover:bg-white text-4xl p-2 rounded-full" />
//           </Link>
//           <Link to={"https://x.com/Love_my_show"} target="_blank">
//             <FaXTwitter className="mx-1 bg-[#666666] hover:bg-white text-4xl p-2 rounded-full" />
//           </Link>
//           <Link to={"https://www.instagram.com/love_myshow/"} target="_blank">
//             <FaInstagram className="mx-1 bg-[#666666] hover:bg-white text-4xl p-2 rounded-full" />
//           </Link>
//           <Link to={"https://www.youtube.com/@LoveMyShow"} target="_blank">
//             <FaYoutube className="mx-1 bg-[#666666] hover:bg-white text-4xl p-2 rounded-full" />
//           </Link>
//           <Link
//             to={"https://www.linkedin.com/company/lovemyshow"}
//             target="_blank"
//           >
//             <FaLinkedinIn className="mx-1 bg-[#666666] hover:bg-white text-4xl p-2 rounded-full" />
//           </Link>
//         </div>

//         <div className="text-center pb-3 loader-first1 m-0 justify-center items-center text-[#7F7F7F] text-xs ">
//           {/*
//        <div
//         className="text-center text-white loader-first1 m-0 justify-center items-center "
//         dangerouslySetInnerHTML={{
//           __html: Copyright ? Copyright : "",
//         }}
//       ></div>
//       */}
//           © {currentYear} Love My Show (SOULBEATS MEDIABOX LLP) Design and
//           Developed By {"  "}
//           <Link
//             to={"https://www.seawindsolution.com/"}
//             className="text-[#29aae1] ml-1 text-xs"
//             target="_blank"
//           >
//             Seawind Solution Pvt. Ltd.{" "}
//           </Link>
//           <img
//             src="https://www.seawindsolution.ae/assets/front/images/loaderimage.png"
//             alt=""
//             className="animate-spin"
//           />
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default Footer;


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { GiBarracksTent } from "react-icons/gi";
import { RiCustomerService2Line } from "react-icons/ri";
import { IoTicketOutline } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6"; 
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getCityByCountryId } from "../API/State-City-Language-API";
import Config from "../API/Config";

const Footer = () => {
  // ==================City Data===============
  const CountryId = Config.getCountryId();
  const [CityData, setCityData] = useState(null);
  const fetchCityData = async () => {
    try {
      const result = await getCityByCountryId(CountryId);
      setCityData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const helpdata = [
    { des: "About Us", link: "/page/about" },
    { des: "Contact Us", link: "/contact" },
    { des: "FAQS", link: "/faq" },
    { des: "Help & Support", link: "/page/help-support" },
    { des: "Terms and Conditions", link: "/page/terms-and-conditions" },
    { des: "Privacy Policy", link: "/page/privacy-policy" },
    {
      des: "Refund and cancellation policy",
      link: "/page/refund-and-cancellation-policy",
    },
  ];

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    fetchCityData();
  }, [CountryId]);
  
  return (
    <div className="mb-16 lg:mb-0">
      {/* ===============================Footer ==================================*/}
      <footer className="lg:list-item bg-[#333338] px-2">
        <div className="container mx-auto lg:flex py-3">
          {/*=========================== List Your Shows================================ */}

          <div className=" py-6 lg:flex text-center">
            <GiBarracksTent className="text-white text-2xl mx-auto" />
            <h1 className="mx-1 md:mx-2 lg:mx-3 mt-1 font-bold text-[#A5A5A5]">
              List your Show
            </h1>
            <p className="mt-1.5 text-sm px-3 text-[#A5A5A5]">
              Got a show, event, activity or a great experience? Parter with us
              & get listed on Love My Show{" "}
            </p>
          </div>
          <div className="lg:ml-auto lg:mx-0 flex justify-center lg:block">
            <Link to={"/contact"}>
              <button className="bg-themecolor1 text-white my-5 py-2 px-5 text-sm rounded-full">
                Contact Today
              </button>
            </Link>
          </div>
        </div>
        {/*===================================== Customer=================================== */}
        <section className="  bg-[#404043] py-4">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mx-16">
              <div className="part1 hover:text-white text-[#A5A5A5]">
                <Link to={"/contact"}>
                  <RiCustomerService2Line className="text-4xl block m-auto " />
                  <h1 className="text-center text-sm mt-2">
                    24/7 Customer Care
                  </h1>
                </Link>
              </div>

              <div className="part1 hover:text-white text-[#A5A5A5]">
                <Link to={"/contact"}>
                  <IoTicketOutline className="text-4xl m-auto" />
                  <h1 className="text-center text-sm mt-2">
                    Resend Booking Confirmation
                  </h1>
                </Link>
              </div>

              <div className="part1 hover:text-white text-[#A5A5A5]">
                <Link to={"/contact"}>
                  <IoMailOutline className="text-4xl block m-auto" />
                  <h1 className="text-center text-sm mt-2">
                    Subscribe To The Newsletter
                  </h1>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-3 container mx-auto py-3">
         

          {/*================================Help=========================== */}
          <div className="container mx-auto mt-5 lg:mt-0">
            <h1 className="my-2 text-sm lg:px-2 md:px-2 px-2 font-semibold text-[#A5A5A5]">
              Help
            </h1>
            <div className="grid lg:flex my-2">
              {helpdata.map((val, index) => {
                return (
                  <div key={index}>
                    <Link
                      to={val.link}
                      className="mx-2 md:mx-4 lg:mx-0 lg:border-r border-r-0 lg:border-l-0 border-l border-[#7F7F7F] text-[#7F7F7F] px-2 lg:px-2 text-xs hover:text-white"
                    >
                      {val.des}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="border-b border-[#666666] pt-4"></div>

        <div className="social flex mt-5 justify-center pb-5 ">
          <Link
            to={"https://www.facebook.com/lovemyshow.official/"}
            target="_blank"
          >
            <FaFacebookF className="mx-1 bg-[#666666] hover:bg-white text-4xl p-2 rounded-full" />
          </Link>
          <Link to={"https://x.com/Love_my_show"} target="_blank">
            <FaXTwitter className="mx-1 bg-[#666666] hover:bg-white text-4xl p-2 rounded-full" />
          </Link>
          <Link to={"https://www.instagram.com/love_myshow/"} target="_blank">
            <FaInstagram className="mx-1 bg-[#666666] hover:bg-white text-4xl p-2 rounded-full" />
          </Link> 
          <Link
            to={"https://www.linkedin.com/company/lovemyshow"}
            target="_blank"
          >
            <FaLinkedinIn className="mx-1 bg-[#666666] hover:bg-white text-4xl p-2 rounded-full" />
          </Link>
        </div>

        <div className="text-center pb-3 pt-2 loader-first1 m-0 justify-center items-center text-[#7F7F7F] text-xs ">
          {/*
       <div
        className="text-center text-white loader-first1 m-0 justify-center items-center "
        dangerouslySetInnerHTML={{
          __html: Copyright ? Copyright : "",
        }}
      ></div>
      */}
          © {currentYear} Love My Show Design and
          Developed By {"  "}
          <Link
            to={"https://www.seawindsolution.com/"}
            className="text-[#29aae1] ml-1 text-xs"
            target="_blank"
          >
            Seawind Solution Pvt. Ltd.{" "}
          </Link>
          <img
            src="https://www.seawindsolution.ae/assets/front/images/loaderimage.png"
            alt=""
            className="animate-spin"
          />
        </div>
      </footer>
    </div>
  );
};

export default Footer;
